import { useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ability, { updateAbility } from 'utils/ability';
import { API } from 'utils/api';
import { isValidId } from 'utils/helpers';
import { ROLE } from 'utils/site';
import loadStructure from 'utils/structure';
import YupContext from 'utils/YupContext';

const useEditPage = (loadApi, options = undefined) => {
  let { id } = useParams();
  if (options?.create) {
    id = undefined;
  } else if (options?.id) {
    id = options.id;
  }
  const [isEdit, setIsEdit] = useState(isValidId(id));
  const [isLoading, setIsLoading] = useState(isValidId(id));
  const [is404, setIs404] = useState(!isValidId(id) && id !== undefined);
  const [isError, setIsError] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    let didCancel = false;
    if (isEdit) {
      setIsError(false);
      loadApi(id)
        .then(({ data }) => {
          if (!didCancel) {
            setEditData(data);
            setIsLoading(false); // siempre al ultimo para mostrar adecuadamente
          }
        })
        .catch(() => {
          if (!didCancel) {
            setIs404(true);
            setIsError(true);
            setIsEdit(false);
            setIsLoading(false);
          }
        });
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [
    editData,
    id,
    {
      isEdit,
      isLoading,
      is404,
      isError,
    },
  ];
};

const useFetchData = (loadApi, initial = undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(initial !== undefined ? initial : null);
  const [query, setQuery] = useState({ get_all: true });
  const [initialState, setInitialState] = useState(initial);
  const [error, setError] = useState(null);

  useEffect(() => {
    let didCancel = false;
    if (initialState !== undefined) {
      if (!didCancel) {
        setData(initialState);
        setInitialState(undefined);
      }
    } else {
      setIsError(false);
      loadApi(query)
        .then(({ data }) => {
          if (!didCancel) {
            setData(data);
            setIsLoading(false); // siempre al ultimo para mostrar adecuadamente
          }
        })
        .catch(err => {
          if (!didCancel) {
            setIsError(true);
            setIsLoading(false);
            setError(err);
          }
        });
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [
    data,
    setQuery,
    {
      isLoading,
      isError,
      error,
    },
  ];
};

const useGetId = () => {
  const { id } = useParams();
  const { path, url } = useRouteMatch();
  return {
    id: isValidId(id) ? id : null,
    create: path.includes(url),
  };
};

export { useGetId };
export { useEditPage, useFetchData, useLocalStorage, useSiteLoad, useYupRequired };

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

function useSiteLoad(load) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useLocalStorage('user');
  const [structure, setStructure] = useState([]);
  const [role, setRole] = useState(null);
  const [start, setStart] = useState(load);
  useEffect(() => {
    if (start) {
      API.User.action('current_user').then(({ data: user }) => {
        setUser(user);
        updateAbility(ability, user);
        setStructure(loadStructure());
        setRole(Object.values(ROLE).find(i => i === user.groups[0].id));
        setLoading(false);
      });
    }
  }, [start]);

  return {
    loading,
    user,
    role,
    structure,
    setStart,
  };
}

function yupMultiRequired(describe, name) {
  if (name.length > 1) {
    return yupMultiRequired(
      describe.fields[name[0]].innerType,
      name.filter((item, index) => {
        if (index === 0) {
          return false;
        }
        if (Number.isInteger(parseInt(item, 10))) {
          return false;
        }
        return true;
      }),
    );
  }

  try {
    const required = describe.fields[name[0]]?.tests.findIndex(({ name }) => name === 'required') >= 0;
    return required;
  } catch (e) {
    console.error(new Error('Yup Context Provider not found'));
  }
  return false;
}

function useYupRequired(name) {
  const multi = name.split('.');
  const schema = useContext(YupContext);

  const describe = schema.describe();

  if (!describe.fields[multi[0]]) {
    return false;
  }

  if (multi.length > 1) {
    if (!describe.fields[multi[0]]?.innerType) {
      return false;
    }
    return yupMultiRequired(
      describe.fields[multi[0]].innerType,
      multi.filter((item, index) => {
        if (index === 0) {
          return false;
        }
        if (Number.isInteger(parseInt(item, 10))) {
          return false;
        }
        return true;
      }),
    );
  }
  try {
    const required = describe.fields[name]?.tests.findIndex(({ name }) => name === 'required') >= 0;
    return required;
  } catch (e) {
    console.error(new Error('Yup Context Provider not found'));
  }
  return false;
}
