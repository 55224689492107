import React from 'react';
import { useGetId } from 'utils/hooks';
import Swal from 'sweetalert2';
import ReservationForm from 'Forms/Reservation/Init';
import Layout from 'layouts/TabForm';
import { useHistory } from 'react-router-dom';
import { goto, MODS } from 'utils/structure';

export default function Save() {
  const save = useGetId();
  const history = useHistory();

  return (
    <Layout
      component={ReservationForm}
      options={save}
      onCreate={data => {
        Swal.fire({
          icon: 'success',
          title: 'Creado',
          text: 'Reserva creada.',
        });
        history.push(`${goto(MODS.RESERVATIONS)}/${data.id}`);
      }}
      onUpdate={() => {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Reserva guardada.',
          onClose: () => window.location.reload(),
        });
      }}
    />
  );
}
