import Select from 'components/Select';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

const favorites = [149, 41, 48, 52, 129];

export default function LanguageSelect({ name, label, isMulti = false }) {
  const [languageData] = useFetchData(API.Language.all);

  const [options, setOptions] = useState([]);

  const [, { value }, { setValue }] = useField(name);

  useEffect(() => {
    if (languageData) {
      const optFav = languageData.filter(i => favorites.includes(i.id));
      const optNotFav = languageData.filter(i => !favorites.includes(i.id));
      setOptions([
        {
          label: 'Sugeridos',
          options: optFav,
        },
        {
          label: 'Todos',
          options: optNotFav,
        },
      ]);
    }
  }, [languageData]);

  return (
    <Select
      label={label}
      options={options}
      placeholder="Seleccionar Idioma(s)"
      optionLabel="lang"
      optionValue="id"
      name={name}
      isMulti={isMulti}
      value={languageData?.filter(i => (isMulti ? value.includes(i.id) : value === i.id)) || ''}
      onChange={sel => setValue(isMulti ? sel?.map(i => i.id) || [] : sel?.id || '')}
    />
  );
}
