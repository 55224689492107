import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FormInput from 'components/FormInput';
import GridItem from 'components/Grid/GridItem';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import { Box } from '@material-ui/core';
import ConfigBlogPost from './ConfigBlogPost';

const schema = Yup.object().shape({
  url: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese un nombre'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function BlogPosts() {
  const classes = useStyles();
  const [blogpostsData, setQueryblogpostsData] = useFetchData(API.PromotionPost.all, []);
  const [blogposts, setBlogposts] = useState([]);
  // const [title, setTitle] = useState('');

  useEffect(() => {
    setQueryblogpostsData({ get_all: true, ordering: '-created', post_type: 'BLOG' });
  }, []);

  useEffect(() => {
    if (blogpostsData) {
      setBlogposts([...blogpostsData]);
    }
  }, [blogpostsData]);

  async function deleteItem(id) {
    await API.PromotionPost.remove(id);
    setQueryblogpostsData({ get_all: true, ordering: '-created', post_type: 'BLOG' });
  }

  return (
    <YupProvider value={schema}>
      <Box width={1} py={4}>
        <ConfigBlogPost />
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={() => {
              setBlogposts(prev => [{ id: null, url: '', isEdit: true, created: Date.now() }, ...prev]);
            }}
            size="small"
          >
            <AddIcon className={classes.extendedIcon} />
            Agregar Url Post
          </Fab>
        </Grid>
      </Grid>
      {blogposts.map((post, index) => (
        <Formik
          initialValues={{
            url: post.url,
            isEdit: post?.isEdit || false,
            id: post?.id || null,
            loading: false,
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            actions.setFieldValue('loading', true);
            if (values.id) {
              API.PromotionPost.update(values.id, {
                url: values.url,
                post_type: 'BLOG',
              })
                .then(({ data }) => {
                  actions.setFieldValue('isEdit', false);

                  actions.setFieldValue('loading', false);
                  // actions.setSubmitting(false);
                  Toast.fire({
                    icon: 'success',
                    title: `${data.url} fué editado con éxito.`,
                  });
                })
                .catch(({ response: { data } }) => {
                  console.log(data);
                  actions.setFieldValue('loading', false);
                  Toast.fire({
                    icon: 'error',
                    title: String(data?.detail || 'La dirección no se pudo guardar.'),
                  });
                });
            } else {
              API.PromotionPost.create({
                url: values.url,
                post_type: 'BLOG',
              })
                .then(({ data }) => {
                  actions.setFieldValue('id', data.id);
                  actions.setFieldValue('isEdit', false);
                  actions.setFieldValue('loading', false);
                  actions.setFieldValue('url', values.url);
                  // actions.setSubmitting(false);
                  Toast.fire({
                    icon: 'success',
                    title: `${data.url} fué agregado con éxito.`,
                  });
                })
                .catch(({ response: { data } }) => {
                  console.log(data);
                  actions.setFieldValue('loading', false);
                  Toast.fire({
                    icon: 'error',
                    title: String(data?.detail || 'La dirección no se pudo guardar.'),
                  });
                  actions.setErrors(Object.fromEntries(Object.entries(data).map(([name, [msg]]) => [name, msg])));
                });
            }
            return true;
          }}
          key={`blogpost${String(post?.id || `create${post?.created}`)}`}
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => (
            <Form autoComplete="off">
              <Grid container justify="center" style={{ padding: '10px', borderTop: '1px solid #EEE' }}>
                <GridItem md={1}>
                  <Typography style={{ lineHeight: '2.5' }}>{`${index + 1}:`}</Typography>
                </GridItem>
                <GridItem md={8}>
                  {values.isEdit ? (
                    <FormInput name="url" delay={10} />
                  ) : (
                    <Typography style={{ lineHeight: '2.5' }}>{values.url}</Typography>
                  )}
                </GridItem>
                <GridItem md={3}>
                  {values.isEdit ? (
                    <>
                      {values.loading ? (
                        <span>Cargando...</span>
                      ) : (
                        <IconButton
                          style={{ padding: '5px' }}
                          onClick={() => {
                            submitForm();
                          }}
                          disabled={isSubmitting}
                        >
                          <SaveIcon />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <>
                      <IconButton style={{ padding: '5px' }} onClick={() => setFieldValue('isEdit', true)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton style={{ padding: '5px' }} onClick={() => deleteItem(values.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      ))}
    </YupProvider>
  );
}
