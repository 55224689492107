import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Form from 'Forms/Login';
import Layout from 'layouts/Login';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { updateAbility } from 'utils/ability';
import { AbilityContext } from 'utils/Can';
import { useLocalStorage } from 'utils/hooks';
import { homePage, ROLE } from 'utils/site';
import loadStructure from 'utils/structure';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright ©
      <Link color="inherit" href="/">
        Tecnocraft
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
export default function Login(props) {
  const { setStructure, setStart } = props;

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useLocalStorage('user');

  const ability = useContext(AbilityContext);
  const history = useHistory();

  return (
    <>
      <Layout
        component={Form}
        onLoad={data => {
          setUser(data);
          updateAbility(ability, data);
          setStructure(loadStructure());
          const role = Object.values(ROLE).find(i => i === data.groups[0].id);
          history.push(homePage(role));
          setStart(true);
        }}
      />
      <Box mt={8}>
        <Copyright />
      </Box>
    </>
  );
}
