import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Link, Route, Switch, useLocation, useRouteMatch, Redirect
} from 'react-router-dom';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingLeft={0} paddingTop={1}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    '&:hover,&:focus': {
      color: '#3f51b5'
    }
  }
}));

export default function ContentTabs({ tabs, defaultIndex = 0, ...attr }) {
  const classes = useStyles();
  const [value, setValue] = useState(defaultIndex);
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (tabs?.length > 0) {
      const findIndex = tabs.findIndex(
        i => !!i.route && pathname.indexOf(url.replace(/\/$/, '') + i.route.replace(/\/$/, '')) >= 0
      );

      const currentIndex = findIndex >= 0 ? findIndex : defaultIndex;
      if (value !== currentIndex) {
        setValue(currentIndex);
      }
    }
  }, [pathname, tabs]);

  if (!tabs?.length) {
    return <div>Cargando...</div>;
  }

  const tabInitIndex = tabs.findIndex(i => i.route === '' || i.route === '/');
  const tabInit = tabInitIndex >= 0 ? tabs[tabInitIndex] : null;

  return (
  // <div className={classes.root}>
    <Box flexGrow={1} bgcolor="#FFF" {...attr}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map(({ title, icon, route }, index) => (
            <Tab
              key={`tab${String(index)}`}
              label={title}
              icon={icon}
              {...a11yProps(index)}
              component={Link}
              to={url + route}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </AppBar>

      <Switch>
        {tabs.map(({ content, route }, index) => (tabInitIndex !== index
          ? (
            <Route
              key={`route${String(index)}`}
              path={path + route}
              render={() => (
                <TabPanel value={value} index={index}>
                  {content}
                </TabPanel>
              )}
            // exact
          />
          )
          : null
        ))}
        {!!tabInit && (
          <Route
            key="routeInit"
            path={path + tabInit.route}
            render={() => (
              <TabPanel value={value} index={tabInitIndex}>
                {tabInit.content}
              </TabPanel>
            )}
            // exact
          />
        )}
        { defaultIndex >= 0 && <Redirect from={path} to={path + tabs[defaultIndex].route} /> }
      </Switch>
    </Box>
  // {/* </div> */}
  );
}
