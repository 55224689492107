import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContentTabs from 'layouts/ContentTabs';
import React, { useEffect, useState } from 'react';
import PaymentMethods from './PaymentMethods';
import PaymentVouchers from './PaymentVouchers';
import PackageGroups from './PackageGroups';
import PromotionPosts from './PromotionPosts';
import BlogPosts from './BlogPosts';

export default function Settings() {
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    const pat = [];
    pat.push(
      ...[
        {
          title: 'Comprobantes',
          icon: <ReceiptIcon />,
          route: '/comprobantes',
          get content() {
            return <PaymentVouchers />;
          },
        },
      ],
    );
    pat.push(
      ...[
        {
          title: 'Métodos de pago',
          icon: <PaymentIcon />,
          route: '/metodos',
          get content() {
            return <PaymentMethods />;
          },
        },
      ],
    );
    pat.push(
      ...[
        {
          title: 'Grupos de Filtrado',
          icon: <ReceiptIcon />,
          route: '/grupos',
          get content() {
            return <PackageGroups />;
          },
        },
      ],
    );
    //
    pat.push(
      ...[
        {
          title: 'Carrousel Posts',
          icon: <ReceiptIcon />,
          route: '/posts',
          get content() {
            return (
              <>
                <PromotionPosts />
                <hr />
                <BlogPosts />
              </>
            );
          },
        },
      ],
    );
    // }
    if (pat.length) {
      setTabs(pat);
    }
  }, []);

  return <ContentTabs tabs={tabs} defaultIndex={0} />;
}
