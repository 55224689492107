import { Box, Divider, Fab, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import ErrorConexion from 'components/ErrorConexion';
import ErrorMessage from 'components/ErrorMessage';
import FormInput from 'components/FormInput';
import IGVSwitch from 'components/IGVSwitch';
import { FieldArray, Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  type_name: Yup.string(),
  custom_fields: Yup.array(),
});

const API_CALL = API.SupplierType;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  values,
  create,
  children: render,
}) => {
  const [time] = useState(Date.now);

  return (
    <Form autoComplete="off" autoCorrect="off">
      {render({
        body: (
          <YupProvider value={validationSchema}>
            <Box>
              <Box display="flex">
                <Box p={2} width={1}>
                  <FormInput label="Tipo de proveedor" name="type_name" />
                </Box>
              </Box>
              {/* <Box display="flex">
                <Box p={2} width={1 / 3}>
                  <PriceInput
                    label="Costo Unitario"
                    name="cost"
                    currency={values.currency}
                  />
                </Box>
                <Box p={2} width={1 / 3}>
                  <CurrencySelect
                    label="Moneda"
                    name="currency"
                  />
                </Box>
                <Box p={2} width={1 / 3} textAlign="center">
                  <IGVSwitch
                    label="¿Incluye IGV?"
                    name="igv"
                  />
                </Box>
              </Box>
              {ability.can('edit', 'app') && (
                <Frame title="Visualización en aplicación (APP)">
                  <Box display="flex">
                    <Box p={2} width={2 / 5}>
                      <FormInput
                        label="Descripción servicio"
                        name="description_full"
                        multiline
                        rows={4}
                        size="medium"
                      />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch
                        label="Servicio Visible"
                        name="show"
                      />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch
                        label="Proveedor Visible"
                        name="show_supplier"
                      />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch
                        label="Encargado Visible"
                        name="show_contact"
                      />
                    </Box>
                  </Box>
                </Frame>
              )} */}

              <FieldArray
                name="custom_fields"
                render={arrayHelpers => (
                  <Box>
                    <Typography color="primary">
                      Campos de Información adicional
                      <Fab
                        size="small"
                        // color="secondary"
                        aria-label="add"
                        onClick={() =>
                          arrayHelpers.push({
                            name: '',
                            multiline: false,
                            options: '',
                          })
                        }
                        style={{ marginLeft: '8px' }}
                        disabled={isSubmitting}
                      >
                        <AddIcon />
                      </Fab>
                    </Typography>
                    <Divider />
                    <ErrorMessage name="custom_fields" />

                    {values.custom_fields.map((field, index) => (
                      <div key={`field_${field?.id || `${time}_${index}`}`}>
                        <Box display="flex">
                          <Box p={2} width={1 / 2}>
                            <FormInput label="Etiqueta" name={`custom_fields.${index}.name`} />
                          </Box>
                          <Box p={2} width={1 / 12}>
                            <IGVSwitch label="Multilinea" name={`custom_fields.${index}.multiline`} />
                          </Box>
                          <Box p={2} width={5 / 12}>
                            <FormInput
                              label="Opciones"
                              name={`custom_fields.${index}.options`}
                              multiline
                              rows={4}
                              size="medium"
                            />
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                )}
              />
            </Box>

            {isSubmitting && <LinearProgress />}
            <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
          </YupProvider>
        ),
        title: create ? 'Agregar Tipo' : 'Editar Tipo',
        handleSubmit,
        isSubmitting,
      })}
    </Form>
  );
};

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    type_name: data.type_name || '',
    custom_fields: data.custom_fields || [],
    // currency: data.currency || 'SOLES',
    // igv: data.igv ?? false,
    // show: data.show ?? true,
    // description_full: data.description_full || '',
    // show_supplier: data.show_supplier ?? true,
    // show_contact: data.show_contact ?? false,
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      ...values,
      custom_fields: values.custom_fields?.map(field => ({
        ...field,
        options: field.options ? JSON.parse(field.options) : null,
      })),
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id, { time: Date.now() }));

  return (
    <FormContainer options={options}>
      <CustomForm
        id={id}
        data={{
          ...data,
          custom_fields: data?.custom_fields?.map(field => ({
            ...field,
            options: field.options ? JSON.stringify(field.options) : '',
          })),
        }}
        {...res}
      />
    </FormContainer>
  );
};

export default function SupplierType({
  // * Props
  id = null,
  create = true,
  ...res
}) {
  if (id) {
    return <UpdateForm id={id} {...res} />;
  }
  if (create) {
    return <CustomForm create {...res} />;
  }
  return <ErrorConexion code={404} />;
}

SupplierType.propTypes = {
  create: PropTypes.bool,
  id: PropTypes.number,
};
