import React, { useEffect, useState } from 'react';
// import { ErrorMessage, useField, useFormikContext } from 'formik';
import Select from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

// const getOptions = arr => arr.map(i => ({ value: i.id, label: i.name }));

export default function Add(props) {
  const [originsData] = useFetchData(API.Origin.all);

  // const [, { value }, { setValue }] = useField(props);
  // const { isSubmitting } = useFormikContext();

  const [value, setValue] = useState(null);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (originsData) {
      setOptions(originsData);
    }
  }, [originsData]);

  useEffect(() => {
    props.onSelect(value);
  }, [value]);

  return (
    <>
      <Select
        options={options}
        placeholder="Agencia o Procedencia"
        onChange={sel => {
          setValue(sel?.id || null);
        }}
        value={value ? options?.filter(i => i.id === value) : null}
        getOptionLabel={i => i.name}
        getOptionValue={i => i.id}
        isClearable
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </>
  );
}
