import BookmarksIcon from '@material-ui/icons/Bookmarks';
import Origins from 'views/Origins/Index';
import OriginSave from 'views/Origins/Save';
import Reservations from 'views/Reservation/Reservations';
import Suppliers from 'views/Suppliers/main_menu';
import SupplierAdd from 'views/Suppliers/item_tabs/supplier_form';
import SupplierMenu from 'views/Suppliers/item_menu';
import Stepper from 'views/Reservation/Stepper';
import Edit from 'views/Reservation/Edit';
import ReservationView from 'views/Reservation/View';
import Packages from 'views/Packages/Packages';
import Profile from 'views/Profile/Profile';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import PackagesAdd from 'views/Packages/Add';
import ServiceOrders from 'views/ServiceOrders/Index';
import ServiceOrdersAdd from 'views/ServiceOrders/Add';
import ServiceOrdersPayments from 'views/ServiceOrdersPayments/Index';
import BookIndex from 'views/Book/Index';
import Configs from 'views/Settings/Index';
import ability from 'utils/ability';
import PassengersIndex from 'views/People/TabMenu';
import ContactsIndex from 'views/People/Index';

const MODS = {
  RESERVATIONS: 'reservations',
  PASSENGERS: 'passengers',
  CONTACTS: 'contacts',
  PERSONS: 'persons',
  ORIGINS: 'origins',
  SUPPLIERS: 'suppliers',
  SERVICE_ORDERS_REQUESTS: 'serviceordersrequests',
  SERVICE_ORDERS: 'serviceorders',
  SERVICE_ORDERS_PAYMENTS: 'serviceorderspayments',
  PACKAGES: 'packages',
  BOOK: 'book',
  CONFIGS: 'configs'
};

const PASSENGER_ROUTE = {
  CLIENT: '/clientes',
  PASSENGER: '/secundarios'
};

const CONTACT_ROUTE = {
  CONTACT: '/contactos',
  RESPONSIBLE: '/responsables'
};

const structure = () => {
  const struc = [];

  if (ability.can('manage', 'counter')) {
    struc.push({
      title: 'Counter',
      path: '/counter',
      name: 'counter',
      // component: Start,
      redirect: '/counter/reservas',
      routes: [
        {
          path: '/reservas',
          title: 'Reservas',
          name: MODS.RESERVATIONS,
          icon: MenuBookIcon,
          component: Reservations,
          routes: [
            {
              path: '/nueva/',
              title: 'Nueva Reserva',
              name: 'add',
              icon: LibraryAddIcon,
              component: Stepper,
              sidebar: false // * no aparece en sidebar
            },
            {
              path: '/:id/view',
              title: 'Ordenes de Servicio',
              name: 'orders',
              icon: MenuBookIcon,
              component: ReservationView,
              sidebar: false
            },
            {
              path: '/:id',
              title: 'Editar Reservas',
              name: 'edit',
              icon: MenuBookIcon,
              component: Edit,
              sidebar: false
            },
          ]
        },
        {
          path: '/pasajeros',
          title: 'Pasajeros',
          name: MODS.PASSENGERS,
          icon: PeopleAltIcon,
          component: PassengersIndex,
        },
        {
          path: '/biblia',
          title: 'Biblia',
          name: MODS.BOOK,
          icon: LocalLibraryIcon,
          component: BookIndex,
        },
      ]
    });
  }

  if (ability.can('manage', 'accounting')) {
    struc.push({
      title: 'Contabilidad',
      path: '/contabilidad',
      name: 'accounting',
      // component: Start,
      redirect: '/contabilidad/pagos-ordenes',
      routes: [
        {
          path: '/pagos-ordenes',
          title: 'Pagos de Ordenes',
          name: MODS.SERVICE_ORDERS_PAYMENTS,
          icon: ApartmentIcon,
          component: ServiceOrdersPayments
        },
      ]
    });
  }

  if (ability.can('manage', 'operations')) {
    struc.push({
      title: 'Operaciones',
      path: '/operaciones',
      name: 'management',
      // component: Start,
      redirect: '/operaciones/ordenes-servicio',
      routes: [
        {
          path: '/ordenes-servicio',
          title: 'Ordenes de Servicio',
          name: MODS.SERVICE_ORDERS,
          icon: ApartmentIcon,
          component: ServiceOrders,
          routes: [
            {
              path: '/nueva',
              title: 'Nuevo Orden de Servicio',
              name: 'add',
              icon: LibraryAddIcon,
              component: ServiceOrdersAdd,
              sidebar: false // * no aparece en sidebar
            },
            {
              path: '/:id',
              title: 'Editar Orden de Servicio',
              name: 'edit',
              icon: MenuBookIcon,
              component: ServiceOrdersAdd,
              sidebar: false
            },
          ]
        },
        {
          path: '/personal',
          title: 'Contactos',
          name: MODS.CONTACTS,
          icon: PeopleAltIcon,
          component: ContactsIndex,
        },
        {
          path: '/paquetes-servicios',
          title: 'Tours',
          name: MODS.PACKAGES,
          icon: ListAltIcon,
          component: Packages,
          routes: [
            {
              path: '/nueva/',
              title: 'Nuevo Servicio Refencial',
              name: 'add',
              icon: LibraryAddIcon,
              component: PackagesAdd,
              sidebar: false // * no aparece en sidebar
            },
            {
              path: '/:id',
              title: 'Editar Tour',
              name: 'edit',
              icon: MenuBookIcon,
              component: PackagesAdd,
              sidebar: false
            },
          ]
        },
        {
          path: '/procedencias',
          title: 'Agencias',
          name: MODS.ORIGINS,
          icon: SwapHorizontalCircleIcon,
          component: Origins,
          routes: [
            {
              path: '/nueva/',
              title: 'Nueva Procedencia',
              name: 'add',
              icon: LibraryAddIcon,
              component: OriginSave,
              sidebar: false // * no aparece en sidebar
            },
            {
              path: '/:id',
              title: 'Editar procedencia',
              name: 'edit',
              icon: MenuBookIcon,
              component: OriginSave,
              sidebar: false
            },
          ]
        },
        {
          path: '/proveedores',
          title: 'Proveedores',
          name: MODS.SUPPLIERS,
          icon: ApartmentIcon,
          component: Suppliers,
          routes: [
            {
              path: '/nueva/',
              title: 'Nuevo Proveedor',
              name: 'add',
              icon: LibraryAddIcon,
              component: SupplierAdd,
              sidebar: false // * no aparece en sidebar
            },
            {
              path: '/:id/view',
              title: 'Ordenes de Servicio',
              name: 'orders',
              icon: MenuBookIcon,
              component: SupplierMenu,
              sidebar: false
            },
            {
              path: '/:id',
              title: 'Editar Proveedor',
              name: 'edit',
              icon: MenuBookIcon,
              component: SupplierMenu,
              sidebar: false
            },
          ]
        },
        {
          path: '/configuracion',
          title: 'Configuraciones',
          name: MODS.CONFIGS,
          icon: BookmarksIcon,
          component: Configs,
        },
      ]
    });
  }

  struc.push({
    title: 'Perfil',
    path: '/perfil',
    name: 'profile',
    // component: Profile,
    redirect: '/perfil/usuario',
    routes: [
      {
        path: '/usuario',
        title: 'Mi usuario',
        name: 'myuser',
        icon: MenuBookIcon,
        component: Profile,
      }
    ]
  });

  return struc;
};

export function linkTo(linkTo) {
  const split = linkTo.split('.');
  let path = '';
  let find = structure();
  for (let i = 0; i < split.length; i += 1) {
    find = find.find(j => j.name === split[i]);
    path += find.path;
    find = find.routes;
  }
  return path;
}

export function goto(comp, page = null) {
  const struc = structure();
  let path = '';
  let findPage = '';
  for (let i = 0; i < struc.length; i += 1) {
    const find = struc[i].routes.find(j => j.name === comp);
    if (find) {
      path += struc[i].path + find.path;
      if (page) {
        findPage = find.routes.find(j => j.name === page);
      }
    }
  }
  return path + (page ? findPage.path : '');
}

export { MODS, PASSENGER_ROUTE, CONTACT_ROUTE };
export default structure;
