import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  header: {
    background: 'linear-gradient(60deg, #1769aa, #2196f3)',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(33, 150, 243,.4)',
    borderRadius: '3px',
    padding: '0 8px',
    margin: '0 8px 16px',
    '& h4': {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textTransform: 'uppercase',
      textDecoration: 'none',
      margin: 0,
      color: theme.palette.grey[50],
      padding: 0,
    },
  },
}));

export default function TabForm({ component: Form, options, ...attr }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Form {...options} {...attr}>
      {({ body, title, handleSubmit, isSubmitting }) => (
        <Box>
          <div className={classes.header}>
            <h4>{title}</h4>
          </div>
          <Box>{body}</Box>
          <Box component="hr" border="0.5px #f2f2f2 solid" />
          <Box display="flex" p={1}>
            <Box width={1 / 2}>
              <Button
                onClick={() => history.goBack()}
                variant="outlined"
                disabled={isSubmitting}
                startIcon={<ArrowBackIosIcon />}
              >
                Atrás
              </Button>
            </Box>
            <Box width={1 / 2} display="flex" justifyContent="flex-end">
              {options.create ? (
                <Button color="primary" variant="contained" disabled={isSubmitting} onClick={handleSubmit}>
                  Crear
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<SaveIcon />}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Form>
  );
}
