import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DnsIcon from '@material-ui/icons/Dns';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { AbilityContext, Can } from 'utils/Can';
import { goto, MODS } from 'utils/structure';
import ClosedTable from './Archive/Closeds';
import UpdatePayment from './Archive/Update';
import Add from './Enter/Add';
import Approve from './Liquidate/Approve';
import Pending from './Liquidate/Pending';
import ApproveTable from './Pay/Approves';
import Paidout from './Pay/Paidout';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    '&:hover,&:focus': {
      color: '#3f51b5',
      // color: '#2196f3'
    },
  },
}));

const orderpaths = {
  create: '',
  liquidate: '/pendientes',
  pay: '/aprobados',
  archive: '/archivo',
};

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [paths, setPaths] = useState([]);

  const currentPath = goto(MODS.SERVICE_ORDERS_PAYMENTS);

  const history = useHistory();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    const pat = [];
    if (ability.can('create', MODS.SERVICE_ORDERS_PAYMENTS)) {
      pat.push(orderpaths.create);
    }
    if (ability.can('liquidate', MODS.SERVICE_ORDERS_PAYMENTS)) {
      pat.push(orderpaths.liquidate);
    }
    if (ability.can('pay', MODS.SERVICE_ORDERS_PAYMENTS)) {
      pat.push(orderpaths.pay);
    }
    if (ability.can('view_archive', MODS.SERVICE_ORDERS_PAYMENTS)) {
      pat.push(orderpaths.archive);
    }
    setPaths(pat);
  }, []);

  useEffect(() => {
    const currentTab = paths.findIndex(i => !!i && window.location.href.indexOf(i) !== -1);
    setValue(currentTab > 0 ? currentTab : 0);
  }, [paths.length, window.location.href]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          // onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="configuraciones"
        >
          <Can do="create" on={MODS.SERVICE_ORDERS_PAYMENTS}>
            {() => (
              <Tab
                label="Ingresar Orden"
                icon={<QueuePlayNextIcon />}
                {...a11yProps(0)}
                component={Link}
                to={currentPath + orderpaths.create}
                className={classes.tab}
              />
            )}
          </Can>
          <Can do="liquidate" on={MODS.SERVICE_ORDERS_PAYMENTS}>
            {() => (
              <Tab
                label="Por Liquidar"
                icon={<AlarmOnIcon />}
                {...a11yProps(1)}
                component={Link}
                to={currentPath + orderpaths.liquidate}
                className={classes.tab}
              />
            )}
          </Can>
          <Can do="pay" on={MODS.SERVICE_ORDERS_PAYMENTS}>
            {() => (
              <Tab
                label="Por Pagar"
                icon={<ReceiptIcon />}
                {...a11yProps(2)}
                component={Link}
                to={currentPath + orderpaths.pay}
                className={classes.tab}
              />
            )}
          </Can>
          <Can do="view_archive" on={MODS.SERVICE_ORDERS_PAYMENTS}>
            {() => (
              <Tab
                label="Archivo"
                icon={<DnsIcon />}
                {...a11yProps(3)}
                component={Link}
                to={currentPath + orderpaths.archive}
                className={classes.tab}
              />
            )}
          </Can>
        </Tabs>
      </AppBar>

      <Switch>
        {/* Archivadas */}
        <Route
          path={currentPath + orderpaths.archive}
          render={() => (
            <TabPanel value={value} index={paths.findIndex(i => i === orderpaths.archive)}>
              <Switch>
                {/* <Route path="/about">
                  <span>hola3</span>
                </Route> */}
                <Route path={`${currentPath + orderpaths.archive}/:id`}>
                  <UpdatePayment
                    onSave={() => {
                      // setValue(2);
                      history.push(currentPath + orderpaths.archive);
                    }}
                  />
                </Route>
                <Route path={currentPath + orderpaths.archive}>
                  <ClosedTable path={currentPath + orderpaths.archive} />
                </Route>
              </Switch>
            </TabPanel>
          )}
        />

        {/* Por pagar */}
        <Route
          path={currentPath + orderpaths.pay}
          render={() => (
            <TabPanel value={value} index={paths.findIndex(i => i === orderpaths.pay)}>
              <Switch>
                {/* <Route path="/about">
                  <span>hola3</span>
                </Route> */}
                <Route path={`${currentPath + orderpaths.pay}/:id`}>
                  <Paidout
                    onSave={() => {
                      // setValue(2);
                      history.push(currentPath + orderpaths.pay);
                    }}
                  />
                </Route>
                <Route path={currentPath + orderpaths.pay}>
                  <ApproveTable path={currentPath + orderpaths.pay} />
                </Route>
              </Switch>
            </TabPanel>
          )}
        />

        {/* Liquidar */}
        <Route
          path={currentPath + orderpaths.liquidate}
          render={() => (
            <TabPanel value={value} index={paths.findIndex(i => i === orderpaths.liquidate)}>
              <Switch>
                {/* <Route path="/about">
                  <span>hola3</span>
                </Route> */}
                <Route path={`${currentPath + orderpaths.liquidate}/:id`}>
                  <Approve
                    onSave={() => {
                      // setValue(1);
                      history.push(currentPath + orderpaths.liquidate);
                    }}
                  />
                </Route>
                <Route path={currentPath + orderpaths.liquidate}>
                  <Pending path={currentPath + orderpaths.liquidate} />
                </Route>
              </Switch>
            </TabPanel>
          )}
        />
        {/* Ingresar Orden */}
        <Route
          path={currentPath + orderpaths.create}
          render={() => (
            <TabPanel value={value} index={paths.findIndex(i => i === orderpaths.create)}>
              <Add
                onSave={() => {
                  // setValue(1);
                  history.push(currentPath + orderpaths.liquidate);
                }}
              />
            </TabPanel>
          )}
        />
      </Switch>
    </div>
  );
}
