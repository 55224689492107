import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import AdminNavbarLinks from './AdminNavbarLinks.js';

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
};

const styles = theme => ({
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1 + 1000,
  },
  container: {
    display: 'flex',
    marginRight: '10px',
    marginLeft: '10px',
    justifyContent: 'space-between',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '30px',
    fontSize: '1em',
    borderRadius: '3px',
    textTransform: 'uppercase',
    color: '#76676e',
    '&:hover,&:focus': {
      background: 'transparent',
    },
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
  },
  appResponsive: {
    top: '8px',
  },
  sky: {
    background: 'linear-gradient(12deg, #ffffff, #2196f3)',
  },
  tabs: {
    backgroundColor: '#FFFFFF',
  },
  indicator: {
    backgroundColor: '#990d51',
  },
  tab: {
    '&:hover,&:focus': {
      color: '#000',
    },
    color: '#000',
  },
  tabgrid: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logoImage: {
    width: '4em',
    display: 'inline-block',
    zIndex: 2,
  },
  menuButton: {
    zIndex: 2,
  },
  right: {
    display: 'flex',
    marginRight: 0,
    justifyContent: 'flex-end',
  },
  middle: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centered: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  DrawerTop: {
    marginTop: '20px',
    zIndex: '3000 !important',
  },
});

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  const { color, logo, structure } = props;

  const arrStructure = Object.values(structure);
  const currentIndex = arrStructure.findIndex(entry => window.location.href.indexOf(entry.path) !== -1);
  const currentEntry = arrStructure[currentIndex];

  function makeBrand() {
    const route = currentEntry.routes.find(
      route => window.location.href.indexOf(currentEntry.path + route.path) !== -1,
    );
    if (route !== undefined) {
      return route.title;
    }
    if (currentEntry !== undefined) return currentEntry.name;
    return '';
  }

  const appBarClasses = classNames({
    [` ${classes[color]}`]: color,
  });
  const [value, setValue] = React.useState(currentIndex);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const makelogo = <img src={logo} alt="logo" className={classes.logoImage} style={{ width: '9em' }} />;

  const NavTab = ({ value }) => {
    if (Object.entries(structure).length <= 1) {
      return null;
    }
    return (
      <Grid className={classes.tabgrid}>
        <Tabs onChange={handleChange} value={value} classes={{ indicator: classes.indicator }} centered>
          {Object.entries(structure).map(([key, entry]) => (
            <Tab label={entry.title} key={`key-${key}`} component={Link} to={entry.path} className={classes.tab} />
          ))}
        </Tabs>
      </Grid>
    );
  };

  const [navState, setNavState] = React.useState(false);
  const toggleLinksDrawer = () => {
    setNavState(!navState);
    return navState;
  };

  return (
    <AppBar className={classes.appBar + appBarClasses} position="fixed">
      <Toolbar className={classes.container}>
        <Hidden mdUp implementation="js">
          <IconButton onClick={props.handleDrawerToggle}>
            <MenuIcon color="secondary" aria-label="Menu" />
            Menu
          </IconButton>
          <IconButton onClick={toggleLinksDrawer}>
            <MenuIcon color="secondary" aria-label="Menu" />
            Links
          </IconButton>
        </Hidden>

        <Hidden smDown implementation="js">
          <div style={{ lineHeight: 0, margin: '10px 0px 0px 0' }}>
            {makelogo}
            <span className={classes.title}>{makeBrand()}</span>
          </div>
          <NavTab value={value} />
        </Hidden>

        <Hidden mdUp implementation="css">
          <Drawer
            className={classes.DrawerTop}
            position="fixed"
            anchor="top"
            open={navState}
            onClose={toggleLinksDrawer}
          >
            <List>
              {Object.entries(structure).map(([, entry]) => (
                <ListItem button key={entry.key}>
                  <Link to={entry.path}>
                    <ListItemText primary={entry.title} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Hidden>

        <AdminNavbarLinks />
      </Toolbar>
    </AppBar>
  );
}
