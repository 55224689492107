import { Box, Button, LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { grayColor } from 'assets/jss/material-dashboard-react.js';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { str2format } from 'utils/helpers';
import { useEditPage, useLocalStorage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import IGVSwitch from 'components/IGVSwitch';
import CurrencySelect from 'components/CurrencySelect';
import ActionContactSelect from './components/ActionContactSelect';
import ActionDatePicker from './components/ActionDatePicker';
import ActionServiceSelect from './components/ActionServiceSelect';
import ActionSupplierSelect from './components/ActionSupplierSelect';
import ActionCurrencySelect from './components/ActionCurrencySelect';
import ServiceRequestChecked from './ServiceRequestChecked';

const validationSchema = Yup.object().shape({
  supplier_contact: Yup.number().pk('Seleccione contacto').nullable().required('Seleccione contacto'),
  service: Yup.number().pk('Seleccione servicio').nullable().required('Seleccione servicio'),
  supplier: Yup.number().pk('Seleccione proveedor').nullable().required('Seleccione proveedor'),
  date_in: Yup.date().nullable().format('yyyy-MM-dd').required('Seleccione una Fecha'),
  service_requests: Yup.array() // No está funcionando asi que hice un parche en submit
    .of(Yup.number().pk())
    .required('Debe seleccionar al menos un servicio'),
  currency: Yup.string().nullable().trim().required('Seleccione moneda'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 5px',
    margin: '0 0 20px',
  },
}));

export default function Add() {
  const classes = useStyles();
  const history = useHistory();

  const [fetchData, id, info] = useEditPage(id => API.ServiceOrder.get(id));

  // const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(true);

  const [serviceRequestData, setServiceRequestData] = useState([]);
  const [serviceRequest, setServiceRequest] = useState([]);

  const [user] = useLocalStorage('user');

  const [forceOnChange, setForceOnChange] = useState(Date.now());
  const [sendForm, setSendForm] = useState(false);

  const handleSubmit = async (data, values) => {
    if (!sendForm) return;

    const serviceRequests = Object.entries(data).map(([servicerequestId, attr]) => ({
      ...attr,
      id: servicerequestId,
    }));

    const submit = {
      supplier_contact: values.supplier_contact,
      service: values.service,
      date_in: values.date_in,
      // time_in: null,
      // date_out: null,
      indications: values.indications,
      igv: values.igv, // * deberia poderse guardar
      currency: values.currency,
      // service_request: values.service_requests,
      service_order_to: '', // * derrepente luego
      // supplier:// * se calcula del servicio
      // cost: // * se calcula de sumar costos de servicerequest
      serviceRequests,
    };

    if (info.isEdit) {
      API.ServiceOrder.update(id, submit).then(() => {
        if (showSuccess) {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Orden de Servicio guardada.',
            onClose: () => window.location.reload(),
          });
        } else {
          history.push(`${goto(MODS.SERVICE_ORDERS)}/${id}`);
        }
      });
    } else {
      API.ServiceOrder.create(submit).then(({ data }) => {
        if (showSuccess) {
          Swal.fire({
            icon: 'success',
            title: 'Creado',
            text: 'Nueva Orden de Servicio creada.',
          });
        }
        history.push(`${goto(MODS.SERVICE_ORDERS)}/${data.id}`);
      });
    }
    setSendForm(false);
  };

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  return (
    <YupProvider value={validationSchema}>
      <GridContainer justify="center">
        <GridItem md={12} xl={8}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>
                {info.isEdit ? `ORDEN DE SERVICIO ${fetchData.code}` : 'ORDEN DE SERVICIO'}
              </h3>
            </CardHeader>

            <CardBody>
              <Formik
                initialValues={
                  info.isEdit
                    ? {
                        supplier_contact: fetchData.supplier_contact,
                        service: fetchData.service,
                        date_in: fetchData.date_in,
                        cost: fetchData.total_cost,
                        igv: fetchData.igv,
                        indications: fetchData.indications,
                        currency: fetchData.currency,
                        service_requests: fetchData.service_request,
                        supplier: fetchData.supplier,
                        num_pax: fetchData.num_pax,
                        total_quantity: fetchData.total_quantity,
                      }
                    : {
                        supplier_contact: null,
                        service: null,
                        date_in: null,
                        cost: '00.00',
                        igv: false,
                        currency: '',
                        indications: '',
                        service_requests: [],
                        supplier: null,
                        num_pax: 0,
                      }
                }
                validationSchema={validationSchema}
                onSubmit={() => {
                  setSendForm(true);
                  setForceOnChange(Date.now());
                }}
              >
                {({ values, isSubmitting, submitForm, setFieldValue }) => (
                  <Form autoComplete="off">
                    <Box display="flex" py={1}>
                      <Box mx={1} width={4 / 12}>
                        {info.isEdit ? (
                          <>
                            <Typography>Dirigido a :</Typography>
                            <div style={{ fontSize: '1.5em', padding: '10px' }}>
                              <span>{fetchData?.supplier_name}</span>
                            </div>
                          </>
                        ) : (
                          <ActionSupplierSelect label="Dirigido a :" name="supplier" />
                        )}
                      </Box>
                      <Box mx={1} width={5 / 12}>
                        <ActionContactSelect label="Señor (a) :" name="supplier_contact" />
                      </Box>
                      <Box mx={1} width={2 / 12}>
                        {info.isEdit ? (
                          <div style={{ textAlign: 'center' }}>
                            <Typography>Inicio de Servicio</Typography>
                            <div style={{ fontSize: '1.5em', padding: '10px' }}>
                              <span>{values.date_in ? str2format(values.date_in) : '---'}</span>
                            </div>
                          </div>
                        ) : (
                          <ActionDatePicker label="Inicio de Servicio" name="date_in" />
                        )}
                      </Box>
                      <Box mx={2} width={1 / 12} textAlign="center">
                        <Typography>Núm Pax</Typography>
                        <div style={{ fontSize: '2em', marginBottom: '-5px' }}>{values.num_pax}</div>
                        <div style={{ fontSize: '1em' }}>{`${values.num_pax} ` + 'pasajeros'}</div>
                      </Box>
                    </Box>

                    <Box display="flex" py={1}>
                      <Box width={6 / 12} mx={1}>
                        {info.isEdit ? (
                          <>
                            <Typography>Servicio :</Typography>
                            <div style={{ fontSize: '1.5em', padding: '10px' }}>
                              <span>{`${values.total_quantity} ${fetchData?.service_description}`}</span>
                            </div>
                          </>
                        ) : (
                          <ActionServiceSelect
                            label="Servicio : "
                            name="service"
                            onSelect={() => {
                              setFieldValue('service_requests', []);
                            }}
                          />
                        )}
                      </Box>
                      <Box width={6 / 12}>
                        <Box display="flex" justifyContent="space-evenly" textAlign="center">
                          <Box mx={2} width={5 / 16}>
                            {info.isEdit ? (
                              <>
                                {/* <Typography>Moneda:</Typography>
                                <div style={{ fontSize: '1.5em', padding: '10px 0' }}>{values.currency}</div> */}
                                <CurrencySelect name="currency" label="Moneda:" />
                              </>
                            ) : (
                              <ActionCurrencySelect name="currency" label="Moneda:" />
                            )}
                          </Box>
                          <Box mx={2}>
                            <Typography>Costo Total</Typography>
                            {values.currency ? (
                              <div style={{ fontSize: '1.5em', padding: '10px 0' }}>
                                {(currency => {
                                  switch (currency) {
                                    case 'DOLARES':
                                      return `$ ${values.cost}`;
                                    case 'EUROS':
                                      return `€ ${values.cost}`;
                                    case 'SOLES':
                                      return `S/ ${values.cost}`;
                                    default:
                                      return `${values.cost}`;
                                  }
                                })(values.currency)}
                              </div>
                            ) : (
                              <small>Seleccione moneda</small>
                            )}
                          </Box>
                          <Box mx={2}>
                            <IGVSwitch label="¿Incluye IGV?" name="igv" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {info.isEdit ? (
                      <ServiceRequestChecked
                        name="service_requests"
                        isEdit
                        service={values.service}
                        date_in={values.date_in}
                        currency={values.currency}
                        serviceRequest={{
                          serviceRequestData,
                          setServiceRequestData,
                          serviceRequest,
                          setServiceRequest,
                        }}
                        igv={values.igv}
                        onChange={data => {
                          handleSubmit(data, values);
                        }}
                        forceOnChange={forceOnChange}
                      />
                    ) : (
                      <ServiceRequestChecked
                        name="service_requests"
                        service={values.service}
                        date_in={values.date_in}
                        currency={values.currency}
                        serviceRequest={{
                          serviceRequestData,
                          setServiceRequestData,
                          serviceRequest,
                          setServiceRequest,
                        }}
                        igv={values.igv}
                        onChange={data => {
                          handleSubmit(data, values);
                        }}
                        forceOnChange={forceOnChange}
                      />
                    )}

                    <br />
                    <GridContainer>
                      <Grid item md={12} className={classes.gridItem}>
                        <FormInput label="Indicaciones" name="indications" multiline />
                      </Grid>
                    </GridContainer>

                    {isSubmitting && <LinearProgress />}
                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                      <GridItem md={3}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          disabled={isSubmitting}
                          onClick={() => {
                            setShowSuccess(true);
                            submitForm();
                          }}
                        >
                          Guardar
                        </Button>
                      </GridItem>
                      {info.isEdit && (
                        <GridItem md={2}>
                          <Button
                            // color="primary"
                            onClick={() => {
                              setShowSuccess(false);
                              return submitForm().then(() => {
                                const a = document.createElement('a');
                                a.target = '_blank';
                                a.href = API.ServiceOrder.pdf({
                                  order: id,
                                  r: user.id,
                                });
                                a.click();
                              });
                            }}
                            variant="contained"
                            fullWidth
                          >
                            Reporte
                          </Button>
                        </GridItem>
                      )}
                    </Grid>
                    <FormikErrorFocus
                      offset={-100}
                      // align="top"
                      // focusDelay={50}
                      ease="out-bounce"
                      duration={50}
                    />
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </YupProvider>
  );
}
