import React from 'react';
import FormInput from 'components/FormInput';
import ComingSelect from 'components/ComingSelect';
import DatePicker from 'components/DatePicker2';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
// import Toast from 'utils/toast';

export default function InOut() {
  const { values } = useFormikContext();

  return (
    <Grid style={{ margin: '15px 0', rowGap: '20px' }} container justifyContent="space-between" direction="column">
      <Grid container justifyContent="space-around" direction="row">
        <Grid item xs={12} md={3}>
          <DatePicker label="Fecha de llegada" name="arrival_date" valueFormat="yyyy-MM-dd" />
        </Grid>
        <Grid item xs={12} md={3}>
          <ComingSelect label="Medio de transporte" name="arrival_via" />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label="Detalles" name="arrival_detail" />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" direction="row">
        <Grid item xs={12} md={3}>
          <DatePicker
            label="Fecha de salida"
            name="departure_date"
            minDate={values.arrival_date}
            valueFormat="yyyy-MM-dd"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ComingSelect label="Medio de transporte" name="departure_via" />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label="Detalles" name="departure_detail" />
        </Grid>
      </Grid>
    </Grid>
  );
}
