import { Grid } from '@material-ui/core';
import CitySelect from 'components/CitySelect';
import CountrySelect from 'components/CountrySelect';
import RegionSelect from 'components/RegionSelect';
import { useField } from 'formik';
import React from 'react';

export default ({
  // * Props
  countryName,
  regionName,
  cityName,
}) => {
  // * "Componente por defecto"

  const [, { value: country }] = useField(countryName);
  const [, { value: region }] = useField(regionName);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CountrySelect label="País" name={countryName} />
      </Grid>
      <Grid item xs={12}>
        <RegionSelect label="Región/Departamento" name={regionName} country={country} />
      </Grid>
      <Grid item xs={12}>
        <CitySelect label="Ciudad/Distrito" name={cityName} region={region} />
      </Grid>
    </Grid>
  );
};
