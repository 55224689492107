import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage, useField } from 'formik';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles(() => ({
  input: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
  styled: {
    '& input[type=number]::-webkit-inner-spin-button': {
      opacity: 1,
    },
  },
}));

const IntegerInput = ({ value, onChange }) => {
  const regexp = new RegExp(/^\d*[1-9]\d*$/);
  const [internalValue, setInternalValue] = useState(value);
  // const [valid, setValid] = useState(isValid(value));
  const classes = useStyles();
  return (
    <div className={classes.styled}>
      <input
        type="number"
        step={1}
        min={1}
        // className={classes.styled}
        style={{ width: '80%', textAlign: 'center' }}
        //  className={ valid ? '' : 'invalid' }
        value={internalValue}
        onKeyPress={event => {
          const key = event.charCode ? event.charCode : event.keyCode;
          if (key === 46) {
            event.preventDefault();
            return false;
          }
          return true;
        }}
        onChange={event => {
          const newValue = event.target.value;
          if (regexp.test(newValue)) {
            setInternalValue(newValue);
            onChange(newValue);
          }
          event.preventDefault();
        }}
        onBlur={() => {
          setInternalValue(value);
        }}
      />
    </div>
  );
};

const Picker = props => {
  // * Clases
  const [, { value }, { setValue }] = useField(props);
  const [defaultValue, setDefaultValue] = useState(value);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 0);

  return (
    <>
      <Typography>{props.label}</Typography>
      <IntegerInput
        value={defaultValue || 1}
        onChange={valu => {
          debouncedHandleOnChange(valu);
          setDefaultValue(valu);
        }}
      />
      <ErrorMessage name={props.name}>
        {msg => (
          <div
            style={{
              color: 'red',
              marginTop: '.5rem',
              margin: '8px 14px 0',
              fontSize: '0.75rem',
              minHeight: '1em',
              textAlign: 'left',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: '400',
              lineHeight: '1em',
            }}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </>
  );
};

export default Picker;
