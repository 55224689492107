import Select from 'components/Select';
import { currencyOptions } from 'config';
import React from 'react';

const options = Object.entries(currencyOptions).map(
  ([id, name]) => ({ id, name })
);

export default function CurrencySelect({
  // * Props
  name, label
}) {
  return (
    <Select
      options={options}
      name={name}
      label={label}
    />
  );
}
