import { Divider, Grid, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AgeGroupSelect from 'components/AgeGroupSelect';
import CountrySelect from 'components/CountrySelect';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import GenderSelect from 'components/GenderSelect';
import PhoneInput from 'components/PhoneInput';
import { PERSON_TYPE } from 'config';
import { ErrorMessage, Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const API_CALL = API.Person;

const useStyles = makeStyles(() => ({
  error: {
    background: 'red',
    '& + small': {
      color: 'red',
    },
  },
}));

// isDialog = false
const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  data,
  create,
  children: render,
  schema,
  type,
}) => {
  const classes = useStyles();

  return (
    <Form autoComplete="off" autoCorrect="off">
      {render({
        body: (
          <YupProvider value={schema}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item md={6} xs={12}>
                <FormInput label="Nombres" name="first_name" uppercase />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormInput label="Apellidos" name="last_name" uppercase />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput label="Teléfono" name="phone" />
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item md={6} xs={12}>
                <FormInput label="Email" name="email" uppercase />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormInput label="Dirección" name="address" uppercase />
              </Grid>
            </Grid>

            {[PERSON_TYPE.RESPONSIBLE, PERSON_TYPE.CONTACT].includes(type) ? (
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item md={6} xs={6}>
                  <CountrySelect label="País" name="country" />
                </Grid>
                <Grid item md={6} xs={6}>
                  <GenderSelect label="Sexo" name="gender" />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item md={4} xs={12}>
                  <CountrySelect label="País" name="country" />
                </Grid>
                <Grid item md={4} xs={6}>
                  <AgeGroupSelect label="Tipo Persona" name="age_group" />
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenderSelect label="Sexo" name="gender" />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-end">
              <Grid item md={6} xs={6}>
                <FormInput label="DNI" name="dni" />
              </Grid>
              <Grid item md={6} xs={6}>
                <FormInput label="RUC" name="ruc" />
              </Grid>
              <Grid item md={6} xs={6}>
                <FormInput label="Pasaporte" name="passport" />
              </Grid>
              <Grid item md={6} xs={6}>
                <FormInput label="Carnet de Extrangeria" name="carnet" />
              </Grid>
            </Grid>

            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-end">
              <ErrorMessage name="identification">{() => <Divider className={classes.error} />}</ErrorMessage>
              <small>* Por lo menos una identificación es requerida (DNI/RUC/PASSPORT/CARNET)</small>
            </Grid>
            {isSubmitting && <LinearProgress />}
            <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
          </YupProvider>
        ),
        title: create ? 'Agregar Persona' : `Editar Persona ${data.code}`,
        handleSubmit,
        isSubmitting,
      })}
    </Form>
  );
};

const CustomForm = withFormik({
  // - Schema
  validationSchema: ({ schema }) => schema,

  // - InitialValues
  mapPropsToValues: ({ data = {}, type }) => {
    //
    const isContact = [PERSON_TYPE.RESPONSIBLE, PERSON_TYPE.CONTACT].includes(type);

    const mapData = {
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      phone: data.phone || '',
      email: data.email || '',
      address: data.address || '',
      country: data.country || null,
      age_group: data.age_group || null,
      gender: data.gender || null,
      dni: data.dni || '',
      ruc: data.ruc || '',
      passport: data.passport || '',
      carnet: data.carnet || '',
      // types
      identification: null,
    };

    if (isContact) {
      mapData.age_group = 'ADULT';
    }

    return mapData;
  },

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      first_name: values.first_name,
      last_name: values.last_name,
      address: values.address,
      email: values.email,
      phone: values.phone,
      dni: values.dni,
      ruc: values.ruc,
      passport: values.passport,
      carnet: values.carnet,
      gender: values.gender,
      age_group: values.age_group,
      country: values.country,
      // types: values.types.map(i => i.value)
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...attr }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...attr} />
    </FormContainer>
  );
};

export default ({ id = null, create = true, ...attr }) => {
  const rules = {
    first_name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese los nombres'),

    last_name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese los apellidos'),

    address: Yup.string().ensure().trim(),
    phone: Yup.string().ensure().trim(),
    email: Yup.string().email('Ingrese un email correcto'),
    // .required('Email requerido'),
    dni: Yup.string().dni('El documento debe tener 8 digitos'),
    ruc: Yup.string().ruc('El documento debe tener 11 digitos'),
    passport: Yup.string().ensure().trim(),
    carnet: Yup.string().ensure().trim(),
    age_group: Yup.string().nullable().required('Selección requerida'),
    gender: Yup.string().nullable().required('Selección requerida'),
    country: Yup.number().pk().nullable().required('Seleccione país'),
    identification: Yup.boolean()
      .nullable()
      .when(['dni', 'ruc', 'passport', 'carnet'], {
        is: (dni, ruc, passport, carnet) => !dni && !ruc && !passport && !carnet,
        then: Yup.boolean().required('Por lo menos una identificación es requerida (DNI/RUC/PASSPORT/CARNET)'),
      }),
  };

  if (attr.type === PERSON_TYPE.RESPONSIBLE) {
    rules.email = Yup.string().email('Ingrese un email correcto').required('Email requerido');
    rules.phone = Yup.string().ensure().trim().required('Teléfono requerido');
  }

  const schema = Yup.object().shape(rules);

  if (id) {
    return <UpdateForm id={id} {...attr} schema={schema} />;
  }
  if (create) {
    return <CustomForm create {...attr} schema={schema} />;
  }
  return <ErrorConexion code={404} />;
};
