import { TextField, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const DatePickerStyled = styled.div`
  /* STYLE PARA ANCHO */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
`;

export default function Add(props) {
  const [startDate, setStartDate] = useState(props.init || null);
  const [endDate, setEndDate] = useState(props.init || null);

  const years = Array(10)
    .fill(new Date().getFullYear() - 9)
    .map((x, i) => x + i);

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  useEffect(() => {
    if (startDate && endDate) {
      props.onSelect(`${format(startDate, 'yyyy-MM-dd')}T00:00:00`, `${format(endDate, 'yyyy-MM-dd')}T23:59:59`);
    }
    if (!startDate) {
      props.onSelect(null, null);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!startDate) {
      setEndDate(null);
    }
  }, [startDate]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <DatePickerStyled style={{ display: 'flex' }}>
          <span>Desde</span>
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 100,
                }}
              >
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  {'<'}
                </button>
                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  {'>'}
                </button>
              </div>
            )}
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            locale="es"
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextField
                fullWidth
                // variant="outlined"
                size="small"
                style={{ width: '120px', marginLeft: '10px' }}
              />
            }
            isClearable
            // onSelect={(date) => setEndDate(date)}
            todayButton="Hoy"
          />
        </DatePickerStyled>
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerStyled style={{ display: 'flex' }}>
          <span>Hasta</span>
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  zIndex: 100,
                }}
              >
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  {'<'}
                </button>
                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  {'>'}
                </button>
              </div>
            )}
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            locale="es"
            // customInput={<CustomTextField />}
            customInput={
              <TextField
                // fullWidth
                // variant="outlined"
                size="small"
                style={{ width: '120px', marginLeft: '10px' }}
              />
            }
            isClearable
            // onSelect={props.onSelect}
            todayButton="Hoy"
          />
        </DatePickerStyled>
      </Grid>
    </Grid>
  );
}
