import Typography from '@material-ui/core/Typography';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function PackageGroupSelect(props) {
  const [groupsData, setQueryGroupsData] = useFetchData(API.PackageGroup.all);
  const [, { value }, { setValue }] = useField(props);
  const refresh = () => setQueryGroupsData({ get_all: true, time: Date.now() });

  return (
    <>
      <Typography>{props.label}</Typography>
      <CreatableSelect
        value={groupsData?.filter(i => (props.isMulti ? value?.includes(i.id) : value === i.id)) || ''}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'create-option') {
            const submit = {
              name: newValue.find(i => i.id === 'create').data,
              description: '',
            };
            API.PackageGroup.create(submit).then(({ data }) => {
              setValue(props.isMulti ? [...value, data.id] : newValue?.id || '');
              refresh();
            });
          } else {
            setValue(props.isMulti ? newValue?.map(i => i.id) || [] : newValue?.id || '');
          }
        }}
        getOptionLabel={i => i.name}
        getOptionValue={i => i.id}
        options={groupsData || []}
        components={{
          Input: props => (
            <div name={props.name}>
              <components.Input {...props} />
            </div>
          ),
        }}
        placeholder="Seleccionar Grupo(s)"
        isMulti={props.isMulti}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        getNewOptionData={inputValue => ({
          id: 'create',
          name: `Agregar ${inputValue}`,
          data: inputValue,
        })}
      />

      <ErrorMessage name={props.name}>
        {msg => (
          <div
            style={{
              color: 'red',
              marginTop: '.5rem',
              margin: '8px 14px 0',
              fontSize: '0.75rem',
              minHeight: '1em',
              textAlign: 'left',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: '400',
              lineHeight: '1em',
            }}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </>
  );
}
