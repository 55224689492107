import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useField, useFormikContext } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import Select from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

// const getOptions = arr => arr.map(i => ({ value: i.id, label: i.name }));

export default function SupplierSelect({ name, label, isEdit }) {
  const [supplierData, setQuerySupplierData] = useFetchData(
    (query = {}) => API.Supplier.action('servicerequests', query),
    [],
  );

  const [, { value }, { setValue }] = useField(name);
  const { values, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isEdit) {
      if (values.service || values.supplier_contact || values.date_in || values.currency) {
        const query = {};
        if (values.service) query.service = values.service;
        if (values.supplier_contact) query.contact = values.supplier_contact;
        if (values.date_in) query.date_in = values.date_in;
        if (values.currency) query.currency = values.currency;
        setQuerySupplierData(query);
      } else {
        setQuerySupplierData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service, values.supplier_contact, values.date_in, values.currency]);

  return (
    <>
      <Typography>{label}</Typography>
      <Select
        value={value ? supplierData.find(i => i.id === value) : ''}
        onChange={sel => setValue(sel?.id || null)}
        options={supplierData || []}
        getOptionLabel={i => i.name}
        getOptionValue={i => i.id}
        placeholder="Seleccione el proveedor"
        isDisabled={isSubmitting}
        name={name}
        isClearable
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
      <ErrorMessage name={name} />
    </>
  );
}
