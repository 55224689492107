import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import React from 'react';
import MultiToggle from 'react-multi-toggle-extra';
import styled from 'styled-components';

const MultiToggleStyled = styled.div`
  /* STYLE PARA BOTONES */

  .toggle-wrapper {
    /* margin: 0.5em auto; */
  }

  .toggle-wrapper label {
    padding: 0.3em;
    font-size: 17px;
    display: block;
  }

  .toggleContainer {
    width: 100%;
    position: relative;
    background: #e0e0e0;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    /* border-radius: 4px; */
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 2.6em;
    line-height: 2.6em;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    /* color: #807b77; */
    font-size: 1em;
    font-weight: bold;
  }

  .toggleContainer .toggleOption.selected {
    color: #ffffff;
    cursor: initial;
  }

  .toggleContainer .toggle {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    transition: all 0.1s ease-in-out;
  }

  .optionDisabled {
    background: #e6e6e6;
    cursor: no-drop !important;
  }

  .rejected {
    /* background-color: #f44336; */
    background: linear-gradient(60deg, #f44336, #e57373);
  }
  .pending {
    /* background-color: #f44336; */
    background: linear-gradient(60deg, #ffc107, #ffd54f);
  }
  .approved {
    /* background-color: #ffc107; */
    background: linear-gradient(60deg, #2196f3, #42a5f5);
  }
  .paidout {
    /* background-color: #009688; */
    background: linear-gradient(60deg, #009688, #4db6ac);
  }
`;

export default function Add(props) {
  const [, { value }, { setValue }] = useField(props);
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Typography>{props.label}</Typography>
      <MultiToggleStyled>
        <MultiToggle
          options={[
            {
              displayName: 'ANULADO',
              selectedDisplayName: 'ANULADO',
              value: 'REJECTED',
              optionClass: 'rejected',
            },
            {
              displayName: 'INGRESADO',
              selectedDisplayName: 'INGRESADO',
              value: 'PENDING',
              optionClass: 'pending',
            },
            {
              displayName: 'APROBADO',
              selectedDisplayName: 'APROBADO',
              value: 'APPROVED',
              optionClass: 'approved',
            },
            {
              displayName: 'PAGADO',
              selectedDisplayName: 'PAGADO',
              value: 'PAIDOUT',
              optionClass: 'paidout',
            },
          ]}
          selectedOption={value}
          onSelectOption={setValue}
          name={props.name}
          isDisabled={isSubmitting}
        />
        <Grid container style={{ position: 'relative', margin: '-20px 0' }}>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <small style={{ color: value === 'REJECTED' ? '#FFF' : '#807b77' }}>{props.helpText0}</small>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <small style={{ color: value === 'PENDING' ? '#FFF' : '#807b77' }}>{props.helpText1}</small>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <small style={{ color: value === 'APPROVED' ? '#FFF' : '#807b77' }}>{props.helpText2}</small>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <small style={{ color: value === 'PAIDOUT' ? '#FFF' : '#807b77' }}>{props.helpText3}</small>
          </Grid>
        </Grid>
        <ErrorMessage name={props.name}>
          {msg => (
            <div
              style={{
                color: 'red',
                marginTop: '.5rem',
                margin: '8px 14px 0',
                fontSize: '0.75rem',
                minHeight: '1em',
                textAlign: 'left',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: '400',
                lineHeight: '1em',
              }}
            >
              {msg}
            </div>
          )}
        </ErrorMessage>
      </MultiToggleStyled>
    </>
  );
}
