import { Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HeaderTitle from 'components/HeaderTitle';
import { localization } from 'config';
import { format } from 'date-fns';
import Form from 'Forms/Supplier/Service';
import DialogForm from 'layouts/DialogForm';
import MaterialTable from 'material-table';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';
import { createRoot } from 'react-dom/client';
import { goto, MODS } from 'utils/structure';

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    marginTop: theme.spacing(2),
  },
}));

const tableRef = React.createRef();

export default function Services(props) {
  const classes = useStyles();
  const { id } = useParams();

  // const [openDialogPayment, setOpenDialogPayment] = useState(false);
  const [openPayment, setOpenPayment] = useState(null);

  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  const ability = useContext(AbilityContext);

  const [supplierData] = useFetchData(() => API.Supplier.get(id));

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleSearch = ({ pageSize, page, search, orderDirection, orderBy }) =>
    API.Service.all({
      supplier: id,
      per_page: pageSize,
      page: page + 1,
      search,
      ordering: `${orderDirection === 'desc' ? '-' : ''}` + `${orderBy === undefined ? '' : orderBy.field}`,
    }).then(({ data: { result, current, count } }) => ({
      data: result,
      page: current - 1,
      totalCount: count,
    }));

  return (
    <>
      <Box>
        <DialogForm
          open={open}
          onClose={close}
          component={Form}
          supplier={parseInt(id, 10)}
          id={openPayment}
          onCreate={data => {
            Toast.fire({
              icon: 'success',
              title: `Servicio "${data.description}" registrado con éxito.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          onUpdate={data => {
            Toast.fire({
              icon: 'success',
              title: `Servicio ${data.description} guardado.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          createLabel="Aceptar"
        />
        <HeaderTitle
          title={!props.readonly ? `Servicios de ${supplierData?.name || ''}` : `Servicios de ${supplierData?.name}`}
          style={{
            borderRadius: '5px',
            padding: '2px 10px',
            margin: '10px 0',
            border: '1px solid #B0BEC5',
          }}
        />
        <MaterialTable
          columns={[
            { title: 'Nombre del servicio', field: 'description' },
            { title: 'Costo Unitario', field: 'cost' },
            { title: 'Moneda', field: 'currency' },
            {
              title: 'Incluye IGV',
              field: 'igv',
              render: ({ igv }) => <div>{!igv ? 'Sin IGV' : 'Con IGV'}</div>,
            },
            {
              title: 'Agregado',
              field: 'created',
              render: ({ created }) => format(new Date(created), 'dd/MM/yyyy (HH:mm)'),
            },

            {
              title: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span style={{ padding: 0, margin: '0 0 -15px 0' }}>Visibilidad en APP</span>
                  <small>Servicio | Proveedor | Contacto</small>
                </div>
              ),
              field: 'show',
              render: ({ show, show_contact: showContact, show_supplier: showSupplier }) => (
                <div>
                  {show ? (
                    <CheckCircleIcon style={{ color: '#8bc34a', padding: '0 8px' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: 'gray', padding: '0 8px' }} />
                  )}
                  {showSupplier ? (
                    <CheckCircleIcon style={{ color: '#8bc34a', padding: '0 8px' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: '#9e9e9e', padding: '0 8px' }} />
                  )}
                  {showContact ? (
                    <CheckCircleIcon style={{ color: '#8bc34a', padding: '0 8px' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: '#9e9e9e', padding: '0 8px' }} />
                  )}
                </div>
              ),
            },
          ]}
          data={handleSearch}
          options={{
            headerStyle: {
              backgroundColor: '#009688',
              color: '#FFF',
            },
            draggable: false,
            // loadingType: 'linear',
            // actionsColumnIndex: -1,
            pageSize: 500,
            toolbar: false,
            loadingType: 'linear',
            paging: false,
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: 'Sin Servicios',
            },
          }}
          actions={
            !props.readonly
              ? [
                  rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {
                      setOpen(() => {
                        setOpenPayment(rowData.id);
                        return true;
                      });
                    },
                  }),
                  ability.can('delete', 'services')
                    ? {
                        icon: 'cancel_presentation_outlined',
                        tooltip: 'Eliminar Servcio',
                        onClick: (e, row) => {
                          Swal.fire({
                            title: '¿Eliminar el servicio?',
                            text: 'Se eliminará completamente.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: '¡Eliminar!',
                            cancelButtonText: 'Mantener.',
                          }).then(result => {
                            if (result.value) {
                              API.Service.remove(row.id)
                                .then(() => {
                                  Toast.fire({
                                    icon: 'info',
                                    title: 'El servicio fue eliminado.',
                                  });
                                  tableRef.current.onQueryChange();
                                })
                                .catch(e => {
                                  const { detail } = e.response.data;
                                  console.log(detail);
                                  const myDiv = document.createElement('div');
                                  const root = createRoot(myDiv);
                                  root.render(
                                    <>
                                      {detail.length ? (
                                        <div style={{ fontSize: 10, textAlign: 'left' }}>
                                          <h6>El servicio está siendo utilizado en: </h6>
                                          {detail.map(({ service_request: serv }) => (
                                            <li
                                              key={`service_request${serv.id}`}
                                              style={{ padding: '5px 0', borderBottom: '1px solid #ddd' }}
                                            >
                                              <a
                                                href={`${goto(MODS.RESERVATIONS)}/${parseInt(serv.reservation.id, 10)}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {serv.reservation.code}
                                              </a>
                                              {' : '}
                                              {serv.package}
                                              <div style={{ paddingLeft: 14 }}>
                                                {serv.service} <br />
                                                {serv.supplier}
                                              </div>
                                            </li>
                                          ))}
                                        </div>
                                      ) : (
                                        <div>No</div>
                                      )}
                                    </>,
                                    myDiv,
                                  );
                                  Swal.fire({
                                    title: 'No se pudo eliminar',
                                    html: myDiv.innerHTML,
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    // cancelButtonText: 'Mantener.'
                                    // ,
                                  });
                                });
                            }
                          });
                        },
                      }
                    : null,
                ]
              : []
          }
          tableRef={tableRef}
        />
      </Box>
      <Box paddingY={1} />
      {!props.readonly && (
        <Box>
          <Zoom
            in
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <Fab
              variant="extended"
              aria-label="add"
              onClick={() =>
                setOpen(() => {
                  setOpenPayment(null);
                  return true;
                })
              }
              size="small"
              className={classes.fab}
            >
              <NoteAddIcon className={classes.extendedIcon} />
              Agregar otro servicio
            </Fab>
          </Zoom>
        </Box>
      )}
    </>
  );
}
