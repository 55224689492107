// import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TypeFileRadio from 'components/TypeFileRadio';
import React from 'react';

export default function FormControlLabelPlacement() {
  return (
    <FormControl component="fieldset">
      <Box py={1} px={2}>
        <TypeFileRadio name="filetype" />
      </Box>
    </FormControl>
  );
}
