import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CountryCityComp from 'components/CountryCityComp';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import PeoplePicker from 'components/PeoplePicker';
import { PERSON_TYPE } from 'config';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese un nombre'),
  country: Yup.number().pk().nullable().required('Seleccione país'),
  region: Yup.number().pk().nullable().required('Seleccione región'),
  city: Yup.number().pk().nullable().required('Seleccione ciudad'),
  responsibles: Yup.array().of(Yup.number().pk()).nullable().required('Debe agregar al menos una persona'),
});

const API_CALL = API.Origin;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  data,
  create,
  children: render,
  isDialog = false,
}) => (
  <Form autoComplete="off" autoCorrect="off">
    {render({
      body: (
        <YupProvider value={validationSchema}>
          <Grid container>
            <Grid item xs={12}>
              <FormInput label="Nombre de agencia o procedencia" name="name" />
            </Grid>
            <Grid item xs={12}>
              <CountryCityComp countryName="country" regionName="region" cityName="city" />
            </Grid>
          </Grid>
          <PeoplePicker
            label="Responsables"
            name="responsibles"
            isDialog={isDialog}
            type={PERSON_TYPE.RESPONSIBLE}
            placeholder="Buscar responsables..."
          />

          {isSubmitting && <LinearProgress />}
          <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
        </YupProvider>
      ),
      title: create ? 'Agregar Procedencia' : `Editar Procedencia ${data.code}`,
      handleSubmit,
      isSubmitting,
    })}
  </Form>
);

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    name: data.name || '',
    city: data.city || null,
    region: data.region || null,
    country: data.country || 175,
    responsibles: data.responsibles || [],
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      name: values.name,
      city: values.city,
      responsibles: values.responsibles,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default ({ id = null, create = true, ...res }) => {
  if (id) {
    return <UpdateForm id={id} {...res} />;
  }
  if (create) {
    return <CustomForm create {...res} />;
  }
  return <ErrorConexion code={404} />;
};
