import Select from 'components/Select';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createFilter } from 'react-select';
import { API } from 'utils/api';
import { MenuList } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';

export default function RegionSelect({
  // * Props
  label,
  name,
  placeholder,
  country,
}) {
  // * "Componente por defecto"

  const [data, setQuery] = useFetchData(API.Region.all, []);
  const [, , { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const first = useRef(true);

  useEffect(() => {
    // Para que se haga null a partir del segund iteración
    first.current = first.current ? false : !!setValue(null);

    if (country !== null) {
      setQuery({ get_all: true, country });
    }
  }, [country]);

  return (
    <>
      <Select
        {...(country === undefined && {
          filterOption: createFilter({ ignoreAccents: false }),
          components: { MenuList },
        })}
        options={data}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={isSubmitting || country === null}
        modeValue
      />
    </>
  );
}

RegionSelect.propTypes = {
  country: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
