import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { str2format, str2fullformat } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        width: 'max-content',
        margin: 'auto',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff',
        '&:hover': {
          color: '#ffebe9',
        },
        '&:focus': {
          color: '#fff',
        },
      },
      active: {
        color: '#fff !important',
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#9e9e9e !important',
        color: '#fff',
      },
      root: {
        padding: '0 10px !important',
        fontSize: '0.75em !important',
        width: 'max-content !important',
      },
    },
  },
});

export default function Pending(props) {
  const history = useHistory();

  const { path } = props;

  const ability = useContext(AbilityContext);

  const handleSearch = (query, getData) =>
    getData({
      // status: 'PAIDOUT',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <GridContainer justify="center">
      <GridItem md={12} xl={8}>
        <ThemeProvider theme={theme}>
          <MaterialTable
            title="Pagos de Ordenes"
            columns={[
              {
                title: 'Seguimiento',
                field: 'code',
              },
              {
                title: 'Estado',
                field: 'status',
                render: ({ status }) => {
                  switch (status) {
                    case 'APPROVED':
                      return <span style={{ color: 'blue' }}>APROBADO</span>;
                    case 'PENDING':
                      return <span style={{ color: '#d0881a' }}>INGRESADO</span>;
                    case 'PAIDOUT':
                      return <span style={{ color: 'green' }}>PAGO ASIGNADO</span>;
                    case 'REJECTED':
                      return <span style={{ color: 'red' }}>ANULADO</span>;
                    default:
                      return null;
                  }
                },
              },
              {
                title: 'Ordenes',
                field: 'orders_format',
                render: i =>
                  i?.entered_group?.service_orders?.map(order => (
                    <li key={`serviceorder${order.id}`}>
                      {ability.can('manage', 'operations') ? (
                        <Link to={`${goto(MODS.SERVICE_ORDERS)}/${order.id}`}>{order.code}</Link>
                      ) : (
                        <span>{order.code}</span>
                      )}
                    </li>
                  )),
              },
              { title: 'Tipo', field: 'voucher_type' },
              { title: 'Comprobante', field: 'voucher_code' },
              { title: 'Proveedor', field: 'supplier' },
              { title: 'Costo', field: 'cost_format' },
              {
                title: 'Ingreso',
                field: 'created',
                render: ({ created: date }) => (date ? str2fullformat(date) : '---'),
              },
              {
                title: 'Aprobación',
                field: 'approved_date',
                render: ({ approved_date: date }) => (date ? str2fullformat(date) : '---'),
              },
              {
                title: 'Fecha de Pago',
                field: 'paidout_date',
                render: ({ paidout_date: date }) => (date ? str2format(date) : '---'),
              },
              // ...commonCols(true, true)
            ]}
            data={query => handleSearch(query, API.ServiceOrderPayment.all)}
            options={{
              headerStyle: {
                // backgroundColor: '#01579b',
                color: '#FFF',
              },
              draggable: false,
              // loadingType: 'linear',
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            components={{
              OverlayLoading: () => <></>,
              Container: props => props.children,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Sin resultados',
                addTooltip: 'Agregar',
                deleteTooltip: 'Eliminar',
                editTooltip: 'Editar',
                editRow: {
                  deleteText: '¿Está seguro que desea eliminar esta fila?',
                  cancelTooltip: 'Cancelar',
                  saveTooltip: 'Guardar',
                },
              },
              header: {
                actions: ' ',
              },
              pagination: {
                labelRowsPerPage: 'Filas por página',
                labelRowsSelect: 'Filas Mostradas',
                labelDisplayedRows: '{count} resultados',
                firstAriaLabel: 'Primera página',
                firstTooltip: 'Primera página',
                previousAriaLabel: 'Anterior página',
                previousTooltip: 'Anterior página',
                nextAriaLabel: 'Siguiente página',
                nextTooltip: 'Siguiente página',
                lastAriaLabel: 'Última página',
                lastTooltip: 'Última página',
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
                nRowsSelected: '{0} seleccionado(s)',
              },
            }}
            actions={[
              // rowData => ({
              //   icon: 'delete',
              //   tooltip: 'Delete User',
              //   onClick: (event, rowData) => console.log(`You want to delete ${rowData.code}`),
              //   disabled: rowData.birthYear < 2000
              // }),
              ability.can('edit_archive', MODS.SERVICE_ORDERS_PAYMENTS)
                ? // eslint-disable-next-line no-unused-vars
                  rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (event, rowData) => history.push(`${path}/${rowData.id}`),
                  })
                : null,
            ]}
          />
        </ThemeProvider>
      </GridItem>
    </GridContainer>
  );
}
