import { goto, MODS } from 'utils/structure';
import { ROLE } from 'config';
import React from 'react';
import { format } from 'date-fns';
import { Box } from '@material-ui/core';

const homePage = role => {
  switch (role) {
    case ROLE.ADMIN: // Administración
      return goto(MODS.RESERVATIONS);
    case ROLE.ACCOUNTING: // Contabilidad
      return goto(MODS.SERVICE_ORDERS_PAYMENTS);
    case ROLE.COUNTER: // Counter
      return goto(MODS.RESERVATIONS);
    case ROLE.SUPER: // Operaciones
      return goto(MODS.SERVICE_ORDERS);
    case ROLE.SUPER_COUNTER: // Counter + Operaciones
      return goto(MODS.RESERVATIONS);
    case ROLE.OPERATION_COUNTER: // Counter + Operaciones
      return goto(MODS.SERVICE_ORDERS);
    default:
      return goto(MODS.RESERVATIONS);
  }
};

const commonCols = (complete = true, hiddenCreate = false) => [
  {
    title: 'Creado Por',
    field: 'created_by',
    render: ({
      created_by_data: userData
    }) => (
      <Box>
        <div>
          {userData?.first_name ? (
            `${userData?.first_name} ${userData?.last_name}`
        ) : (
            userData?.username
            )}
        </div>
        <small>
          {`[${userData?.groups[0].name}] `}
        </small>
      </Box>
    ),
    emptyValue: '---',
    sorting: false,
    hidden: hiddenCreate,
    hiddenByColumnsButton: hiddenCreate
  },
  {
    title: 'Fecha de creación',
    field: 'created',
    render: ({ created }) => format(new Date(created), 'dd/MM/yyyy (HH:mm)'),
    hidden: hiddenCreate,
    hiddenByColumnsButton: hiddenCreate
  },
  {
    title: 'Editado Por',
    field: 'updated_by',
    render: ({
      updated_by_data: userData,
      updated_by: updatedBy,
      created_by: createdBy,
      created_by_data: createdByData
    }) => (
      <Box bgcolor={updatedBy !== createdBy ? '#ffeb3b' : 'none'}>
        {userData?.first_name ? (
          <div>
            {`${userData?.first_name} ${userData?.last_name}`}
          </div>
        ) : (
          <div>
            {userData?.username || `sss${createdByData?.first_name} ${createdByData?.last_name}`}
          </div>
        )}
        <small>
          {`[${userData?.groups[0].name}] `}
        </small>
      </Box>
    ),
    emptyValue: '---',
    sorting: false,
    hidden: !complete,
    hiddenByColumnsButton: !complete
  },
  {
    title: 'Fecha de edición',
    field: 'updated',
    render: ({ updated }) => format(new Date(updated), 'dd/MM/yyyy (HH:mm)'),
    hidden: !complete,
    hiddenByColumnsButton: !complete
  }
];
export { ROLE, homePage, commonCols };
export default {};
