import Grid from '@material-ui/core/Grid';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function PackageSelect(props) {
  const [groupData] = useFetchData(API.PackageGroup.all);
  const [packagesData, setQueryPackage] = useFetchData(API.Package.all);
  // eslint-disable-next-line no-unused-vars
  const [allPackages, setAllPackages] = useState([]);

  const { isSubmitting } = useFormikContext();
  const [options, setOptions] = useState([
    {
      id: 'ALL',
      name: 'TODOS',
      description: 'Todos los Tours',
    },
  ]);

  const [selectGroup, setSelectGroup] = useState('ALL');

  const [, { value: details }, { setValue: setDetails }] = useField(props);

  useEffect(() => {
    if (groupData?.length) {
      setOptions(opt => [...opt, ...groupData]);
    }
  }, [groupData]);

  useEffect(() => {
    if (packagesData?.length) {
      setAllPackages(packagesData);
    }
  }, [packagesData]);

  useEffect(() => {
    if (selectGroup === 'ALL') {
      setQueryPackage({ get_all: true });
    } else {
      setQueryPackage({ get_all: true, groups__id: selectGroup });
    }
  }, [selectGroup]);

  const handleClick = (item, checked) => {
    if (checked) {
      const append = () =>
        setDetails([
          ...details,
          {
            package: item.id,
            service_requests: [
              ...item.services.map(service => ({
                service,
              })),
            ],
            start_date: null,
            comple_date: null,
            observations: '',
            mode: 'PRIVATE',
            progress: 'NotStarted',
            price: item.price,
            currency: item.currency,
            package_name: item.name,
          },
        ]);
      return props?.onAppend ? props.onAppend(append, item.id) : append();
    }

    const remove = () => setDetails(details.filter(i => i.package !== item.id));
    return props?.onRemove
      ? props.onRemove(
          remove,
          details.find(i => i.package === item.id),
        )
      : remove();
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4} className={props.classGridItem}>
        <Select
          value={options?.find(i => i.id === selectGroup) || ''}
          onChange={sel => {
            setSelectGroup(sel.id);
          }}
          options={options}
          getOptionLabel={i => i.name}
          getOptionValue={i => i.id}
          placeholder="Seleccione el grupo"
          isDisabled={isSubmitting}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={12} md={8} className={props.classGridItem}>
        <Select
          // value={allPackages?.filter(i => details.find(j => j.package === i.id)) || []}
          value={[]}
          options={packagesData}
          placeholder="Seleccionar Tour"
          getOptionLabel={i => i.name}
          getOptionValue={i => i.id}
          isMulti
          onChange={(sel, action) => {
            if (action.action === 'select-option') {
              handleClick(action.option, true);
            } else if (action.action === 'remove-value') {
              handleClick(action.removedValue, false);
            }
          }}
          hideSelectedOptions={false}
          isClearable={false}
          isDisabled={isSubmitting}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </Grid>
    </Grid>
  );
}
