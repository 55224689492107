import React from 'react';
import { useGetId } from 'utils/hooks';
import Swal from 'sweetalert2';

import Form from 'Forms/Person';
import Layout from 'layouts/TabForm';

export default function Save() {
  const save = useGetId();

  return (
    <Layout
      component={Form}
      options={save}
      onCreate={() => {
        Swal.fire({
          icon: 'success',
          title: 'Creado',
          text: 'Nueva persona registrada.',
        });
      }}
      onUpdate={() => {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Persona editada.',
        });
      }}
    />
  );
}
