import { Box, Paper } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import ControlPointDuplicateTwoToneIcon from '@material-ui/icons/ControlPointDuplicateTwoTone';
import HeaderTitle from 'components/HeaderTitle';
import { localization, symbolCurrency } from 'config';
import { format } from 'date-fns';
import Form from 'Forms/Reservation/Payment';
import DialogForm from 'layouts/DialogForm';
import MaterialTable from 'material-table';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    marginTop: theme.spacing(2),
    // right: theme.spacing(4),
  },
}));

const tableRef = React.createRef();

function reducer(state, action) {
  switch (action.type) {
    case 'CALC_SOLES':
      return {
        ...state,
        soles: action.services.filter(i => i.currency === 'SOLES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_DOLARES':
      return {
        ...state,
        dolares: action.services.filter(i => i.currency === 'DOLARES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_EUROS':
      return {
        ...state,
        euros: action.services.filter(i => i.currency === 'EUROS').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    default:
      return state;
  }
}

function reducerAmount(state, action) {
  switch (action.type) {
    case 'CALC_SOLES':
      return {
        ...state,
        soles: action.payments.filter(i => i.currency === 'SOLES').reduce((a, i) => a + parseFloat(i.amount), 0),
      };
    case 'CALC_DOLARES':
      return {
        ...state,
        dolares: action.payments.filter(i => i.currency === 'DOLARES').reduce((a, i) => a + parseFloat(i.amount), 0),
      };
    case 'CALC_EUROS':
      return {
        ...state,
        euros: action.payments.filter(i => i.currency === 'EUROS').reduce((a, i) => a + parseFloat(i.amount), 0),
      };
    default:
      return state;
  }
}

export default function Payments(props) {
  const classes = useStyles();
  const { id } = useParams();

  // const [openDialogPayment, setOpenDialogPayment] = useState(false);
  const [openPayment, setOpenPayment] = useState(null);

  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  const ability = useContext(AbilityContext);

  const [reservationData] = useFetchData(() => API.Reservation.get(id));
  const [total, dispatch] = React.useReducer(reducer, {
    soles: 0,
    dolares: 0,
    euros: 0,
  });
  const [totalAmount, dispatchAmount] = React.useReducer(reducerAmount, {
    soles: 0,
    dolares: 0,
    euros: 0,
  });
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (reservationData?.reservation_details.length) {
      dispatch({
        type: 'CALC_SOLES',
        services: reservationData.reservation_details,
      });
      dispatch({
        type: 'CALC_DOLARES',
        services: reservationData.reservation_details,
      });
      dispatch({
        type: 'CALC_EUROS',
        services: reservationData.reservation_details,
      });
    }
  }, [reservationData]);

  const handleSearch = ({ pageSize, page, search, orderDirection, orderBy }) =>
    API.ReservationPayment.all({
      reservation: id,
      per_page: pageSize,
      page: page + 1,
      search,
      ordering: `${orderDirection === 'desc' ? '-' : ''}` + `${orderBy === undefined ? '' : orderBy.field}`,
    }).then(({ data: { result, current, count } }) => {
      if (result?.length) {
        dispatchAmount({ type: 'CALC_SOLES', payments: result });
        dispatchAmount({ type: 'CALC_DOLARES', payments: result });
        dispatchAmount({ type: 'CALC_EUROS', payments: result });
      }

      return {
        data: result,
        page: current - 1,
        totalCount: count,
      };
    });

  return (
    <>
      <Box>
        <DialogForm
          open={open}
          onClose={close}
          component={Form}
          reservation={parseInt(id, 10)}
          id={openPayment}
          onCreate={data => {
            Toast.fire({
              icon: 'success',
              title: `Pago por ${data.amount} ${data.currency} registrado con éxito.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          onUpdate={data => {
            Toast.fire({
              icon: 'success',
              title: `Pago por ${data.amount} ${data.currency} editado con éxito.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          createLabel="Agregar Pago"
        />
        <HeaderTitle
          title={!props.readonly ? `RESERVA ${reservationData?.code || ''}` : `PAGOS DE ${reservationData?.code}`}
          style={{
            borderRadius: '5px',
            padding: '2px 10px',
            margin: '10px 0',
            border: '1px solid #B0BEC5',
          }}
        />
        <MaterialTable
          columns={[
            {
              title: 'Efectuado',
              field: 'created',
              render: ({ created }) => format(new Date(created), 'dd/MM/yyyy (HH:mm)'),
            },
            { title: 'Para', field: 'voucherfor_value' },
            { title: 'Comprobante', field: 'voucher_type_name' },
            { title: 'Código', field: 'voucher_code' },
            { title: 'Método de Pago', field: 'payment_method_name' },
            { title: 'Monto', field: 'amount' },
            { title: 'Moneda', field: 'currency' },
            // {
            //   title: 'Creado', field: 'created', render: i => str2fullformat(i.created),
            // },
          ]}
          data={handleSearch}
          options={{
            headerStyle: {
              backgroundColor: '#9c27b0',
              color: '#FFF',
            },
            draggable: false,
            // loadingType: 'linear',
            // actionsColumnIndex: -1,
            pageSize: 500,
            toolbar: false,
            loadingType: 'linear',
            paging: false,
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: 'Sin Pagos',
            },
          }}
          actions={
            !props.readonly
              ? [
                  rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {
                      setOpen(() => {
                        setOpenPayment(rowData.id);
                        return true;
                      });
                    },
                  }),
                  ability.can('delete', 'reservation_payments')
                    ? {
                        icon: 'cancel_presentation_outlined',
                        tooltip: 'Eliminar Pago',
                        onClick: (e, row) => {
                          Swal.fire({
                            title: '¿Eliminar el pago?',
                            text: 'Se eliminará completamente.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: '¡Eliminar!',
                            cancelButtonText: 'Mantener.',
                          }).then(result => {
                            if (result.value) {
                              API.ReservationPayment.remove(row.id).then(() => {
                                Toast.fire({
                                  icon: 'info',
                                  title: 'El pago fue eliminado.',
                                });
                                tableRef.current.onQueryChange();
                              });
                            }
                          });
                        },
                      }
                    : null,
                ]
              : []
          }
          tableRef={tableRef}
        />
      </Box>
      <Box paddingY={1}>
        <Paper
          square
          elevation={3}
          style={{
            // backgroundColor: '#CDDC39',
            fontWeight: 'bold',
            marginTop: '5px',
          }}
        >
          <Box display="flex">
            {(!!total.soles || !!totalAmount.soles) && (
              <Box width={1 / 3}>
                <Box>
                  <Box component="small" p={2}>
                    TOTAL SOLES
                  </Box>
                  <span>{`${symbolCurrency.SOLES}${total.soles.toFixed(2)}`}</span>
                </Box>
                <Box>
                  <Box component="small" p={2}>
                    A CUENTA
                  </Box>
                  <span>{`${symbolCurrency.SOLES}${totalAmount.soles.toFixed(2)}`}</span>
                </Box>
                {total.soles - totalAmount.soles > 0 && (
                  <Box>
                    <Box component="small" p={2}>
                      PENDIENTE
                    </Box>
                    <span>{`${symbolCurrency.SOLES}${(total.soles - totalAmount.soles).toFixed(2)}`}</span>
                  </Box>
                )}
              </Box>
            )}
            {(!!total.dolares || !!totalAmount.dolares) && (
              <Box width={1 / 3}>
                <Box>
                  <Box component="small" p={2}>
                    TOTAL DOLARES
                  </Box>
                  <span>{symbolCurrency.DOLARES + total.dolares.toFixed(2)}</span>
                </Box>
                <Box>
                  <Box component="small" p={2}>
                    A CUENTA
                  </Box>
                  <span>{`${symbolCurrency.DOLARES}${totalAmount.dolares.toFixed(2)}`}</span>
                </Box>
                {total.dolares - totalAmount.dolares > 0 && (
                  <Box>
                    <Box component="small" p={2}>
                      PENDIENTE
                    </Box>
                    <span>{`${symbolCurrency.DOLARES}${(total.dolares - totalAmount.dolares).toFixed(2)}`}</span>
                  </Box>
                )}
              </Box>
            )}
            {(!!total.euros || !!totalAmount.euros) && (
              <Box width={1 / 3}>
                <Box>
                  <Box component="small" p={2}>
                    TOTAL EUROS
                  </Box>
                  <span>{symbolCurrency.EUROS + total.euros.toFixed(2)}</span>
                </Box>
                <Box>
                  <Box component="small" p={2}>
                    A CUENTA
                  </Box>
                  <span>{`${symbolCurrency.EUROS}${totalAmount.euros.toFixed(2)}`}</span>
                </Box>

                {total.euros - totalAmount.euros > 0 && (
                  <Box>
                    <Box component="small" p={2}>
                      PENDIENTE
                    </Box>
                    <span>{`${symbolCurrency.EUROS}${(total.euros - totalAmount.euros).toFixed(2)}`}</span>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      {!props.readonly && (
        <Box>
          <Zoom
            in
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <Fab
              variant="extended"
              aria-label="add"
              onClick={() =>
                setOpen(() => {
                  setOpenPayment(null);
                  return true;
                })
              }
              size="small"
              className={classes.fab}
            >
              <ControlPointDuplicateTwoToneIcon className={classes.extendedIcon} />
              Agregar otro Pago
            </Fab>
          </Zoom>
        </Box>
      )}
    </>
  );
}
