import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function ActionServiceSelect({ name, label, onSelect }) {
  const [serviceData, setQueryServiceData] = useFetchData(
    (query = {}) => API.Service.action('servicerequests', query),
    [],
  );
  const [, { value }, { setValue }] = useField(name);
  const { values } = useFormikContext();

  useEffect(() => {
    if (values.supplier_contact || values.supplier || values.date_in || values.currency) {
      const query = {};
      if (values.supplier_contact) query.contact = values.supplier_contact;
      if (values.supplier) query.supplier = values.supplier;
      if (values.date_in) query.date_in = values.date_in;
      if (values.currency) query.currency = values.currency;
      setQueryServiceData(query);
    } else {
      setQueryServiceData();
    }
  }, [values.supplier_contact, values.supplier, values.date_in, values.currency]);

  useEffect(() => {
    if (onSelect && serviceData) {
      onSelect(value ? serviceData.find(i => i.id === value) : null);
    }
  }, [value, serviceData]);

  return (
    <>
      <Typography>{label}</Typography>
      <Select
        value={value ? serviceData.find(i => i.id === value) : ''}
        onChange={sel => setValue(sel?.id || null)}
        options={serviceData || []}
        placeholder="Seleccionar servicio"
        getOptionLabel={i => i.description}
        getOptionValue={i => i.id}
        isClearable
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        name={name}
      />
      <ErrorMessage name={name} />
    </>
  );
}
