import { Button, LinearProgress, TableHead } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { FieldArray, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { date2format, date2fullstr, str2date, str2format, str2fullformat } from 'utils/helpers';
import { useEditPage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  comment: Yup.string().ensure().trim(),
  voucher_code: Yup.string().ensure().trim().required('Ingrese la boleta o factura'),
  status: Yup.string().ensure().trim(),
  alter_receiver_name: Yup.string().ensure().trim(),
  alter_receiver_dni: Yup.string().ensure().trim(),
  paidout_date: Yup.string().ensure().trim(),
  voucher_type: Yup.object().required('Seleccione comprobante'),
  payment_method: Yup.object().nullable(),
  service_orders: Yup.array().required('Debe agregar al menos una orden de servicio'),
  cost: Yup.number().required('Costo requerido'),
  currency: Yup.string().ensure().trim().required('Moneda requerida'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `2px solid ${grayColor[5]}`,
    backgroundColor: '#dcefff',
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
  tableHeader: {
    color: '#FFF',
    // lineHeight: '1.5rem',
    padding: '0 8px',
    background: 'dodgerblue',
    fontSize: '0.7rem',
  },
}));

export default function Add(props) {
  const classes = useStyles();

  const [fetchData, id, info] = useEditPage(id => API.ServiceOrderPayment.get(id));

  const ability = useContext(AbilityContext);

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  return (
    <YupProvider value={validationSchema}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} xl={8}>
          <Formik
            initialValues={{
              ...fetchData,
              approved_date: fetchData.approved_date ? new Date(fetchData.approved_date) : new Date(),
              service_orders: fetchData.entered_group.service_orders,
              paidout_date: fetchData.paidout_date ? str2date(fetchData.paidout_date) : null,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              const submit = {
                status: 'APPROVED',
                supplier: values.supplier?.id || values.supplier,
                approved_date: values.approved_date ? date2fullstr(values.approved_date) : null,
                voucher_type: values.voucher_type?.id || values.voucher_type,
                // cost_edited: values.cost_edited,
                entered_group: { id: values.entered_group.id },
                comment: values.comment,
                cost: values.cost,
                currency: values.currency,
                igv: values.igv,
                reply: values.reply,
                voucher_code: values.voucher_code,
              };
              API.ServiceOrderPayment.update(id, submit).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Liquidado',
                  text: `El pago para ${values.supplier.name} fue aprobado.`,
                });
                props.onSave();
              });
            }}
          >
            {({ values, isSubmitting, submitForm }) => (
              <Form autoComplete="off">
                <GridContainer>
                  <Grid item md={6} className={classes.gridItem}>
                    <Typography>Ordenar pago a :</Typography>
                    <div style={{ fontSize: '1.3rem', textAlign: 'center' }}>{values.supplier?.name}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Costo ({values.igv ? 'con IGV' : 'sin IGV'})</Typography>
                    <div style={{ fontSize: '1.5em' }}>
                      {`${parseFloat(values.cost).toFixed(2)} ${values.currency}`}
                    </div>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Método de pago</Typography>
                    <div style={{ fontSize: '1.5em' }}>{values.payment_method?.name || 'Pendiente'}</div>
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>{values.voucher_type.name}</Typography>
                    <div style={{ fontSize: '1.5em' }}>{values.voucher_code}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Ingreso</Typography>
                    <div style={{ fontSize: '1.5em' }}>{str2fullformat(values.created)}</div>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Aprobación</Typography>
                    <div style={{ fontSize: '1.5em' }}>{date2format(values.approved_date)}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Pago</Typography>
                    <div style={{ fontSize: '1.5em' }}>
                      {values.paidout_date ? date2format(values.paidout_date) : 'Pendiente'}
                    </div>
                  </Grid>
                </GridContainer>

                <Grid container style={{ margin: '10px -5px' }}>
                  <Grid item md={12}>
                    <Typography color="primary" display="block" variant="body1">
                      ORDENES DE SERVICIO
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>

                <FieldArray
                  name="service_orders"
                  render={() => (
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeader}>CÓDIGO</TableCell>
                          <TableCell className={classes.tableHeader}>SERVICIO DE PROVEEDOR</TableCell>
                          <TableCell className={classes.tableHeader}>FECHA DE SERVICIO</TableCell>
                          <TableCell className={classes.tableHeader}>COSTO</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.service_orders.map(item => (
                          <TableRow key={`task${String(item.id)}`} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {ability.can('manage', 'operations') ? (
                                <Link to={`${goto(MODS.SERVICE_ORDERS)}/${item.id}`}>{item.code}</Link>
                              ) : (
                                <span>{item.code}</span>
                              )}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{item.service_description}</TableCell>
                            <TableCell className={classes.tableCell}>{str2format(item.date_in)}</TableCell>
                            <TableCell className={classes.tableCell}>{`${item.total_cost} ${item.currency}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                />

                <Grid container style={{ margin: '15px -5px' }}>
                  <Grid item md={12}>
                    <Typography color="primary" display="block" variant="body1">
                      COMENTARIOS
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
                {values.comment ? (
                  <Grid container style={{ margin: '10px 10px 30px 0' }}>
                    <GridItem md={6}>
                      <FormInput label="Ingreso" name="comment" multiline rows={3} disabled />
                    </GridItem>
                    <GridItem md={6}>
                      <FormInput label="Respuesta" name="reply" multiline rows={3} />
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid container style={{ margin: '10px 10px 30px 0' }}>
                    <GridItem md={12}>
                      <FormInput label="Enviar comentario" name="reply" multiline rows={3} />
                    </GridItem>
                  </Grid>
                )}

                {isSubmitting && <LinearProgress />}
                <Grid
                  container
                  // justify="center"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <GridItem md={3}>
                    {!!values.service_orders?.length && (
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{
                          backgroundColor: '#FFC107',
                          color: '#FFFFFF',
                        }}
                      >
                        Liquidar
                      </Button>
                    )}
                  </GridItem>
                </Grid>
              </Form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
    </YupProvider>
  );
}
