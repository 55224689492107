import Select from 'components/Select';
import React from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function PaymentVoucherSelect({
  // * Props
  name, label
}) {
  const [paymentVoucherData] = useFetchData(API.PaymentVoucher.all);

  return (
    <Select
      options={paymentVoucherData}
      name={name}
      label={label}
    />
  );
}
