import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import FormInput from 'components/FormInput';
import GridItem from 'components/Grid/GridItem';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import { Box, Button } from '@material-ui/core';

const schema = Yup.object().shape({
  blogpost_title: Yup.string().min(3, 'Demasiado corto.').required('Ingrese un titulo'),
  //
});

export default function ConfigTitlePost() {
  const [configurationData, setQueryConfigurationData, { loading, isError }] = useFetchData(
    () => API.Configuration.get('blogpost_title'),
    null,
  );
  const [configuration, setConfiguration] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setQueryConfigurationData();
  }, []);

  useEffect(() => {
    if (!loading && isError === false && configurationData) {
      setConfiguration(configurationData);
      setShow(true);
    } else if (!loading && isError === true) {
      setShow(true);
    }
  }, [loading, isError, configurationData]);

  return (
    <YupProvider value={schema}>
      {show && (
        <Formik
          initialValues={{
            blogpost_title: configuration?.value || '',
            id: configuration?.id || null,
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            actions.setFieldValue('loading', true);
            if (values?.id) {
              API.Configuration.update('blogpost_title', {
                key: 'blogpost_title',
                value: values.blogpost_title,
              })
                .then(() => {
                  actions.setSubmitting(false);
                  Toast.fire({
                    icon: 'success',
                    title: `Título de los posts2 fué editado con éxito.`,
                  });
                })
                .catch(error => {
                  console.log(error);
                  Toast.fire({
                    icon: 'warning',
                    title: String(error),
                  });
                });
            } else {
              API.Configuration.create({
                key: 'blogpost_title',
                value: values.blogpost_title,
              })
                .then(({ data }) => {
                  actions.setFieldValue('id', data.id);
                  actions.setSubmitting(false);
                  Toast.fire({
                    icon: 'success',
                    title: `Título de los posts2 fué agregado con éxito.`,
                  });
                })
                .catch(error => {
                  console.log(error);
                  Toast.fire({
                    icon: 'warning',
                    title: String(error),
                  });
                });
            }
            return true;
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form autoComplete="off">
              <Grid container justify="center" style={{ padding: '10px' }}>
                <GridItem md={3}>
                  <Box display="flex" height="100%" alignItems="center">
                    Título del carousel 2
                  </Box>
                </GridItem>
                <GridItem md={7}>
                  <Box display="inline-flex" width={1}>
                    <FormInput name="blogpost_title" delay={10} />
                  </Box>
                </GridItem>
                <GridItem md={2}>
                  <Button
                    variant="contained"
                    color="default"
                    // size="large"
                    startIcon={<SaveIcon />}
                    // className={classes.button}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Guardar
                  </Button>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </YupProvider>
  );
}
