import { createBrowserHistory } from 'history';
import Layout from 'layouts/Main';
// import Login from 'layouts/Login';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import ability from 'utils/ability';
import { useSiteLoad } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'config';
import SignIn from 'views/SignIn/SignIn';
import { LinearProgress } from '@material-ui/core';

if (process.env.NODE_ENV !== 'production') {
  const { error } = console;
  console.error = (warning, ...args) => {
    if (/(Invalid prop|Failed prop type)/.test(warning)) {
      // throw new Error(warning);
      console.error(new Error('Error de Prop types'));
    }
    error.apply(console, [warning, ...args]);
  };
}

const PrivateRoute = ({ component: Component, structure, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      API.isAuthenticated() ? <Component {...props} structure={structure} /> : <Redirect to="/login" />
    }
  />
);

const isLoginPage = () => window.location.pathname.indexOf('/login') > -1;

function App() {
  const { loading, structure, setStart } = useSiteLoad(false);
  const [struc, setStruc] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoginPage()) {
      setStart(true);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setStruc(structure);
    }
  }, [loading]);

  if (loading && !isLoginPage()) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AbilityContext.Provider value={ability}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          {Object.values(struc).map(entry => (
            <PrivateRoute path={`${entry.path}`} component={Layout} key={`path${entry.name}`} structure={struc} />
          ))}
          <Route
            path="/login"
            // render={props => <Login {...props} setStructure={setStruc} />}
          >
            <SignIn setStructure={setStruc} setStart={setStart} />
          </Route>
          {/* <Route path="/profile" component={Profile} /> */}
          <Redirect from="/" exact to={goto(MODS.RESERVATIONS)} />
        </Switch>
      </AbilityContext.Provider>
    </ThemeProvider>
  );
}

export default () => {
  const hist = createBrowserHistory();
  return (
    <Router history={hist}>
      <App />
    </Router>
  );
};
