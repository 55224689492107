import LinearProgress from '@material-ui/core/LinearProgress';
import { Form, withFormik, Field } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React from 'react';
import { API } from 'utils/api';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import { TextField } from 'formik-material-ui';
import Toast from 'utils/toast';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Ingrese un usuario'),
  password: Yup.string().required('Ingrese una contraseña'),
});

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  children: render,
}) => (
  <Form autoComplete="off" autoCorrect="off">
    {render({
      body: (
        <YupProvider value={validationSchema}>
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label="Usuario"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {isSubmitting && <LinearProgress />}
          <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
        </YupProvider>
      ),
      handleSubmit,
      isSubmitting,
    })}
  </Form>
);

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        onLoad = () => {},
      },
    },
  ) => {
    // const [user, setUser] = useLocalStorage('user');

    const isLogin = await API.authenticate(values.username, values.password).catch(error => {
      if (error?.statusText === 'Unauthorized') {
        Toast.fire({
          icon: 'error',
          title: 'Usuario o contraseña incorrecta.',
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error del servidor o de conexión.',
        });
      }
    });

    if (!isLogin) return false;

    const { data } = await API.User.action('current_user');

    // setUser(data);
    // updateAbility(ability, user);
    // setStructure(loadStructure());
    // const role = Object.values(ROLE).find(i => i === user.groups[0].id);
    // history.push(homePage(role));

    onLoad(data);
    return Boolean(isLogin);
  },
})(BodyForm);

export default props => <CustomForm {...props} />;
