import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CurrencySelect from 'components/CurrencySelect';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import IGVSwitch from 'components/IGVSwitch';
import PriceInput from 'components/PriceInput';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Frame from 'layouts/Frame';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  description_full: Yup.string(),
  igv: Yup.boolean().required('Seleccione'),
  show: Yup.boolean().required('Seleccione'),
  show_supplier: Yup.boolean().required('Seleccione'),
  show_contact: Yup.boolean().required('Seleccione'),
  currency: Yup.string().nullable().required('Selección Requerida'),
  cost: Yup.string().price('Ingrese monto correcto').required('Costo requerido'),
});

const API_CALL = API.Service;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  values,
  create,
  children: render,
}) => {
  const ability = useContext(AbilityContext);

  return (
    <Form autoComplete="off" autoCorrect="off">
      {render({
        body: (
          <YupProvider value={validationSchema}>
            <Box>
              <Box display="flex">
                <Box p={2} width={1}>
                  <FormInput label="Nombre del servicio" name="description" />
                </Box>
              </Box>
              <Box display="flex">
                <Box p={2} width={1 / 3}>
                  <PriceInput label="Costo Unitario" name="cost" currency={values.currency} />
                </Box>
                <Box p={2} width={1 / 3}>
                  <CurrencySelect label="Moneda" name="currency" />
                </Box>
                <Box p={2} width={1 / 3} textAlign="center">
                  <IGVSwitch label="¿Incluye IGV?" name="igv" />
                </Box>
              </Box>
              {ability.can('edit', 'app') && (
                <Frame title="Visualización en aplicación (APP)">
                  <Box display="flex">
                    <Box p={2} width={2 / 5}>
                      <FormInput
                        label="Descripción servicio"
                        name="description_full"
                        multiline
                        rows={4}
                        size="medium"
                      />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch label="Servicio Visible" name="show" />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch label="Proveedor Visible" name="show_supplier" />
                    </Box>
                    <Box p={2} pt={3} width={1 / 6} style={{ textAlign: 'center' }}>
                      <IGVSwitch label="Encargado Visible" name="show_contact" />
                    </Box>
                  </Box>
                </Frame>
              )}
            </Box>

            {isSubmitting && <LinearProgress />}
            <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
          </YupProvider>
        ),
        title: create ? 'Agregar Servicio' : 'Editar Servicio',
        handleSubmit,
        isSubmitting,
      })}
    </Form>
  );
};

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    description: data.description || '',
    cost: data.cost || '',
    currency: data.currency || 'SOLES',
    igv: data.igv ?? true,
    show: data.show ?? true,
    description_full: data.description_full || '',
    show_supplier: data.show_supplier ?? true,
    show_contact: data.show_contact ?? false,
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
        supplier,
      },
    },
  ) => {
    // Submit
    const submit = {
      ...values,
      supplier,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id, { time: Date.now() }));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default function Payment({
  // * Props
  id = null,
  create = true,
  supplier,
  ...res
}) {
  if (!supplier) {
    return <ErrorConexion code={404} />;
  }
  if (id) {
    return <UpdateForm id={id} supplier={supplier} {...res} />;
  }
  if (create) {
    return <CustomForm create supplier={supplier} {...res} />;
  }
  return <ErrorConexion code={404} />;
}

Payment.propTypes = {
  create: PropTypes.bool,
  id: PropTypes.number,
  supplier: PropTypes.number.isRequired,
};
