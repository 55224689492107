import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { str2format, str2fullformat } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `1px solid ${grayColor[5]}`,
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
}));

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        width: 'max-content',
        margin: 'auto',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff',
        '&:hover': {
          color: '#ffebe9',
        },
        '&:focus': {
          color: '#fff',
        },
      },
      active: {
        color: '#fff !important',
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#00bcd4 !important',
        color: '#fff',
      },
      root: {
        padding: '0 10px !important',
        fontSize: '0.75em !important',
        width: 'max-content !important',
      },
    },
  },
});

export default function Pending(props) {
  const classes = useStyles();

  const history = useHistory();

  const { path } = props;

  const ability = useContext(AbilityContext);

  const handleSearch = (query, getData) =>
    getData({
      status: 'APPROVED',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <GridContainer justify="center">
      <GridItem md={12} xl={8}>
        <ThemeProvider theme={theme}>
          <MaterialTable
            title="Esperando Pago"
            columns={[
              {
                title: 'Seguimiento',
                field: 'code',
              },
              {
                title: 'Ordenes / Reservas / Pax',
                field: 'entered_group',
                render: ({ entered_group: { service_orders: orders } }) =>
                  orders.map(ord => (
                    <table key={ord.id} className={classes.table}>
                      <tbody>
                        <tr>
                          <td
                            rowSpan={ord.service_request.length + 1}
                            style={{
                              fontSize: '0.8rem',
                              border: '1px solid #DDD',
                            }}
                          >
                            {ability.can('manage', 'operations') ? (
                              <Link to={`${goto(MODS.SERVICE_ORDERS)}/${ord.id}`}>{ord.code}</Link>
                            ) : (
                              <span>{ord.code}</span>
                            )}

                            <br />
                            <div style={{ fontSize: '0.6rem' }}>
                              Costo: {ord.total_cost_format}{' '}
                              {ability.can('manage', 'operations') && (
                                <span>( Servicio para el {ord.date_in ? str2format(ord.date_in) : '---'})</span>
                              )}
                            </div>
                          </td>
                          {ability.can('manage', 'reservations') && (
                            <>
                              <td
                                style={{
                                  fontSize: '0.5rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                Reserva
                              </td>
                              <td
                                style={{
                                  fontSize: '0.5rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                Pax
                              </td>
                            </>
                          )}
                          {/* <td colSpan={2} style={{ display: 'none' }}>{' '}</td> */}
                        </tr>
                        {ability.can('manage', 'reservations') &&
                          ord.service_request.map(({ reservation_detail: { reservation } }) => (
                            <tr key={reservation.id}>
                              <td>
                                <Link to={`${goto(MODS.RESERVATIONS)}/${reservation.id}`}>{reservation.code}</Link>
                              </td>
                              <td>({reservation.client.name})</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )),
              },
              { title: 'Comprobante', field: 'voucher_code' },
              { title: 'Proveedor', field: 'supplier' },
              { title: 'Costo', field: 'cost_format' },
              {
                title: 'Ingreso',
                field: 'created',
                render: ({ created: date }) => str2fullformat(date),
                emptyValue: '---',
              },
              {
                title: 'Aprobación',
                field: 'approved_date',
                render: ({ approved_date: date }) => str2fullformat(date),
                emptyValue: '---',
                // sorting: false,
                // hidden: true,
                // hiddenByColumnsButton: true,
              },
              // ...commonCols(true, true)
            ]}
            data={query => handleSearch(query, API.ServiceOrderPayment.all)}
            options={{
              headerStyle: {
                // backgroundColor: '#01579b',
                color: '#FFF',
              },
              draggable: false,
              // loadingType: 'linear',
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            components={{
              OverlayLoading: () => <></>,
              Container: props => props.children,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Sin resultados',
                addTooltip: 'Agregar',
                deleteTooltip: 'Eliminar',
                editTooltip: 'Editar',
                editRow: {
                  deleteText: '¿Está seguro que desea eliminar esta fila?',
                  cancelTooltip: 'Cancelar',
                  saveTooltip: 'Guardar',
                },
              },
              header: {
                actions: 'Acciones ',
              },
              pagination: {
                labelRowsPerPage: 'Filas por página',
                labelRowsSelect: 'Filas Mostradas',
                labelDisplayedRows: '{count} resultados',
                firstAriaLabel: 'Primera página',
                firstTooltip: 'Primera página',
                previousAriaLabel: 'Anterior página',
                previousTooltip: 'Anterior página',
                nextAriaLabel: 'Siguiente página',
                nextTooltip: 'Siguiente página',
                lastAriaLabel: 'Última página',
                lastTooltip: 'Última página',
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
                nRowsSelected: '{0} seleccionado(s)',
              },
            }}
            actions={[
              // rowData => ({
              //   icon: 'delete',
              //   tooltip: 'Delete User',
              //   onClick: (event, rowData) => console.log(`You want to delete ${rowData.code}`),
              //   disabled: rowData.birthYear < 2000
              // }),
              // eslint-disable-next-line no-unused-vars
              rowData => ({
                icon: 'gavel',
                tooltip: 'Procesar Pago',
                onClick: (event, rowData) => history.push(`${path}/${rowData.id}`),
              }),
            ]}
            // options={{
            //   actionsColumnIndex: -1
            // }}
          />
        </ThemeProvider>
      </GridItem>
    </GridContainer>
  );
}
