import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function IGVSwitch(props) {
  const [, { value }, { setValue }] = useField(props);
  const [defaultValue] = useState(value);
  const { isSubmitting } = useFormikContext();
  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 1000);

  const year = String(new Date().getFullYear()).substr(2, 2);
  return (
    <>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue={defaultValue}
        onChange={({ target: { value } }) => debouncedHandleOnChange(value)}
        disabled={isSubmitting}
      >
        <FormControlLabel value="FILE" control={<Radio color="primary" />} label={`FILE-${year}-XXXXX`} />
        <FormControlLabel value="INT" control={<Radio color="primary" />} label={`INT-${year}-XXXXX`} />
      </RadioGroup>
    </>
  );
}
