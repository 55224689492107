import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useYupRequired } from 'utils/hooks';

export default function FormInput({
  // * Props
  label,
  name,
  uppercase = false,
  delay = 500,
  ...attr
}) {
  // * "Componente por defecto"
  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const required = useYupRequired(name);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, delay);

  return (
    <>
      <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      <TextField
        onChange={({ target: { value: text } }) => debouncedHandleOnChange(uppercase ? text.toUpperCase() : text)}
        defaultValue={value}
        name={name}
        variant="outlined"
        fullWidth
        size="small"
        // type={name.includes('name') ? 'search' : 'text'}
        autoComplete="off"
        inputProps={{
          autoComplete: 'off', // disable
          style: { textTransform: uppercase ? 'uppercase' : 'initial' },
        }}
        {...attr}
        disabled={isSubmitting}
      />
      <ErrorMessage name={name} />
    </>
  );
}
