import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function Frame({ children, title }) {
  return (
    <Box component={Paper} p={1} m={1} marginBottom={2}>
      {title && (
        <Box paddingX={2} paddingY={1}>
          <Typography color="primary">{title}</Typography>
          <Divider />
        </Box>
      )}
      {children}
    </Box>
  );
}
