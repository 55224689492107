import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import Calendar from './Calendar';
import Bible from './Bible';

export default function Edit() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (ability.can('manage', MODS.RESERVATIONS)) {
      setTabs([
        {
          title: 'Calendario',
          icon: <DateRangeIcon />,
          route: '',
          content: <Calendar />
        },
        {
          title: 'Resumen diario',
          icon: <LocalLibraryIcon />,
          route: '/view',
          content: <Bible />
        },
      ]);
    }
  }, []);

  return (
    <ContentTabs tabs={tabs} bgcolor="none" />
  );
}
