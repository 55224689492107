import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import FormInputDe from 'components/FormInputDe';
import PaymentForSelect from 'components/PaymentForSelect';
import PaymentMethodSelect from 'components/PaymentMethodSelect';
import PaymentVoucherSelect from 'components/PaymentVoucherSelect';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';
import React from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import { VOUCHER_CODE } from 'config';
import PriceInput from 'components/PriceInput';
import CurrencySelect from 'components/CurrencySelect';

const validationSchema = Yup.object().shape({
  voucher_type: Yup.number().nullable().pk().required('Seleccione tipo de comprobante'),
  voucher_code: Yup.string()
    .trim()
    .when('voucher_type', (voucherType, schema) => {
      if (voucherType === VOUCHER_CODE.FACTURA) return schema.factura('Formato incorrecto');
      if (voucherType === VOUCHER_CODE.BOLETA) return schema.boleta('Formato incorrecto');
      return schema.min(3, 'Se requiere al menos 3 caracteres');
    })
    .required('Ingrese codigo de comprobante'),
  voucherfor: Yup.string().nullable().required('Seleccione destinatario'),
  voucherfor_value: Yup.string().ensure().trim().required('Ingrese un nombre'),
  payment_method: Yup.number().nullable().pk().required('Seleccione un método de pago'),
  currency: Yup.string().nullable().required('Selección Requerida'),
  amount: Yup.string().price('Ingrese monto correcto').required('Monto requerido'),
  comment: Yup.string().ensure(),
});

const API_CALL = API.ReservationPayment;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  values,
  create,
  children: render,
  reservation,
  setFieldValue,
}) => (
  <Form autoComplete="off" autoCorrect="off">
    {render({
      body: (
        <YupProvider value={validationSchema}>
          <Box display="flex">
            <Box marginY={1} marginX={2} width={1 / 2}>
              <PaymentVoucherSelect label="Comprobante" name="voucher_type" />
            </Box>
            <Box marginY={1} marginX={2} width={1 / 2}>
              <FormInput label="Código" name="voucher_code" />
            </Box>
          </Box>

          <Box display="flex">
            <Box marginY={1} marginX={2} width={1 / 3}>
              <PriceInput label="Monto de pago" name="amount" currency={values.currency} />
            </Box>
            <Box marginY={1} marginX={2} width={1 / 3}>
              <CurrencySelect label="Moneda" name="currency" />
            </Box>
            <Box marginY={1} marginX={2} width={1 / 3}>
              <PaymentMethodSelect label="Método de pago" name="payment_method" />
            </Box>
          </Box>

          <Box display="flex">
            <Box marginY={1} marginX={2} width={5 / 12}>
              <PaymentForSelect
                reservation={reservation}
                label="A Nombre de"
                name="voucherfor"
                onSelect={sel => setFieldValue('voucherfor_value', sel)}
              />
            </Box>
            <Box marginY={1} marginX={2} width={7 / 12}>
              <FormInputDe label="Nombre" name="voucherfor_value" placeholder="Ingrese nombre" />
            </Box>
          </Box>

          <Box>
            <Box marginY={1} marginX={2}>
              <FormInput label="Comentario" name="comment" placeholder="Enter para más lineas" multiline />
            </Box>
          </Box>

          {isSubmitting && <LinearProgress />}
          <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
        </YupProvider>
      ),
      title: create ? 'Agregar Pago' : 'Editar Pago',
      handleSubmit,
      isSubmitting,
    })}
  </Form>
);

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    voucher_type: data.voucher_type || null,
    voucher_code: data.voucher_code || '',
    voucherfor: data.voucherfor || null,
    voucherfor_value: data.voucherfor_value || '',
    payment_method: data.payment_method || null,
    currency: data.currency || 'SOLES',
    amount: data.amount || 0.0,
    comment: data.comment || '',
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
        reservation,
      },
    },
  ) => {
    // Submit
    const submit = {
      ...values,
      reservation,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id, { time: Date.now() }));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default function Payment({
  // * Props
  id = null,
  create = true,
  reservation,
  ...res
}) {
  if (!reservation) {
    return <ErrorConexion code={404} />;
  }
  if (id) {
    return <UpdateForm id={id} reservation={reservation} {...res} />;
  }
  if (create) {
    return <CustomForm create reservation={reservation} {...res} />;
  }
  return <ErrorConexion code={404} />;
}

Payment.propTypes = {
  create: PropTypes.bool,
  id: PropTypes.number,
  reservation: PropTypes.number.isRequired,
};
