import { Box, Button, LinearProgress } from '@material-ui/core';
import FormInput from 'components/FormInput';
import Select from 'components/Select';
import { Form, Formik } from 'formik';
import Frame from 'layouts/Frame';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';
import { YupProvider } from 'utils/YupContext';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  custom_fields: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(), // .required('No contiene valor'),
      }),
    )
    .nullable()
    .required('Debe enviar al menos un campo'),
});

export default function Payments() {
  const { id } = useParams();

  const [supplierData] = useFetchData(() => API.Supplier.get(id));
  const [customMetaData] = useFetchData(() => API.CustomMeta.all({ get_all: true, supplier: id }));
  const [customFieldData, setQueryCustomField] = useFetchData(API.CustomField.all, null);

  useEffect(() => {
    if (supplierData) {
      setQueryCustomField({ get_all: true, supplier_type: supplierData.supplier_type });
    }
  }, [supplierData]);

  if (!customFieldData || !customMetaData) {
    return <div>Loading...</div>;
  }

  return (
    <YupProvider value={schema}>
      <Formik
        initialValues={{
          custom_fields: customFieldData.map(field => {
            const meta = customMetaData?.find(i => i.custom_field === field.id) || null;
            return {
              id: meta?.id || null,
              custom_field: field.id,
              value: meta?.value || '',
            };
          }),
        }}
        validationSchema={schema}
        onSubmit={values => {
          API.Supplier.post(id, 'customfields', {
            ...values,
          })
            .then(() => {
              Toast.fire({
                icon: 'success',
                title: 'Información guardada.',
              });
              window.location.reload();
            })
            .catch(() => {
              Toast.fire({
                icon: 'error',
                title: 'Ocurrió un error.',
              });
            });
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form autoComplete="off">
            {customFieldData?.map((field, index) => (
              <Frame title={field.name} key={`field_${field.id}`}>
                <Box width={1 / 3} p={2}>
                  {field.options ? (
                    <Select
                      name={`custom_fields.${index}.value`}
                      options={field.options}
                      optionLabel="label"
                      optionValue="value"
                    />
                  ) : (
                    <FormInput
                      name={`custom_fields.${index}.value`}
                      multiline={field.multiline}
                      rows={4}
                      size={field.multiline ? 'medium' : 'small'}
                    />
                  )}
                </Box>
              </Frame>
            ))}
            {isSubmitting && <LinearProgress />}
            <Box p={2}>
              <Button disabled={isSubmitting} onClick={submitForm} color="primary" variant="contained">
                Guardar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </YupProvider>
  );
}
