import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  title: {
    background: 'linear-gradient(60deg, #E8EAF6, #E8EAF6)',
    // boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgb(158 158 158 / 0.4)',
    padding: '10px',
    borderRadius: '5px 5px 0 0',
    '& h4': {
      marginTop: '0px',
      minHeight: 'auto',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
      color: '#607D8B',
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
  },
}));

export default function HeaderTitle(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.title} style={props.style}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{}}>
                <h4>{props.title}</h4>
              </td>
              <td style={{ textAlign: 'right' }}>{props.children}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
