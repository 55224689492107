import React from 'react';
import FormInput from 'components/FormInput';
import LanguageSelect from 'components/LanguageSelect';
import ComingFromSelect from 'components/ComingFromSelect';
import { Grid } from '@material-ui/core';

export default function Information() {
  return (
    <Grid style={{ margin: '15px 0', rowGap: '20px' }} container justifyContent="space-between" direction="column">
      <Grid container justifyContent="space-around" direction="row">
        <Grid item xs={12} md={3}>
          <FormInput label="Punto de recojo" name="pick_up_point" multiline />
        </Grid>
        <Grid item xs={12} md={3}>
          <ComingFromSelect label="Origen del Grupo" name="coming_from" />
        </Grid>
        <Grid item xs={12} md={3}>
          <LanguageSelect label="Idioma" name="language" />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" direction="row">
        <Grid item xs={12} md={5}>
          <FormInput label="OBSERVACIONES GENERALES" name="observations" multiline />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormInput label="INDICACIONES PARA CLIENTE" name="specialreqs" multiline />
        </Grid>
      </Grid>
    </Grid>
  );
}
