import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import Save from 'views/People/Save';
import Clients from './Clients';
import Passengers from './Passengers';

export default function TabMenu() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);
  const { path } = useRouteMatch();

  useEffect(() => {
    const pat = [];

    if (ability.can('manage_clients', MODS.PASSENGERS)) {
      const route = '/clientes';
      pat.push({
        title: 'PAX PRINCIPALES',
        icon: <EmojiPeopleIcon />,
        route,
        content: (
          <Switch>
            <Route path={`${path + route}/nuevo`}>
              <Save />
            </Route>
            <Route path={`${path + route}/:id`}>
              <Save />
            </Route>
            <Route path={path + route}>
              <Clients path={path + route} />
            </Route>
          </Switch>
        ),
      });
    }

    if (ability.can('manage_passengers', MODS.PASSENGERS)) {
      const route = '/secundarios';
      pat.push({
        title: 'PAX SECUNDARIOS',
        icon: <DirectionsWalkIcon />,
        route,
        content: (
          <Switch>
            <Route path={`${path + route}/nuevo`}>
              <Save />
            </Route>
            <Route path={`${path + route}/:id`}>
              <Save />
            </Route>
            <Route path={path + route}>
              <Passengers />
            </Route>
          </Switch>
        ),
      });
    }

    if (pat.length) {
      setTabs(pat);
    }
  }, [ability, path]);

  return <ContentTabs tabs={tabs} defaultIndex={0} />;
}
