import { Avatar, Box, Button, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';

export default function MainForm({ component: Form, ...attr }) {
  return (
    <Form {...attr}>
      {({ body, isSubmitting }) => (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box marginTop={8} display="flex" flexDirection="column" alignItems="center">
            <Box component={Avatar} margin={1} bgcolor="secondary.main">
              <LockOutlinedIcon />
            </Box>
            <Typography component="h1" variant="h5">
              Ingresar al sistema
            </Typography>
            {body}
          </Box>
          <Box marginY={3}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
              Ingresar
            </Button>
          </Box>
        </Container>
      )}
    </Form>
  );
}
