import { Grid, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ErrorMessage from 'components/ErrorMessage';
import GridItem from 'components/Grid/GridItem';
import PackageSelect from 'components/PackageSelect';
import { symbolCurrency } from 'config';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import ServicesForm from './ServicesForm';

const useStyles = makeStyles({
  gridItem: {
    padding: '0 15px',
    margin: '10px 0 20px',
  },
});

function reducer(state, action) {
  switch (action.type) {
    case 'CALC_SOLES':
      return {
        ...state,
        soles: action.services.filter(i => i.currency === 'SOLES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_DOLARES':
      return {
        ...state,
        dolares: action.services.filter(i => i.currency === 'DOLARES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_EUROS':
      return {
        ...state,
        euros: action.services.filter(i => i.currency === 'EUROS').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    default:
      return state;
  }
}

export default function Origin() {
  const {
    values: { reservation_details: details },
  } = useFormikContext();
  const classes = useStyles();

  const [total, dispatch] = React.useReducer(reducer, {
    soles: 0,
    dolares: 0,
    euros: 0,
  });

  const handleRemove = (proceed, item) => {
    Swal.fire({
      title: `¿Eliminar ${item.package_name}?`,
      text: 'Se eliminará completamente cuando guarde la reserva.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: '¡Eliminar!',
      cancelButtonText: 'Mantener.',
    }).then(result => {
      if (result.value) {
        proceed();
      }
    });
  };

  useEffect(() => {
    dispatch({ type: 'CALC_SOLES', services: details });
    dispatch({ type: 'CALC_DOLARES', services: details });
    dispatch({ type: 'CALC_EUROS', services: details });
  }, [details]); // (total);

  return (
    <Grid style={{ margin: '15px 0', rowGap: '20px' }} container justifyContent="space-between" direction="column">
      <Grid item xs={12}>
        <PackageSelect classGridItem={classes.gridItem} name="reservation_details" onRemove={handleRemove} />
      </Grid>
      <Grid item xs={12}>
        <FieldArray
          name="reservation_details"
          render={arrayHelpers => (
            <div name="reservation_details">
              {details.map((rowDetail, index) => (
                <ServicesForm
                  remove={() => handleRemove(() => arrayHelpers.remove(index), rowDetail)}
                  name={`reservation_details.${index}`}
                  key={`package${rowDetail.package}_${rowDetail.id || `new_${String(index)}`}`}
                />
              ))}
              <ErrorMessage name="reservation_details" />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper
          square
          elevation={3}
          style={{
            backgroundColor: '#CDDC39',
            fontWeight: 'bold',
            marginTop: '5px',
          }}
        >
          <Grid container justifyContent="flex-start">
            {!!total.soles && (
              <>
                <GridItem xs={6} md={2}>
                  <small>TOTAL SOLES</small>
                </GridItem>
                <GridItem md={6}>{`${symbolCurrency.SOLES}${total.soles.toFixed(2)}`}</GridItem>
              </>
            )}
            {!!total.dolares && (
              <>
                <GridItem xs={6} md={2}>
                  <small>TOTAL DOLARES</small>
                </GridItem>
                <GridItem xs={6} md={2}>
                  {symbolCurrency.DOLARES + total.dolares.toFixed(2)}
                </GridItem>
              </>
            )}
            {!!total.euros && (
              <>
                <GridItem xs={6} md={2}>
                  <small>TOTAL EUROS</small>
                </GridItem>
                <GridItem xs={6} md={2}>
                  {symbolCurrency.EUROS + total.euros.toFixed(2)}
                </GridItem>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
