import Paper from '@material-ui/core/Paper';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { es } from 'date-fns/locale';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory } from 'react-router-dom';
import { API } from 'utils/api';
import { goto, MODS } from 'utils/structure';
import { addDays, subDays } from 'date-fns';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales: { es },
});
function Event({ event }) {
  return (
    <small>
      ({format(event.start, 'hh:mm a')}) {event.title}: <b>{event.reservation_client}</b>
    </small>
  );
}

function EventAgenda({ event }) {
  return (
    <span>
      <em style={{ color: 'magenta' }}>{event.title}</em>
    </span>
  );
}

const date = new Date();

export default function MyCalendar() {
  const [myEvents, setMyEvents] = useState([]);

  const [start, setStart] = useState(subDays(new Date(date.getFullYear(), date.getMonth(), 1), 6));
  const [end, setEnd] = useState(addDays(new Date(date.getFullYear(), date.getMonth() + 1, 0), 6));

  // cargando data de acuerdo a start_date con 3 dias margin

  function loadData() {
    API.Book.all({
      get_all: true,
      start_date__gte: `${format(subDays(start, 3), 'yyyy-MM-dd')}T00:00:00`,
      start_date__lte: `${format(addDays(end, 3), 'yyyy-MM-dd')}T23:59:59`,
    }).then(({ data }) => {
      setMyEvents(
        data.map(i => ({
          id: i.id,
          title: i.package_name,
          start: i.start_date ? new Date(i.start_date) : null,
          end: i.comple_date ? new Date(i.comple_date) : null,
          reservation_code: i.reservation_obj.code,
          reservation_client: i.reservation_obj.client,
          reservation: i.reservation,
        })),
      );
    });
  }

  useEffect(() => {
    loadData();
  }, [start, end]);

  function onChange(range) {
    if (range?.start && range?.end) {
      setStart(range.start);
      setEnd(range.end);
    } else if (Array.isArray(range) && !!range?.length) {
      setStart(range.at(0));
      setEnd(range.at(-1));
    }
  }

  const history = useHistory();

  return (
    <Paper variant="outlined" square>
      <Calendar
        // date={date}}
        defaultView="month"
        localizer={localizer}
        onRangeChange={range => onChange(range)}
        events={myEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        culture="es"
        formats={{
          dayFormat: (date, culture, localizer) => localizer.format(date, 'EEE dd/LL', culture),
          dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'EEEE dd MMMM', culture),
          agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'EEE dd MMMM', culture),
          agendaHeaderFormat: ({ start, end }, culture, localizer) =>
            `${localizer.format(start, 'dd MMMM', culture)} — ${localizer.format(end, 'dd MMMM', culture)}`,
          // eventTimeRangeFormat: ({ start, end }, culture, localizer) => `${localizer.format(
          //   start, 'dd MMMM', culture
          // )} — ${localizer.format(
          //   end, 'dd MMMM', culture
          // )}`
        }}
        messages={{
          allDay: 'Todo el día',
          previous: 'Anterior',
          next: 'Siguiente',
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          agenda: 'Agenda',
          date: 'Fecha',
          time: 'Hora',
          event: 'Tour', // Or anything you want
          showMore: total => `+ ${total} eventos`,
          tomorrow: 'Mañana',
          yesterday: 'Ayer',
          noEventsInRange: 'No hay servicios en esta fecha. ',
        }}
        // min={new Date(1970, 1, 1, 6)}
        // scrollToTime={new Date(2020, 3, 14, 11, 0, 0)}
        popup
        selectable
        // onNavigate={console.log}
        // onDoubleClickEvent={data => history.push(`${goto(MODS.RESERVATIONS)}/${data.reservation}/ver`)}
        onSelectEvent={data => history.push(`${goto(MODS.RESERVATIONS)}/${data.reservation}/ver`)}
        // showMultiDayTimes
        views={['month', 'week']}
        components={{
          event: Event,
          agenda: {
            event: EventAgenda,
          },
        }}
      />
    </Paper>
  );
}
