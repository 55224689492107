import Select from 'components/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function CountrySelect({
  // * Props
  label,
  name,
  placeholder,
}) {
  // * "Componente por defecto"

  const [data] = useFetchData(API.Country.all);

  return (
    <>
      <Select options={data} placeholder={placeholder} name={name} label={label} modeValue />
    </>
  );
}

CountrySelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
