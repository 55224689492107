import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CurrencySelect from 'components/CurrencySelect';
import DatePicker from 'components/DatePicker2';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ModeServiceSelect from 'components/ModeServiceSelect';
import PriceInput from 'components/PriceInput';
import { symbolCurrency } from 'config';
import { useField } from 'formik';
import React, { useState } from 'react';
import ServicesRequest from './ServicesRequest';

export default function Services(props) {
  // * Clases

  const { remove, name } = props;

  const [addRow, setAddRow] = useState(false);
  const [addObservations, setAddObservations] = useState(false);
  const [, { value: detail }] = useField(props);

  return (
    <Grid style={{ margin: '15px 0', rowGap: '20px' }} container justifyContent="space-between" direction="column">
      <Grid item xs={12} style={{ margin: '5px 10px' }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div
              style={{
                padding: '4px 10px',
                marginBottom: '10px',
                backgroundColor: '#3F51B5',
              }}
            >
              <Tooltip title="Eliminar Tour">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    remove();
                  }}
                  style={{
                    color: '#FFF',
                    float: 'right',
                    padding: '0 2px',
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Hacer una Observación">
                <IconButton
                  aria-label="comment"
                  onClick={() => {
                    setAddObservations(!addObservations);
                  }}
                  style={{
                    color: '#FFF',
                    float: 'right',
                    padding: '2px 2px 0',
                  }}
                >
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Agregar un servicio de proveedor">
                <IconButton
                  aria-label="add_service"
                  onClick={() => setAddRow(prev => !prev)}
                  style={{
                    color: '#FFF',
                    float: 'right',
                    padding: '0 2px',
                  }}
                >
                  <NoteAddIcon />
                </IconButton>
              </Tooltip>
              <Typography style={{ color: '#FFF' }}>
                {`(${symbolCurrency[detail.currency]}
                  ${parseFloat(detail.price).toFixed(2)})
                  ${detail.package_name}`}
              </Typography>
              <Divider />
            </div>
          </Grid>
        </Grid>
        <GridContainer>
          <GridItem md={2}>
            <ModeServiceSelect label="Modo" name={`${name}.mode`} />
          </GridItem>
          <GridItem md={3}>
            <DatePicker
              label="Inicio"
              placeholderText="Seleccione Fecha y Hora"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              name={`${name}.start_date`}
              valueFormat="yyyy-MM-dd'T'HH:mm:ss"
            />
          </GridItem>
          <GridItem md={3}>
            <DatePicker
              label="Culminación"
              placeholderText="Seleccione Fecha y Hora"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              name={`${name}.comple_date`}
              minDate={detail.start_date}
              valueFormat="yyyy-MM-dd'T'HH:mm:ss"
              disabled={!detail.start_date}
            />
          </GridItem>
          <GridItem md={2}>
            <PriceInput label="Precio" name={`${name}.price`} currency={detail.currency} />
          </GridItem>
          <GridItem md={2}>
            <CurrencySelect label="Moneda" name={`${name}.currency`} />
          </GridItem>
        </GridContainer>
        <ServicesRequest name={`${name}.service_requests`} detail={detail} addRow={addRow} setAddRow={setAddRow} />
        <Grid container>
          {(addObservations || detail.observations) && (
            <Grow in>
              <Grid item xs={12} md={12} style={{ margin: '10px 10px' }}>
                <FormInput
                  label="Observaciones"
                  name={`${name}.observations`}
                  placeholder="Enter para más lineas"
                  multiline
                />
              </Grid>
            </Grow>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
