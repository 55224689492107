import { TableHead } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
// core components
import styles from 'assets/jss/material-dashboard-react/components/tasksStyle.js';
import classnames from 'classnames';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { symbolCurrency } from 'config';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { API } from 'utils/api';
import { str2format } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';
import CostUpdate from './components/CostUpdate';
import DatePicker from './components/DatePicker2';

const useStyles = makeStyles(theme => ({
  ...styles,
  tableHead: {
    padding: 0,
    borderBottom: 0,
    backgroundColor: '#1769aa',
    color: 'white',
  },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  datepick: {
    '& .react-datepicker__input-container': {
      width: '100%',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__input-container input': {
      width: 'calc(100% - 16px)',
      fontSize: '0.8rem',
    },
    '& .react-datepicker__close-icon::after': {
      padding: '0',
    },
  },
  quantityCell: {
    width: '8% !important',
    padding: '3px',
  },
  tableCheckSelect: {
    '& td input[checked=checked]': {
      backgroundColor: '#EEE',
    },
  },
}));

export default function ServiceRequestChecked({
  serviceRequest: { serviceRequestData, setServiceRequestData, serviceRequest, setServiceRequest },
  onChange = () => {},
  forceOnChange = null,
  ...props
}) {
  const classes = useStyles();

  const tableCellClasses = classnames([classes.tableCell, classes.datepick]);

  const [, { value }, { setValue }] = useField(props);
  const { values, setFieldValue } = useFormikContext();

  const handleChange = () => {
    const update = {};

    [...serviceRequestData, ...serviceRequest]
      .filter(i => values.service_requests.includes(i.id))
      .forEach(({ id, ...attr }) => {
        attr.igv = props.igv;
        update[id] = attr;
      });
    onChange(update);
    forceOnChange = null;
  };

  useEffect(() => {
    if (!forceOnChange) return;

    handleChange();
  }, [forceOnChange]);

  // * Cargando si existen Ids
  useEffect(() => {
    if (value.length) {
      API.ServiceRequest.all({
        get_all: true,
        id__in: value.join(','),
      }).then(({ data }) => {
        setServiceRequest(data);
      });
    }
  }, []);

  useEffect(() => {
    if (props.service) {
      const query = {
        get_all: true,
        service_order__isnull: true,
        service: props.service,
      };
      if (props.date_in) {
        query.date_in = props.date_in;
      }
      if (props.currency) {
        query.currency = props.currency;
      }
      API.ServiceRequest.all(query).then(({ data }) => {
        setServiceRequestData(data);
      });
    }
  }, [props.service, props.date_in, props.currency]);

  // * Cada que se cambia los check se hace de nuevo la suma
  useEffect(() => {
    let cost = 0.0;
    cost += serviceRequestData.filter(j => value.includes(j.id)).reduce((a, i) => a + parseFloat(i.cost), 0);
    cost += serviceRequest.filter(j => value.includes(j.id)).reduce((a, i) => a + parseFloat(i.cost), 0);

    setFieldValue('cost', cost.toFixed(2));

    let numPax = 0;
    numPax += serviceRequestData.filter(j => value.includes(j.id)).reduce((a, i) => a + parseInt(i.num_pax, 10), 0);
    numPax += serviceRequest.filter(j => value.includes(j.id)).reduce((a, i) => a + parseInt(i.num_pax, 10), 0);
    setFieldValue('num_pax', numPax);
  }, [value, serviceRequest, serviceRequestData]);

  // * Puede causar lag
  useEffect(() => {
    document.querySelectorAll('tbody td').forEach(i => {
      const tr = i.closest('tr');
      tr.querySelectorAll('td').forEach(td => {
        td.style.backgroundColor = '#EEE';
      });
    });

    document.querySelectorAll('tbody td > .Mui-checked').forEach(i => {
      const tr = i.closest('tr');
      tr.querySelectorAll('td').forEach(td => {
        td.style.backgroundColor = '#FFF';
      });
    });
  }, [value, serviceRequest, serviceRequestData]);

  const tableRender = (source, setSource) => (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHead} />
          <TableCell className={classes.tableHead}>File</TableCell>
          <TableCell className={classes.tableHead}>Pax Principal</TableCell>
          <TableCell className={classes.tableHead}>Num Pax</TableCell>
          <TableCell
            className={classes.tableHead}
            // style={{ textAlign: 'center' }}
          >
            Costo
          </TableCell>
          <TableCell className={classes.tableHead}>Inicio</TableCell>
          <TableCell className={classes.tableHead}>Hora</TableCell>
          <TableCell className={classes.tableHead}>Finalización</TableCell>
          {/* <TableCell /> */}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableCheckSelect}>
        {source.map(item => (
          <TableRow key={`task${String(item.id)}`} className={classes.tableRow} style={{ backgroundColor: '#EEE' }}>
            <TableCell className={tableCellClasses}>
              <Checkbox
                checked={value.includes(item.id)}
                tabIndex={-1}
                onClick={({ target: { checked } }) => {
                  if (checked) {
                    setValue([...value, item.id]);
                  } else {
                    setValue(value.filter(i => i !== item.id));
                  }
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root,
                }}
              />
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              <Link to={`${goto(MODS.RESERVATIONS)}/${parseInt(item.reservation_id, 10)}/ver`} target="_blank">
                {item.reservation_code}
              </Link>
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              {/* Ref: */}
              {/* {' '} */}
              {item.reservation_client}
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              <strong>({item.num_pax_format} pax)</strong>
              <br />
              <small>{item.num_pax} pasajeros</small>
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              <small style={{ marginLeft: '2px' }}>
                {`${symbolCurrency[item.currency]} ${parseFloat(item.cost / item.num_pax).toFixed(2)} `}x Pax
              </small>
              <CostUpdate
                defaultValue={item.cost}
                currency={props.currency}
                setValRequest={text =>
                  setSource(prev =>
                    prev.map(i =>
                      i.id === item.id
                        ? {
                            ...i,
                            cost: text,
                          }
                        : i,
                    ),
                  )
                }
              />
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              {str2format(item.date_in)}
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              {/* {(item.time_in || '-')} */}
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Hora"
                placeholderText="Seleccione Hora"
                dateFormat="h:mm aa"
                // name={`${name}.${index}.time_in`}
                customInput={null}
                valueFormat="HH:mm:ss"
                defaultValue={item.time_in}
                setValRequest={text =>
                  setSource(prev =>
                    prev.map(i =>
                      i.id === item.id
                        ? {
                            ...i,
                            time_in: text,
                          }
                        : i,
                    ),
                  )
                }
              />
            </TableCell>
            <TableCell className={tableCellClasses} style={{ padding: 0 }}>
              {/* {item.date_out} */}
              <DatePicker
                placeholderText="Fecha de Finalización"
                dateFormat="dd/MM/yyyy"
                // minDate={item.date_in}
                customInput={null}
                valueFormat="yyyy-MM-dd"
                defaultValue={item.date_out}
                setValRequest={text =>
                  setSource(prev =>
                    prev.map(i =>
                      i.id === item.id
                        ? {
                            ...i,
                            date_out: text,
                          }
                        : i,
                    ),
                  )
                }
              />
            </TableCell>
            {/* <TableCell /> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      {props.isEdit && (
        <>
          <GridContainer>
            <GridItem md={12}>
              <Typography color="primary" display="block" variant="body1">
                RESERVAS
              </Typography>

              {/* <Divider /> */}
              <ErrorMessage name={props.name}>
                {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
              </ErrorMessage>
            </GridItem>
          </GridContainer>
          {props.service && !!serviceRequest?.length && <>{tableRender(serviceRequest, setServiceRequest)}</>}
          <br />
        </>
      )}
      <>
        {!!props.service && serviceRequestData?.length ? (
          <>
            <GridContainer>
              <GridItem md={12}>
                <Typography color="primary" display="block" variant="body1">
                  {props.isEdit ? 'NUEVAS PETICIONES DESDE RESERVAS' : 'RESERVAS'}
                </Typography>

                {/* <Divider /> */}
                <ErrorMessage name={props.name}>
                  {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
                </ErrorMessage>
              </GridItem>
            </GridContainer>
            {tableRender(serviceRequestData, setServiceRequestData)}
          </>
        ) : (
          <>
            {!props.isEdit && (
              <>
                <GridContainer>
                  <GridItem md={12}>
                    <Typography color="primary" display="block" variant="body1">
                      RESERVAS
                    </Typography>

                    <Divider />
                    <ErrorMessage name={props.name}>
                      {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
                    </ErrorMessage>
                  </GridItem>
                </GridContainer>
                <Typography>Seleccione un servicio</Typography>
              </>
            )}
          </>
        )}
      </>
    </>
  );
}
