import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Navbar from 'components/Navbars/Navbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import bgImage from 'assets/img/sidebar-4.jpg';
import logo from 'assets/img/image.png';

const drawerWidth = 200;

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const container = {
  // paddingRight: '15px',
  // paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
};

const styles = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth - 60}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    // overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '50px',
    padding: '30px 15px 0',
    // minHeight: 'calc(100vh - 123px)',
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight * 4}px)`,
  },
  container,
  map: {
    marginTop: '70px',
  },
});

const switchRoutes = structure => (
  <Switch>
    {structure.map(entry =>
      entry.routes.map(route => {
        if (route.routes) {
          return route.routes.map(item => (
            <Route
              // exact
              path={entry.path + route.path + item.path}
              component={item.component}
              key={`${entry.name}-${route.name}-${item.name}`}
            />
          ));
        }
        return null;
      }),
    )}

    {structure.map(entry =>
      entry.routes.map(route => (
        <Route
          // exact
          path={entry.path + route.path}
          component={route.component}
          key={`${entry.name} - ${route.name}`}
        />
      )),
    )}

    {structure.map(entry => {
      if (entry.redirect !== undefined) {
        return <Redirect from={entry.path} to={entry.redirect} key={`redirect${entry.name}`} />;
      }
      if (entry.component !== undefined) {
        return <Route path={entry.path} component={entry.component} key={`component${entry.name}`} />;
      }
      return null;
    })}
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Main({ structure }) {
  // styles
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel}>
        <Sidebar structure={structure} logo={logo} image={bgImage} open={mobileOpen} color="sky" />
        <Navbar structure={structure} handleDrawerToggle={handleDrawerToggle} logo={logo} color="sky" />

        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes(structure)}</div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
