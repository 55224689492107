import { Button, LinearProgress } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchText from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import CurrencySelect from 'components/CurrencySelect';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IGVSwitch from 'components/IGVSwitch';
import PaymentMethodSelect from 'components/PaymentMethodSelect';
import PaymentVoucherSelect from 'components/PaymentVoucherSelect';
import { VOUCHER_CODE } from 'config';
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { date2format, str2format } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import Orders from './Orders';

const validationSchema = Yup.object().shape({
  comment: Yup.string().ensure().trim(),
  voucher_code: Yup.string()
    .trim()
    .when('voucher_type', (voucherType, schema) => {
      if (voucherType === VOUCHER_CODE.FACTURA) return schema.factura('Formato incorrecto');
      if (voucherType === VOUCHER_CODE.BOLETA) return schema.boleta('Formato incorrecto');
      return schema.min(3, 'Se requiere al menos 3 caracteres');
    })
    .required('Ingrese codigo de comprobante'),
  status: Yup.string().ensure().trim(),
  alter_receiver_name: Yup.string().ensure().trim(),
  alter_receiver_dni: Yup.string().ensure().trim(),
  paidout_date: Yup.string().ensure().trim(),
  voucher_type: Yup.number().positive().nullable().required('Seleccione comprobante'),
  payment_method: Yup.number().positive().nullable().required('Seleccione método'),
  service_orders: Yup.array().required('Debe agregar al menos una orden de servicio'),
  cost: Yup.string()
    .ensure()
    .trim()
    .matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, 'Costo incorrecto')
    .required('Costo requerido'),
  currency: Yup.string().ensure().trim().required('Seleccionar moneda'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `1px solid ${grayColor[5]}`,
    '& td': {
      backgroundColor: '#e8f5e9',
    },
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
  actionButton: {
    backgroundColor: '#EEE',
    margin: '10px 15px 10px 0',
  },
}));

const getsymbol = currency => {
  switch (currency) {
    case 'DOLARES':
      return '$';
    case 'EUROS':
      return '€';
    case 'SOLES':
    default:
      return 'S/';
  }
};

const Cost = props => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!props.costEdited) {
      setFieldValue(
        'cost',
        values.service_orders.reduce((a, i) => a + parseFloat(i.total_cost), 0),
      );
    }
  }, [values.service_orders]);

  return (
    <>
      <Badge badgeContent="E" color="primary" invisible={!props.costEdited}>
        <Typography>Total</Typography>
      </Badge>
      <div style={{ fontSize: '1.6em' }}>{`${getsymbol(values.currency)} ${parseFloat(values.cost).toFixed(2)}`}</div>
      <small style={{ margin: '-6px 0 0 0', display: 'block', color: values.igv ? '#FF0000' : '#000' }}>
        {values.igv ? 'INCLUYE IGV' : 'SIN IGV'}
      </small>
    </>
  );
};
export default function Add(props) {
  const classes = useStyles();

  const [serviceOrderCode, setServiceOrderCode] = useState('');
  const [showComments] = useState(false);

  const [showCostEdit, setShowCostEdit] = useState(false);
  const [supplierName, setSupplierName] = useState('');
  const [showOrders, setShowOrders] = useState(true);

  const onAdd = (values, setFieldValue, arrayHelpers) => {
    const idData = serviceOrderCode.trim();

    if (values.service_orders.find(i => i.code === idData)) {
      Toast.fire({
        icon: 'warning',
        title: 'Ya existe la orden de servicio.',
      });
    } else {
      API.ServiceOrder.action('search2pay', {
        order: idData,
      })
        .then(({ data }) => {
          if (data?.supplier_name) {
            setSupplierName(data.supplier_name);
          }

          // * Validaciones
          // ! Falta que no se pueda agregar los que ya están cerradas
          if (data.entered_group !== null) {
            Toast.fire({
              icon: 'error',
              title: 'La orden de servicio ya fue procesada.',
            });
            // * La primera vez cargamos supplier, moneda, igv
          } else if (!values.service_orders.length) {
            setFieldValue('supplier', data.supplier);
            setFieldValue('currency', data.currency);
            setFieldValue('igv', data.igv);
            // setFieldValue('cost', parseFloat(data.total_cost));

            arrayHelpers.push(data);
            setServiceOrderCode('');
          } else if (data.supplier !== values.supplier) {
            Toast.fire({
              icon: 'warning',
              title:
                `Las órdenes ${values.service_orders[0].code} y ${data.code}` +
                '<br /> deben tener el mismo proveedor.',
            });
          } else if (data.currency !== values.currency) {
            Toast.fire({
              icon: 'warning',
              title: `Las órdenes ${values.service_orders[0].code} y ${data.code} <br /> deben tener la misma moneda.`,
            });
          } else if (data.igv !== values.igv) {
            if (data.igv) {
              Toast.fire({
                icon: 'warning',
                title: `Todas las ordenes (${values.service_orders[0].code} y ${data.code}) deben incluir IGV.`,
              });
            } else {
              Toast.fire({
                icon: 'warning',
                title: `Ninguna órden (${values.service_orders[0].code} ó ${data.code}) debe incluir IGV.`,
              });
            }
          } else {
            arrayHelpers.push(data);
            setServiceOrderCode('');
          }
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'No existe la orden de servicio.',
          });
        });
    }
  };

  const ability = useContext(AbilityContext);

  return (
    <YupProvider value={validationSchema}>
      <Grid container justifyContent="center">
        <Grid item xs={12} xl={8}>
          <Formik
            initialValues={{
              comment: '',
              voucher_code: '',
              status: 'PENDING',
              supplier: null,
              alter_receiver_name: '',
              alter_receiver_dni: '',
              paidout_date: null,
              voucher_type: null,
              payment_method: null,
              service_orders: [],
              cost: 0.0,
              igv: false,
              currency: '',
              cost_edited: false,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              API.ServiceOrderPayment.create({
                comment: values.comment,
                voucher_code: values.voucher_code,
                status: values.status,
                supplier: values.supplier,
                alter_receiver_name: values.alter_receiver_name,
                alter_receiver_dni: values.alter_receiver_dni,
                voucher_type: values.voucher_type?.id || values.voucher_type,
                payment_method: values.payment_method?.id || values.payment_method,
                entered_group: {
                  service_orders: values.service_orders.map(i => i.id),
                  status_group: 'COMPLETE',
                  payment_mode: 'ABSOLUTE',
                },
                cost: values.cost,
                igv: values.igv,
                currency: values.currency,
                cost_edited: values.cost_edited,
              }).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Pago Ingresado',
                  text: 'El pago pasará a espera de liquidación',
                });
                props.onSave();
              });
            }}
          >
            {({ values, isSubmitting, submitForm, setFieldValue }) => (
              <Form autoComplete="off">
                {!!values.service_orders?.length && (
                  <>
                    <GridContainer>
                      <Grid item md={6} className={classes.gridItem}>
                        <Typography>Ordenar pago a :</Typography>
                        <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>{supplierName}</div>
                      </Grid>
                      {showCostEdit ? (
                        <>
                          <Grid item md={6}>
                            <Grid container>
                              <Grid item md={3} className={classes.gridItem} style={{ backgroundColor: 'lightyellow' }}>
                                <FormInput label="Costo" name="cost" style={{ backgroundColor: '#FFF' }} />
                              </Grid>
                              <Grid item md={4} className={classes.gridItem} style={{ backgroundColor: 'lightyellow' }}>
                                <CurrencySelect label="Moneda" name="currency" />
                              </Grid>
                              <Grid
                                item
                                md={3}
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: 'lightyellow',
                                  margin: '0 0 20px',
                                }}
                              >
                                <IGVSwitch label="¿Incluye IGV?" name="igv" />
                              </Grid>
                              <Grid item md={2} className={classes.gridItem} style={{ backgroundColor: 'lightyellow' }}>
                                <Tooltip title="Aceptar" placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <IconButton
                                    aria-label="Aceptar"
                                    className={classes.actionButton}
                                    color="primary"
                                    onClick={() => setShowCostEdit(false)}
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={2} className={classes.gridItem} style={{ textAlign: 'left' }}>
                            <Cost costEdited={values.cost_edited} />
                          </Grid>
                          <Grid item md={3} className={classes.gridItem}>
                            <Tooltip title="Editar Manualmente" placement="top" classes={{ tooltip: classes.tooltip }}>
                              <IconButton
                                aria-label="Editar"
                                className={classes.actionButton}
                                color="primary"
                                onClick={() => {
                                  setFieldValue('cost_edited', true);
                                  // setEdited(true);
                                  setShowCostEdit(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </>
                      )}
                    </GridContainer>
                    <GridContainer>
                      <Grid item md={3} className={classes.gridItem}>
                        <PaymentVoucherSelect name="voucher_type" label="Tipo de Comprobante" />
                      </Grid>
                      <Grid item md={3} className={classes.gridItem}>
                        <FormInput label="Código comprobante" name="voucher_code" placeholder="Código" />
                      </Grid>

                      <Grid item md={3} className={classes.gridItem}>
                        <PaymentMethodSelect label="Método de Pago" name="payment_method" isClearable />
                      </Grid>

                      <Grid item md={3} className={classes.gridItem}>
                        <Typography>Fecha de Ingreso :</Typography>
                        <div style={{ fontSize: '1.9em' }}>{date2format(new Date())}</div>
                      </Grid>
                    </GridContainer>
                    {showComments && (
                      <>
                        <Grid container style={{ margin: '10px -5px' }}>
                          <Grid item md={12}>
                            <Typography color="primary" display="block" variant="body1">
                              AGREGAR COMENTARIOS
                            </Typography>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid container style={{ margin: '10px 10px 30px 0' }}>
                          <Grid item md={12}>
                            <FormInput name="comment" multiline />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid container style={{ margin: '10px -5px' }}>
                      <Grid item md={12}>
                        <Typography color="primary" display="block" variant="body1">
                          ORDENES DE SERVICIO
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                  </>
                )}
                <FieldArray
                  name="service_orders"
                  render={arrayHelpers => (
                    <>
                      <Grid container>
                        <Grid item md={2} xs={12} style={{ textAlign: 'left' }}>
                          <Typography style={{ lineHeight: '2.5' }}>INGRESE ORDEN :</Typography>
                        </Grid>
                        <Grid item xs={7} md={3}>
                          <SearchText
                            value={serviceOrderCode}
                            onChange={({ target: { value } }) => setServiceOrderCode(value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            inputProps={{
                              autoComplete: 'off',
                            }}
                            type="search"
                            placeholder="Ej ORD-21-000001"
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                onAdd(values, setFieldValue, arrayHelpers);
                                e.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item md={3} style={{ textAlign: 'left' }}>
                          <Fab
                            size="small"
                            color="secondary"
                            variant="extended"
                            aria-label="add"
                            className={classes.margin}
                            onClick={() => onAdd(values, setFieldValue, arrayHelpers)}
                            disabled={isSubmitting}
                          >
                            <SearchIcon />
                            Agregar
                          </Fab>
                        </Grid>

                        {!!values.service_orders?.length && (
                          <Grid item md={4} style={{ textAlign: 'right', padding: '0 10px' }} />
                        )}
                      </Grid>
                      <ErrorMessage name="service_orders">
                        {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <br />
                      <Table className={classes.table}>
                        <TableBody>
                          {values.service_orders.map((item, i) => (
                            <TableRow key={`task${String(item.id)}`} className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>
                                {ability.can('manage', 'operations') ? (
                                  <Link to={`${goto(MODS.SERVICE_ORDERS)}/${item.id}`}>{item.code}</Link>
                                ) : (
                                  <span>{item.code}</span>
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>{item.service_description}</TableCell>
                              <TableCell className={classes.tableCell}>{str2format(item.date_in)}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {`${item.total_cost} ${item.currency}`}
                              </TableCell>

                              <TableCell className={classes.tableActions}>
                                <Tooltip
                                  id="tooltip-top-start"
                                  title="Eliminar"
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="Close"
                                    className={classes.tableActionButton}
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                    }}
                                  >
                                    <Close className={`${classes.tableActionButtonIcon} ${classes.close}`} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  )}
                />

                {isSubmitting && <LinearProgress />}
                <Grid
                  container
                  // justify="center"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <GridItem md={3}>
                    {!!values.service_orders?.length && (
                      <Button
                        color="primary"
                        // onClick={() => handleSubmit()}
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Ingresar Pago
                      </Button>
                    )}
                  </GridItem>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => {
                  setShowOrders(val => !val);
                }}
              >
                <Typography color="primary" display="block" variant="body1">
                  MOSTRAR ORDENES DE SERVICIO SIN PROCESAR
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {showOrders && <Orders />}
        </Grid>
      </Grid>
    </YupProvider>
  );
}
