import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function IGVSwitch({ label, name }) {
  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const [defaultValue] = useState(value);
  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 200);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={defaultValue}
            onChange={({ target: { checked } }) => debouncedHandleOnChange(checked)}
            name={name}
            color="primary"
            disabled={isSubmitting}
          />
        }
        label={label}
      />
    </>
  );
}
