import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function Total(props) {
  const { serviceRequests } = props;

  const orders = {};
  serviceRequests
    .filter(s => s?.service_order)
    .forEach(s => {
      orders[s.service_order] = s.quantity;
    });

  const services = {};
  serviceRequests
    .filter(s => !s?.service_order)
    .forEach(s => {
      services[s.service?.id || s.service] = s.quantity;
    });

  const [ordersData] = useFetchData(() => API.ServiceOrder.all({ id__in: Object.keys(orders).join(',') || 0 }));
  const [servicesData] = useFetchData(() => API.Service.all({ id__in: Object.keys(services).join(',') || 0 }));

  const [totalSoles, setTotalSoles] = React.useState(0);
  const [totalDolares, setTotalDolares] = React.useState(0);
  const [totalEuros, setTotalEuros] = React.useState(0);

  const [totalOrderSoles, setTotalOrderSoles] = React.useState(0);
  const [totalOrderDolares, setTotalOrderDolares] = React.useState(0);
  const [totalOrderEuros, setTotalOrderEuros] = React.useState(0);

  const [totalServiceSoles, setTotalServiceSoles] = React.useState(0);
  const [totalServiceDolares, setTotalServiceDolares] = React.useState(0);
  const [totalServiceEuros, setTotalServiceEuros] = React.useState(0);

  useEffect(() => {
    setTotalOrderSoles(
      ordersData?.result
        ?.filter(c => c.currency === 'SOLES')
        .reduce((a, b) => a + parseInt(orders[b.id], 10) * parseFloat(b.total_cost), 0),
    );
    setTotalOrderDolares(
      ordersData?.result
        ?.filter(c => c.currency === 'DOLARES')
        .reduce((a, b) => a + parseInt(orders[b.id], 10) * parseFloat(b.total_cost), 0),
    );
    setTotalOrderEuros(
      ordersData?.result
        ?.filter(c => c.currency === 'EUROS')
        .reduce((a, b) => a + parseInt(orders[b.id], 10) * parseFloat(b.total_cost), 0),
    );
  }, [ordersData]);

  useEffect(() => {
    setTotalServiceSoles(
      servicesData?.result
        ?.filter(c => c.currency === 'SOLES')
        .reduce((a, b) => a + parseInt(services[b.id], 10) * parseFloat(b.cost), 0),
    );
    setTotalServiceDolares(
      servicesData?.result
        ?.filter(c => c.currency === 'DOLARES')
        .reduce((a, b) => a + parseInt(services[b.id], 10) * parseFloat(b.cost), 0),
    );
    setTotalServiceEuros(
      servicesData?.result
        ?.filter(c => c.currency === 'EUROS')
        .reduce((a, b) => a + parseInt(services[b.id], 10) * parseFloat(b.cost), 0),
    );
  }, [servicesData]);

  useEffect(() => {
    setTotalSoles(totalOrderSoles + totalServiceSoles);
    setTotalDolares(totalOrderDolares + totalServiceDolares);
    setTotalEuros(totalOrderEuros + totalServiceEuros);
  }, [totalOrderSoles, totalOrderDolares, totalOrderEuros, totalServiceSoles, totalServiceDolares, totalServiceEuros]);

  return (
    <>
      <Box paddingY={1} fontWeight={400}>
        <table>
          <tr>
            <th>
              <Box fontWeight={400} p={1} />
            </th>
            <th>
              <Box p={1}>Total Costos</Box>
            </th>
            <th>
              <Box fontWeight={400} p={1}>
                Ordenes Realizadas
              </Box>
            </th>
            <th>
              <Box fontWeight={400} p={1}>
                Ordenes Pendientes
              </Box>
            </th>
          </tr>
          {totalSoles > 0 && (
            <tr>
              <td>
                <Box p={1}>SOLES</Box>
              </td>
              <td align="right">
                <Box fontWeight={600} p={1}>
                  S/ {totalSoles?.toFixed(2)}
                </Box>
              </td>
              <td align="right">
                <Box p={1}>S/ {totalOrderSoles?.toFixed(2)}</Box>
              </td>
              <td align="right">
                <Box p={1}>S/ {totalServiceSoles?.toFixed(2)}</Box>
              </td>
            </tr>
          )}
          {totalDolares > 0 && (
            <tr>
              <td>
                <Box p={1}>DOLARES</Box>
              </td>
              <td align="right">
                <Box fontWeight={600} p={1}>
                  $ {totalDolares?.toFixed(2)}
                </Box>
              </td>
              <td align="right">
                <Box p={1}>$ {totalOrderDolares?.toFixed(2)}</Box>
              </td>
              <td align="right">
                <Box p={1}>$ {totalServiceDolares?.toFixed(2)}</Box>
              </td>
            </tr>
          )}
          {totalEuros > 0 && (
            <tr>
              <td>
                <Box p={1}>EUROS</Box>
              </td>
              <td align="right">
                <Box fontWeight={600} p={1}>
                  € {totalEuros?.toFixed(2)}
                </Box>
              </td>
              <td align="right">
                <Box p={1}>€ {totalOrderEuros?.toFixed(2)}</Box>
              </td>
              <td align="right">
                <Box p={1}>€ {totalServiceEuros?.toFixed(2)}</Box>
              </td>
            </tr>
          )}
          {!totalSoles && !totalDolares && !totalEuros && (
            <tr>
              <td colSpan={4}>
                <Box p={1}>Aún no se han registrado costos</Box>
              </td>
            </tr>
          )}
        </table>
      </Box>
    </>
  );
}
