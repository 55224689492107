import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import { localization } from 'config';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { commonCols } from 'utils/site';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  tableToolbar: {
    border: '1px solid #EEE',
    backgroundColor: '#EEEEEE',
    '& .Mui-disabled': {
      color: 'transparent',
    },
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },

  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `1px solid ${grayColor[5]}`,
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
}));
const tableRef = React.createRef();

export default function AllSuppliers() {
  const classes = useStyles();

  const history = useHistory();

  const ability = useContext(AbilityContext);

  const handleSearch = (query, getData) =>
    getData({
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <div>
      <MaterialTable
        title=""
        columns={[
          // { title: 'Código', field: 'code' },
          {
            title: 'Nombre',
            field: 'name',
          },
          {
            title: 'Tipo',
            field: 'supplier_type_name',
            sorting: false,
          },
          {
            title: 'Dirección',
            field: 'address',
            emptyValue: '---',
            sorting: false,
            hidden: true,
            hiddenByColumnsButton: true,
          },
          {
            title: 'Teléfono',
            field: 'phone',
            emptyValue: '---',
            sorting: false,
          },
          {
            title: 'Email',
            field: 'email',
            emptyValue: '---',
            sorting: false,
          },
          ...commonCols(false),
        ]}
        tableRef={tableRef}
        data={query => handleSearch(query, API.Supplier.all)}
        options={{
          headerStyle: {
            // backgroundColor: '#01579b',
            color: '#FFF',
          },
          draggable: false,
          // loadingType: 'linear',
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          // actionsColumnIndex: -1,
          columnsButton: true,
          exportButton: true,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        components={{
          OverlayLoading: () => <></>,
          // Container: props => props.children,
          Toolbar: props => (
            <div className={classes.table}>
              <table style={{ width: '100%', padding: '10px' }}>
                <tbody>
                  <tr>
                    <td>
                      <MTableToolbar {...props} />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Link to={goto(MODS.SUPPLIERS, 'add')}>
                        <Button color="secondary" variant="contained">
                          Agregar Proveedor
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        }}
        localization={localization}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (event, rowData) => history.push(`${goto(MODS.SUPPLIERS)}/${rowData.id}`),
          },
          ability.can('delete', 'suppliers')
            ? {
                icon: 'cancel_presentation_outlined',
                tooltip: 'Eliminar Proveedor',
                onClick: (e, row) => {
                  Swal.fire({
                    title: `¿Eliminar ${row.code || 'el registro'}?`,
                    text: 'Se eliminará completamente.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '¡Eliminar!',
                    cancelButtonText: 'Mantener.',
                  }).then(result => {
                    if (result.value) {
                      API.Supplier.remove(row.id).then(() => {
                        Toast.fire({
                          icon: 'info',
                          title: 'El registro fue eliminado.',
                        });
                        tableRef.current.onQueryChange();
                      });
                    }
                  });
                },
              }
            : null,
        ]}
        pageSize={20}
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    </div>
  );
}
