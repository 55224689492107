import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const DatePickerStyled = styled.div`
  /* STYLE PARA ANCHO */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
`;

export default function Add(props) {
  const [startDate, setStartDate] = useState(props.init || null);
  const years = Array(11)
    .fill(2010)
    .map((x, i) => x + i);
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  useEffect(() => {
    if (startDate) {
      // setEndDate(null);
      props.onSelect(format(startDate, 'yyyy-MM-dd'));
    }
    if (!startDate) {
      props.onSelect(null);
    }
  }, [startDate]);

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>{props.label}</td>
            <td>
              <DatePickerStyled>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 100,
                      }}
                    >
                      <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {'<'}
                      </button>
                      <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                      >
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {'>'}
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  // selectsStart
                  startDate={startDate}
                  // endDate={endDate}
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                  // customInput={(
                  //   <CustomButton />
                  //         )}
                  customInput={
                    <TextField
                      // fullWidth
                      // variant="outlined"
                      size="small"
                      style={{ width: '120px' }}
                    />
                  }
                  isClearable
                  onSelect={date => {
                    setStartDate(date);
                  }}
                />
              </DatePickerStyled>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
