import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckLabel from 'components/CheckLabel';
import CountryCityComp from 'components/CountryCityComp';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import LanguageSelect from 'components/LanguageSelect';
import PeoplePicker from 'components/PeoplePicker';
import PhoneInput from 'components/PhoneInput';
import RichInput from 'components/RichInput';
import SupplierTypeSelect from 'components/SupplierTypeSelect';
import Viewer from 'components/Viewer';
import { PERSON_TYPE } from 'config';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Frame from 'layouts/Frame';
import React, { useContext } from 'react';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese un nombre'),
  address: Yup.string().ensure().trim(),
  phone: Yup.string().ensure().trim().min(8, 'Se requiere Teléfono').required('Teléfono requerido'),
  email: Yup.string().email('Ingrese un email correcto').required('Email requerido'),
  comercial_name: Yup.string().ensure().trim(),
  dni: Yup.string().dni('Debe ser Numerico de 8 digitos'),
  ruc: Yup.string().ruc('Debe ser Numerico de 11 digitos'),
  bnk_account: Yup.string().ensure().trim(),
  observations: Yup.string().ensure().trim(),
  policies: Yup.string().ensure().trim(),
  description: Yup.string().ensure().trim(),
  web: Yup.string().ensure().trim(),
  supplier_type: Yup.number().nullable().pk().required('Seleccione el tipo de proveedor'),
  languages: Yup.array().nullable().required('Seleccione un idioma'),
  country: Yup.number().nullable().pk().required('Seleccione país'),
  region: Yup.number().nullable().pk().required('Seleccione región'),
  city: Yup.number().nullable().pk().required('Seleccione ciudad'),
  contacts: Yup.array().required('Debe agregar al menos una persona'),
});

const API_CALL = API.Supplier;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  data,
  create,
  children: render,
  values,
}) => {
  const ability = useContext(AbilityContext);
  return (
    <Form autoComplete="off" autoCorrect="off">
      {render({
        body: (
          <YupProvider value={validationSchema}>
            <Frame>
              <Box display="flex">
                <Box marginY={1} marginX={2} width={5 / 12}>
                  <FormInput label="Nombre de proveedor" name="name" />
                </Box>
                <Box marginY={1} marginX={2} width={4 / 12}>
                  <FormInput label="Nombre Comercial" name="comercial_name" />
                </Box>
                <Box marginY={1} marginX={2} width={3 / 12}>
                  <PhoneInput label="Teléfono" size="small" name="phone" />
                </Box>
              </Box>
              <Box marginY={1} marginX={2}>
                <CountryCityComp countryName="country" regionName="region" cityName="city" />
              </Box>
              <Box display="flex">
                <Box marginY={1} marginX={2} width={5 / 12}>
                  <FormInput label="Dirección" name="address" />
                </Box>
                <Box marginY={1} marginX={2} width={4 / 12}>
                  <FormInput label="Email" name="email" />
                </Box>
                <Box marginY={1} marginX={2} width={3 / 12}>
                  <FormInput label="Sitio Web" name="web" />
                </Box>
              </Box>
              <Box display="flex">
                <Box marginY={1} marginX={2} width={5 / 12}>
                  {create ? (
                    <SupplierTypeSelect label="Tipo de proveedor" name="supplier_type" />
                  ) : (
                    <Viewer name="SupplierType" value={values.supplier_type}>
                      {supplierType => (
                        <Box display="flex" flexDirection="column" pt={2}>
                          <small>Tipo de proveedor</small>
                          <div>{supplierType.type_name}</div>
                        </Box>
                      )}
                    </Viewer>
                  )}
                </Box>
                <Box marginY={1} marginX={2} width={7 / 12}>
                  <LanguageSelect label="Idiomas" isMulti name="languages" />
                </Box>
              </Box>
              <Box display="flex">
                <Box marginY={1} marginX={2} width={4 / 12}>
                  <FormInput label="RUC" name="ruc" />
                </Box>
                <Box marginY={1} marginX={2} width={4 / 12}>
                  <FormInput label="DNI" name="dni" />
                </Box>
                <Box marginY={1} marginX={2} width={4 / 12}>
                  <FormInput label="Número de cuenta" name="bnk_account" />
                </Box>
              </Box>
              <Box marginY={1} marginX={2}>
                <CheckLabel
                  label="No incluir este proveedor en las futuras ordenes de servicio"
                  name="orders_exclude"
                />
              </Box>
            </Frame>
            <Frame>
              <PeoplePicker
                label="Contactos"
                name="contacts"
                type={PERSON_TYPE.CONTACT}
                placeholder="Buscar contactos de proveedor... "
              />
              <Box display="flex">
                <Box marginY={1} marginX={2} width={6 / 12}>
                  <FormInput label="Observaciones" name="observations" multiline rows="4" />
                </Box>
                <Box marginY={1} marginX={2} width={6 / 12}>
                  <FormInput label="Politicas de cancelación" name="policies" multiline rows="4" />
                </Box>
              </Box>
            </Frame>
            {ability.can('edit', 'app') && (
              <Frame title="Visualización en aplicación (App)">
                <Box display="flex" justifyContent="center">
                  <Box marginY={1} marginX={2} width={6 / 12}>
                    <RichInput label="Descripción de proveedor" name="description" />
                  </Box>
                </Box>
              </Frame>
            )}
            {/* {values.supplier_type && (
                        <>
                          <GridContainer>
                            <GridItem md={12}>
                              <Typography
                                color="primary"
                                display="block"
                                variant="body1">
                                INFORMACIÓN ADICIONAL
                              </Typography>
                              <Divider />
                              <br />
                            </GridItem>
                          </GridContainer>
                        </>
                      )}

                      <GridContainer>
                        {customMetas.map((meta, index) => (
                          <Grid
                            key={`meta${String(index)}`}
                            item
                            md={6}
                            className={classes.gridItem}>
                            <Typography>
                              {meta.name}
                            </Typography>
                            {meta.options ? (
                              <Select
                                value={meta.value || null}
                                onChange={sel => onChangeMeta(sel, index)}
                                options={meta.options}
                                placeholder="Seleccionar"
                              />
                            ) : (
                              <TextInput
                                value={meta.value || ''}
                                setValue={value => onChangeMeta(value, index)}
                                variant="outlined"
                                fullWidth
                                // size="small"
                                inputProps={{
                                  autoComplete: 'disable',
                                }}
                                multiline={meta.multiline}
                                rows={5}
                              />
                            )}
                          </Grid>
                        ))}
                      </GridContainer> */}

            {isSubmitting && <LinearProgress />}
            <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
          </YupProvider>
        ),
        title: create ? 'Agregar Proveedor' : `Editar - ${data.name}`,
        handleSubmit,
        isSubmitting,
      })}
    </Form>
  );
};

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    name: data.name || '',
    address: data.address || '',
    phone: data.phone || '',
    email: data.email || '',
    web: data.web || '',
    comercial_name: data.comercial_name || '',
    bnk_account: data.bnk_account || '',
    dni: data.dni || '',
    ruc: data.ruc || '',
    observations: data.observations || '',
    policies: data.policies || '',
    description: data.description || '',
    supplier_type: data.supplier_type || null,
    city: data.city || null,
    region: data.region || null,
    country: data.country || 175,
    languages: data.languages || [],
    contacts: data.contacts || [],
    orders_exclude: data.orders_exclude || false,
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      name: values.name,
      address: values.address,
      phone: values.phone,
      email: values.email,
      comercial_name: values.comercial_name,
      dni: values.dni,
      ruc: values.ruc,
      bnk_account: values.bnk_account,
      observations: values.observations,
      policies: values.policies,
      description: values.description,
      supplier_type: values.supplier_type,
      city: values.city,
      languages: values.languages,
      contacts: values.contacts,
      orders_exclude: values.orders_exclude,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default ({ id = null, create = true, ...res }) => {
  if (id) {
    return <UpdateForm id={id} {...res} />;
  }
  if (create) {
    return <CustomForm create {...res} />;
  }
  return <ErrorConexion code={404} />;
};
