import { Button, LinearProgress, TableHead } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import DatePicker from 'components/DatePicker2';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PaymentMethodSelect from 'components/PaymentMethodSelect';
import { FieldArray, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { date2fullstr, date2str, str2format, str2fullformat } from 'utils/helpers';
import { useEditPage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  comment: Yup.string().ensure().trim(),
  voucher_code: Yup.string().ensure().trim().required('Ingrese la boleta o factura'),
  status: Yup.string().ensure().trim(),
  alter_receiver_name: Yup.string().ensure().trim(),
  alter_receiver_dni: Yup.string().ensure().trim(),
  paidout_date: Yup.date().nullable().required('Seleccione fecha'),
  voucher_type: Yup.number().positive().nullable().required('Seleccione comprobante'),
  payment_method: Yup.number().positive().nullable().required('Seleccione método'),
  service_orders: Yup.array().required('Debe agregar al menos una orden de servicio'),
  cost: Yup.number().required('Costo requerido'),
  currency: Yup.string().ensure().trim().required('Moneda requerida'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `2px solid ${grayColor[5]}`,
    backgroundColor: '#dcefff',
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
  tableHeader: {
    color: '#FFF',
    // lineHeight: '1.5rem',
    padding: '0 8px',
    background: 'dodgerblue',
    fontSize: '0.7rem',
  },
}));

export default function Add(props) {
  const classes = useStyles();

  const [fetchData, id, info] = useEditPage(id => API.ServiceOrderPayment.get(id));

  const [showAlternativeReceiver, setShowAlternativeReceiver] = useState(false);

  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (fetchData && (fetchData.alter_receiver_dni || fetchData.alter_receiver_name)) {
      setShowAlternativeReceiver(true);
    }
  }, [fetchData]);

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  return (
    <YupProvider value={validationSchema}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} xl={8}>
          <Formik
            initialValues={{
              ...fetchData,
              paidout_date: null,
              service_orders: fetchData.entered_group.service_orders,
              voucher_type: fetchData.voucher_type?.id || fetchData.voucher_type, // * obligatorio en add
              payment_method: fetchData.payment_method?.id || fetchData.payment_method,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              const submit = {
                status: 'PAIDOUT',
                supplier: values.supplier?.id || values.supplier,
                paidout_date: values.paidout_date ? date2str(values.paidout_date) : null,
                voucher_type: values.voucher_type?.id || values.voucher_type,
                payment_method: values.payment_method?.id || values.payment_method,
                entered_group: {
                  id: values.entered_group.id,
                },
                comment: values.comment,
                cost: values.cost,
                currency: values.currency,
                igv: values.igv,
                reply: values.reply,
                voucher_code: values.voucher_code,
                alter_receiver_name: values.alter_receiver_name,
                alter_receiver_dni: values.alter_receiver_dni,
                closed_date: date2fullstr(new Date()),
              };
              API.ServiceOrderPayment.update(id, submit).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Pago Generado',
                  text: 'Se genero el pago.',
                });
                props.onSave();
              });
            }}
          >
            {({ values, isSubmitting, submitForm }) => (
              <Form autoComplete="off">
                <GridContainer>
                  <Grid item md={6} className={classes.gridItem}>
                    <Typography>Ordenar pago a :</Typography>
                    <div style={{ fontSize: '1.3rem', textAlign: 'center' }}>{values.supplier?.name}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Costo ({values.igv ? 'con IGV' : 'sin IGV'})</Typography>
                    <div style={{ fontSize: '1.5em' }}>
                      {`${parseFloat(values.cost).toFixed(2)} ${values.currency}`}
                    </div>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <PaymentMethodSelect label="Método de pago" name="payment_method" />
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>{fetchData.voucher_type.name}</Typography>
                    <div style={{ fontSize: '1.5em' }}>{values.voucher_code}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Ingreso</Typography>
                    <div style={{ fontSize: '1.5em' }}>{str2fullformat(values.created)}</div>
                  </Grid>
                  <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Aprobación</Typography>
                    <div style={{ fontSize: '1.5em' }}>{str2fullformat(values.approved_date)}</div>
                  </Grid>

                  <Grid item md={3} className={classes.gridItem}>
                    <DatePicker
                      label="Fecha de Pago"
                      placeholderText="Seleccione"
                      name="paidout_date"
                      // todayButton="Hoy"
                      popperPlacement="bottom-end"
                      minDate={new Date(values.created)}
                    />
                  </Grid>
                  {/* <Grid item md={3} className={classes.gridItem}>
                    <Typography>Fecha de Pago</Typography>
                    <div style={{ fontSize: '1.5em' }}>
                      {values.paidout_date ? date2format(values.paidout_date) : 'Pendiente'}
                    </div>
                  </Grid> */}
                </GridContainer>

                {!showAlternativeReceiver ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAlternativeReceiver}
                        onChange={({ target: { checked } }) => setShowAlternativeReceiver(checked)}
                        name="alter_receiver"
                        // color="primary"
                      />
                    }
                    label="RECEPTOR DE PAGO ALTERNATIVO"
                    style={{
                      position: 'relative',
                      top: '-10px',
                      // margin: '-15px -15px'
                    }}
                  />
                ) : (
                  <>
                    <Grid container style={{ margin: '10px -5px' }}>
                      <Grid item md={12}>
                        {/* <Typography
                          color="primary"
                          display="block"
                          variant="body1">
                          <Checkbox
                            checked={showAlternativeReceiver}
                            onChange={({ target: { checked } }) => setShowAlternativeReceiver(checked)}
                            style={{ margin: '-8px -4px' }}
                          />
                          RECEPTOR DE PAGO ALTERNATIVO
                        </Typography> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showAlternativeReceiver}
                              onChange={({ target: { checked } }) => setShowAlternativeReceiver(checked)}
                              name="alter_receiver"
                              // color="primary"
                              style={{ margin: '-8px -4px' }}
                            />
                          }
                          label={
                            <Typography color="primary" display="block" variant="body1">
                              RECEPTOR DE PAGO ALTERNATIVO
                            </Typography>
                          }
                          style={{
                            position: 'relative',
                            top: '-5px',
                            margin: '-15px -5px',
                          }}
                        />
                        <Divider />
                      </Grid>
                    </Grid>
                    <GridContainer>
                      <GridItem md={8}>
                        <FormInput placeholder="INGRESE NOMBRE DE RECEPTOR" name="alter_receiver_name" />
                      </GridItem>
                      <GridItem md={4}>
                        <FormInput placeholder="INGRESE DNI DE RECEPTOR" name="alter_receiver_dni" />
                      </GridItem>
                    </GridContainer>
                  </>
                )}

                <Grid container style={{ margin: '10px -5px' }}>
                  <Grid item md={12}>
                    <Typography color="primary" display="block" variant="body1">
                      ORDENES DE SERVICIO
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>

                <FieldArray
                  name="service_orders"
                  render={() => (
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeader}>CÓDIGO</TableCell>
                          <TableCell className={classes.tableHeader}>SERVICIO DE PROVEEDOR</TableCell>
                          <TableCell className={classes.tableHeader}>FECHA DE SERVICIO</TableCell>
                          <TableCell className={classes.tableHeader}>COSTO</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.service_orders.map(item => (
                          <TableRow key={`task${String(item.id)}`} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {ability.can('manage', 'operations') ? (
                                <Link to={`${goto(MODS.SERVICE_ORDERS)}/${item.id}`}>{item.code}</Link>
                              ) : (
                                <span>{item.code}</span>
                              )}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{item.service_description}</TableCell>
                            <TableCell className={classes.tableCell}>{str2format(item.date_in)}</TableCell>
                            <TableCell className={classes.tableCell}>{`${item.total_cost} ${item.currency}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                />

                {!!values.reply && (
                  <>
                    <Grid container style={{ margin: '15px -5px' }}>
                      <Grid item md={12}>
                        <Typography color="primary" display="block" variant="body1">
                          COMENTARIOS
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>

                    <Grid container style={{ margin: '10px 10px 30px 0' }}>
                      {!!values.comment && (
                        <GridItem md={12}>
                          <Typography>Desde ingreso :</Typography>
                          <small>{values.comment}</small>
                        </GridItem>
                      )}
                      {!!values.reply && (
                        <GridItem md={12}>
                          <Typography>Respuesta :</Typography>
                          <small>{values.reply}</small>
                        </GridItem>
                      )}
                    </Grid>
                  </>
                )}

                {isSubmitting && <LinearProgress />}
                <Grid
                  container
                  // justify="center"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <GridItem md={3}>
                    {!!values.service_orders?.length && (
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{
                          backgroundColor: '#4CAF50',
                          color: '#FFFFFF',
                        }}
                      >
                        Generar Pago
                      </Button>
                    )}
                  </GridItem>
                </Grid>
              </Form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
    </YupProvider>
  );
}
