import Typography from '@material-ui/core/Typography';
// import { format } from 'date-fns';
// import { ErrorMessage } from 'formik';
// import { useField, useFormikContext } from 'formik';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import ErrorMessage from 'components/ErrorMessage';

// const getOptions = arr => arr.map(i => ({ value: i.id, label: `${i.first_name} ${i.last_name}` }));

export default function SupplierContactSelect({ label, name }) {
  const [contactsData, setQueryContactData] = useFetchData(
    (query = {}) => API.Contact.action('servicerequests', query),
    [],
  );

  const [, { value }, { setValue }] = useField(name);
  const { values, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (values.service || values.supplier || values.date_in || values.currency) {
      const query = {};
      if (values.service) query.service = values.service;
      if (values.supplier) query.supplier = values.supplier;
      if (values.date_in) query.date_in = values.date_in;
      if (values.currency) query.currency = values.currency;
      setQueryContactData(query);
    } else {
      setQueryContactData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service, values.supplier, values.date_in, values.currency]);

  return (
    <>
      <Typography>{label}</Typography>
      <Select
        value={contactsData?.find(i => i.id === value) || ''}
        onChange={sel => setValue(sel?.id || null)}
        options={contactsData || []}
        getOptionLabel={i => `${i.first_name} ${i.last_name}`}
        getOptionValue={i => i.id}
        placeholder="Seleccione la persona de contacto"
        isDisabled={isSubmitting}
        name={name}
        isClearable
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
      <div name={name} />
      <ErrorMessage name={name} />
    </>
  );
}
