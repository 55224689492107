import { Box, Button, Grid, Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TableButtonDate from 'components/TableButtonDate';
import TableOriginSelect from 'components/TableOriginSelect';
import TableSelectDate from 'components/TableSelectDate';
import { localization } from 'config';
import { format } from 'date-fns';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { goto, MODS, PASSENGER_ROUTE } from 'utils/structure';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  newtoolbar: {
    // marginLeft: '-25px',
  },
  table: {
    border: '1px solid #EEE',
    backgroundColor: '#EEEEEE',
    '& .Mui-disabled': {
      color: 'transparent',
    },
  },
  formControl: {
    margin: '-17px 20px 0',
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 0,
    marginTop: 0,
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: 'rgb(98 189 25 / 42%)',
    borderRadius: '50%',
    margin: '0 0 0 10px',
  },
  gridToolbar: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
}));

const tableRef = React.createRef();

export default function Reservation() {
  // * Styles
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (Object.keys(filters).length) {
      tableRef.current.onQueryChange({ search: '' }, () => {
        tableRef.current.setState({ searchText: '' });
      });
    }
  }, [filters]);

  const handleSearch = (query, getData, filters) =>
    getData({
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '+' : ''}` +
        `${query.orderBy === undefined ? '-created' : query.orderBy.field}`,
      ...filters,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid style={{ margin: '10px auto' }} container justifyContent="center" direction="row">
        <Grid item md={4} lg={4} xs={12}>
          <TableSelectDate
            onSelect={(st, ed) =>
              setFilters(prev => ({
                ...prev,
                created__gte: st,
                created__lte: ed,
              }))
            }
          />
        </Grid>
        <Grid item md={6} lg={2} xs={12}>
          <TableButtonDate
            label="Llegada"
            onSelect={arrivalDate =>
              setFilters(prev => ({
                ...prev,
                arrival_date: arrivalDate,
              }))
            }
          />
        </Grid>
        <Grid item md={6} lg={2} xs={12}>
          <TableButtonDate
            label="Salida"
            onSelect={departureDate =>
              setFilters(prev => ({
                ...prev,
                departure_date: departureDate,
              }))
            }
          />
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <TableOriginSelect
            label="Agencia o Procedencia"
            onSelect={origin =>
              setFilters(prev => ({
                ...prev,
                origin,
              }))
            }
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" direction="column">
        <Grid item xs={12}>
          <MaterialTable
            color="primary"
            title=""
            columns={[
              {
                title: 'Codigo',
                field: 'code',
                render: ({ code, approve }) =>
                  approve !== 'CANCELED' ? (
                    <Box>{code}</Box>
                  ) : (
                    <Box style={{ textDecoration: 'line-through', color: 'tomato' }}>{code}</Box>
                  ),
              },
              {
                title: 'Ordenes',
                field: 'indicator_orders',
                render: i =>
                  i.indicator_orders ? (
                    <div className={classes.circle} />
                  ) : (
                    <div className={classes.circle} style={{ backgroundColor: 'tomato' }} />
                  ),
                sorting: false,
                export: false,
              },
              {
                title: 'Pax Principal',
                field: 'client',
                render: i => (
                  <>
                    <span>{`${i.client_data.first_name} ${i.client_data.last_name}`}</span>
                    <IconButton href={`${goto(MODS.PASSENGERS)}${PASSENGER_ROUTE.CLIENT}/${i.client}`} size="small">
                      <EditOutlinedIcon style={{ fontSize: '0.72em' }} />
                    </IconButton>
                  </>
                ),
                sorting: false,
                export: false,
              },
              {
                title: 'Nombres Pax',
                field: 'client_data.first_name',
                hidden: true,
                export: true,
              },
              {
                title: 'Apellidos Pax',
                field: 'client_data.last_name',
                hidden: true,
                export: true,
              },
              {
                title: 'Documentos',
                field: 'documents',
                render: ({ client_data: clientData }) => (
                  <Box>
                    {clientData?.dni && (
                      <div>
                        <small>DNI</small> {clientData.dni}
                      </div>
                    )}
                    {clientData?.ruc && (
                      <div>
                        <small>RUC</small> {clientData.ruc}
                      </div>
                    )}
                    {clientData?.passport && (
                      <div>
                        <small>Pasaporte</small> {clientData.passport}
                      </div>
                    )}
                    {clientData?.carnet && (
                      <div>
                        <small>Carnet</small> {clientData.carnet}
                      </div>
                    )}
                  </Box>
                ),
                sorting: false,
                hidden: true,
                hiddenByColumnsButton: true,
                export: false,
              },
              {
                title: 'DNI',
                field: 'client_data.dni',
                hidden: true,
                export: true,
              },
              {
                title: 'RUC',
                field: 'client_data.ruc',
                hidden: true,
                export: true,
              },
              {
                title: 'Pasaporte',
                field: 'client_data.passport',
                hidden: true,
                export: true,
              },
              {
                title: 'Carnet',
                field: 'client_data.carnet',
                hidden: true,
                export: true,
              },
              {
                title: 'Email',
                field: 'client_data.email',
                render: ({ client_data: clientData }) => clientData?.email,
                emptyValue: '---',
                sorting: false,
                hidden: true,
                hiddenByColumnsButton: true,
              },
              {
                title: 'Teléfono',
                field: 'client_data.phone',
                render: ({ client_data: clientData }) => clientData?.phone,
                emptyValue: '---',
                sorting: false,
              },
              {
                title: 'País',
                field: 'client_data.country_name',
                render: ({ client_data: clientData }) => clientData?.country_name,
                emptyValue: '---',
                sorting: false,
                hidden: true,
                hiddenByColumnsButton: true,
              },
              // { title: 'Label', field: 'count_label' },
              {
                title: 'Fecha de llegada',
                field: 'arrival_date',
                hidden: true,
                hiddenByColumnsButton: true,
                type: 'date',
              },
              {
                title: 'Fecha de salida',
                field: 'departure_date',
                hidden: true,
                hiddenByColumnsButton: true,
                type: 'date',
              },
              {
                title: 'Agencia/Procedencia',
                field: 'origin_name',
                hidden: true,
                hiddenByColumnsButton: true,
              },
              {
                title: 'Total Soles',
                field: 'total_soles_format',
                // hidden: true,
                // hiddenByColumnsButton: true,
                emptyValue: '---',
                sorting: false,
              },
              {
                title: 'Total Dolares',
                field: 'total_dolares_format',
                // hidden: true,
                // hiddenByColumnsButton: true,
                emptyValue: '---',
                sorting: false,
              },
              {
                title: 'Total Euros',
                field: 'total_euros_format',
                // hidden: true,
                // hiddenByColumnsButton: true,
                emptyValue: '---',
                sorting: false,
              },
              {
                title: 'Creado Por',
                field: 'created_by_data.username',
                render: ({ created_by_data: userData }) => (
                  <Box>
                    {userData?.first_name ? (
                      <div>{`${userData?.first_name} ${userData?.last_name}`}</div>
                    ) : (
                      <div>{userData?.username}</div>
                    )}
                    <small>{userData?.groups[0].name}</small>
                  </Box>
                ),
                emptyValue: '---',
                sorting: false,
              },
              {
                title: 'Fecha Creación',
                field: 'created',
                type: 'datetime',
                render: ({ created }) => format(new Date(created), 'dd/MM/yyyy (HH:mm)'),
              },
              {
                title: 'Editado Por',
                field: 'updated_by',
                render: ({ updated_by_data: userData, updated_by: updatedBy, created_by: createdBy }) => (
                  <Box bgcolor={updatedBy !== createdBy && '#ffeb3b'}>
                    {userData?.first_name ? (
                      <div>{`${userData?.first_name} ${userData?.last_name}`}</div>
                    ) : (
                      <div>{userData?.username}</div>
                    )}
                    <small>{userData?.groups[0].name}</small>
                  </Box>
                ),
                emptyValue: '---',
                sorting: false,
                hidden: true,
                hiddenByColumnsButton: true,
              },
              {
                title: 'Fecha Edición',
                field: 'updated',
                render: ({ updated }) => format(new Date(updated), 'dd/MM/yyyy (HH:mm)'),
                hidden: true,
                hiddenByColumnsButton: true,
              },
            ]}
            tableRef={tableRef}
            options={{
              loadingType: 'linear',
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100],
              paginationType: 'stepped',

              exportButton: true,
              actionsCellStyle: {
                width: '1%',
              },
              columnsButton: true,
              search: true,
              toolbarButtonAlignment: 'right',
              searchFieldAlignment: 'left',
              searchFieldStyle: { width: '115%' },
            }}
            components={{
              Container: props => props.children,
              Toolbar: props => (
                <Grid className={classes.gridToolbar} alignItems="center" container direction="row">
                  <Grid item xs={12} md={5}>
                    <MTableToolbar {...props} />
                  </Grid>
                  <Grid item xs={12} md={5} />
                  <Grid item xs={12} md={2}>
                    <Link to={goto(MODS.RESERVATIONS, 'add')}>
                      <Button color="secondary" variant="contained">
                        Nueva Reserva
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              ),
            }}
            // getData={query => API.Reservation.all({ ...query, ...filters })}
            data={query => handleSearch(query, API.Reservation.all, filters)}
            className={classes.table}
            localization={{ ...localization, toolbar: { searchPlaceholder: 'Nombre Pax o Código de Reserva' } }}
            actions={[
              row => ({
                icon: () => (
                  <Link to={`${goto(MODS.RESERVATIONS)}/${parseInt(row.id, 10)}`}>
                    <Icon>edit</Icon>
                  </Link>
                ),
                tooltip: 'Editar',
              }),

              row => ({
                icon: () => (
                  <Link to={`${goto(MODS.RESERVATIONS)}/${parseInt(row.id, 10)}/ver`}>
                    <Icon>visibility</Icon>
                  </Link>
                ),
                tooltip: 'Ver Reserva',
              }),

              ability.can('delete', 'reservation')
                ? {
                    icon: 'cancel_presentation_outlined',
                    tooltip: 'Eliminar Reserva',
                    onClick: (e, row) => {
                      Swal.fire({
                        title: `¿Eliminar ${row.code || 'el registro'}?`,
                        text: 'Se eliminará completamente.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                        confirmButtonText: '¡Eliminar!',
                        cancelButtonText: 'Mantener.',
                      }).then(result => {
                        if (result.value) {
                          API.Reservation.remove(row.id).then(() => {
                            Toast.fire({
                              icon: 'info',
                              title: 'El registro fue eliminado.',
                            });
                            tableRef.current.onQueryChange();
                          });
                        }
                      });
                    },
                  }
                : null,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
