import { parseISO } from 'date-fns';
import * as Yup from 'yup';

const invalidDate = new Date('');

function parseDateFromFormats(format, time = false) {
  return this.transform((value, originalValue) => {
    if (this.isType(value)) return value;
    if (typeof originalValue === 'string' && typeof format === 'string') {
      return parseISO(time === 'time' ? `${'2000-01-01T'}${originalValue}` : originalValue) || invalidDate;
    }
    return invalidDate;
  });
}
Yup.addMethod(Yup.date, 'format', parseDateFromFormats);

function minSelectedInclude(message) {
  return this.test('minselected', message, list => !!list.find(i => i.include));
}
Yup.addMethod(Yup.array, 'minselected', minSelectedInclude);

function primaryKey(message) {
  return this.test('pk', message, value => value === null || (Number.isInteger(value) && value > 0));
}

Yup.addMethod(Yup.number, 'pk', primaryKey);

function dni(message) {
  return this.matches(/^[0-9]{8}$/, message);
}
Yup.addMethod(Yup.string, 'dni', dni);

function ruc(message) {
  return this.matches(/^[0-9]{11}$/, message);
}
Yup.addMethod(Yup.string, 'ruc', ruc);

function price(message) {
  return this.matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, message);
}
Yup.addMethod(Yup.string, 'price', price);

function boleta(message) {
  return this.matches(/^[B][0-9]{3}-0*[1-9]\d{0,7}$/, message);
}
Yup.addMethod(Yup.string, 'boleta', boleta);

function factura(message) {
  return this.matches(/^[F|E][0-9]{3}-0*[1-9]\d{0,7}$/, message);
}
Yup.addMethod(Yup.string, 'factura', factura);

export default Yup;
