import { Box, Button, Divider, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { Add, OpenInNew } from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import API from 'utils/api';
import { useGetId } from 'utils/hooks';
import Toast from 'utils/toast';

export default function Documents() {
  const [files, setFiles] = useState([]);
  const { id } = useGetId();
  const [DNI, setDNI] = useState(null);

  const updateFiles = () => {
    API.ReservationFile.all({
      reservation: id,
      get_all: true,
    }).then(({ data: result }) => {
      setFiles(result.filter(i => i.document_type !== 'DNI'));
      setDNI(result.find(i => i.document_type === 'DNI'));
    });
  };

  useEffect(() => {
    updateFiles();
  }, []);

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        {DNI ? (
          <Box p={2}>
            <a
              href={DNI.file}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              className="file-link"
            >
              {DNI.name.match(/\.(png|jpg|jpeg)$/) ? (
                <>
                  <Box>
                    <Typography variant="button">DOCUMENTO DNI</Typography>
                  </Box>
                  <img src={DNI.file} alt={DNI.name} style={{ height: '100px', width: 'auto' }} />
                </>
              ) : (
                <strong>
                  DNI adjunto en formato pdf{' '}
                  <IconButton
                    aria-label="open"
                    onClick={() => {
                      window.open(DNI.file);
                    }}
                  >
                    <OpenInNew />
                  </IconButton>
                </strong>
              )}
            </a>
            <IconButton
              aria-label="Delete"
              onClick={() => {
                Swal.fire({
                  title: '¿Desea eliminar el DNI?',
                  text: 'Después podrá agregar otro DNI.',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: '¡Eliminar!',
                  cancelButtonText: 'Mantener.',
                }).then(result => {
                  if (result.value) {
                    API.ReservationFile.remove(DNI.id).then(() => {
                      Toast.fire({
                        icon: 'info',
                        title: 'El DNI fue eliminado.',
                      });
                      updateFiles();
                    });
                    setDNI(null);
                  }
                });
              }}
            >
              <Delete color="secondary" />
            </IconButton>
          </Box>
        ) : (
          <DniFileUpload updateFiles={updateFiles} />
        )}
        {/*  */}
      </Grid>
      <Divider
        style={{
          width: '98%',
          marginTop: '15px',
          marginBottom: '15px',
          height: '2px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      />
      <Grid item md={12} xs={12}>
        <Box p={2}>
          <Typography variant="button">Otros documentos</Typography>
        </Box>

        {files
          .filter(file => file.document_type === 'OTRO')
          .map(file => (
            <Box key={file.id} mx={2}>
              <li>
                <a
                  href={file.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  className="file-link"
                >
                  <strong>{file.name}</strong>
                </a>

                <IconButton
                  aria-label="open"
                  onClick={() => {
                    window.open(file.file);
                  }}
                >
                  <OpenInNew />
                </IconButton>

                {/* Remove file */}
                <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    Swal.fire({
                      title: '¿Desea eliminar el archivo?',
                      text: `${file.name}`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#3085d6',
                      confirmButtonText: '¡Eliminar!',
                      cancelButtonText: 'Mantener.',
                    }).then(result => {
                      if (result.value) {
                        API.ReservationFile.remove(file.id).then(() => {
                          Toast.fire({
                            icon: 'info',
                            title: 'El registro fue eliminado.',
                          });
                          updateFiles();
                        });
                      }
                    });
                  }}
                >
                  <Delete color="secondary" />
                </IconButton>
              </li>
            </Box>
          ))}
      </Grid>
      <Grid item xs={12}>
        <SingleFileUpload updateFiles={updateFiles} />
      </Grid>
    </Grid>
  );
}

function SingleFileUpload(props) {
  const { updateFiles } = props;
  const [file, setFile] = useState(null);
  const { id } = useGetId();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      // Max file size: 1MB
      if (file.size > 1048576) {
        Swal.fire({
          icon: 'warning',
          title: 'El tamaño del archivo es muy grande (Max 1MB).',
          showConfirmButton: true,
        });
        inputRef.current.value = '';
        setFile(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      const timestamp = new Date().getTime();
      //
      const formData = new FormData();
      formData.append('file', file);
      formData.append('reservation', id);
      formData.append('size', file.size);
      formData.append('name', file.name.replace(/\s/g, '_').replace(/\./g, `_${timestamp}.`));

      inputRef.current.value = '';
      setFile(null);
      //
      API.ReservationFile.create(formData).then(({ data: result }) => {
        setLoading(false);
        //
        if (result.id) {
          Toast.fire({
            icon: 'success',
            title: 'El archivo fue cargado.',
          });
          updateFiles();
        }
      });
    }
  }, [file]);

  const handleChange = e => {
    setFile(e.target.files[0]);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box p={1}>
      {loading && <LinearProgress />}
      <input type="file" onChange={handleChange} ref={inputRef} style={{ display: 'none' }} />
      <Button variant="contained" color="primary" component="span" onClick={handleButtonClick} startIcon={<Add />}>
        ADJUNTAR OTRO DOCUMENTO
      </Button>
    </Box>
  );
}

function DniFileUpload(props) {
  const { updateFiles } = props;
  const [file, setFile] = useState(null);
  const { id } = useGetId();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      // Max file size: 1MB
      if (file.size > 1048576) {
        Swal.fire({
          icon: 'warning',
          title: 'El tamaño del archivo es muy grande (Max 1MB).',
          showConfirmButton: true,
        });
        inputRef.current.value = '';
        setFile(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      const timestamp = new Date().getTime();
      //
      const formData = new FormData();
      formData.append('file', file);
      formData.append('reservation', id);
      formData.append('size', file.size);
      formData.append('name', file.name.replace(/\s/g, '_').replace(/\./g, `_${timestamp}.`));
      formData.append('document_type', 'DNI');

      inputRef.current.value = '';
      setFile(null);
      //
      API.ReservationFile.create(formData).then(({ data: result }) => {
        setLoading(false);
        //
        console.log(result);
        if (result.id) {
          Toast.fire({
            icon: 'success',
            title: 'El DNI fue cargado.',
          });
          updateFiles();
        }
      });
    }
  }, [file]);

  const handleChange = e => {
    setFile(e.target.files[0]);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box p={1}>
      {loading && <LinearProgress />}
      <Box p={1}>
        <Typography variant="body1">Agregar DNI (Imagen o PDF)</Typography>
      </Box>
      <input
        type="file"
        onChange={handleChange}
        ref={inputRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpeg, image/jpg, application/pdf"
      />
      <Button variant="contained" color="primary" component="span" onClick={handleButtonClick}>
        SELECCIONAR Y SUBIR
      </Button>
    </Box>
  );
}
