import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import DatePicker from 'components/DatePicker2';
import 'react-datepicker/dist/react-datepicker.css';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

const ActionDatePicker = ({ name, label, isEdit }) => {
  // * Clases
  const { values } = useFormikContext();

  const [serviceRequestData, setQueryServiceRequestData] = useFetchData(
    (query = {}) => API.ServiceRequest.action('dates', query),
    [],
  );

  useEffect(() => {
    if (!isEdit) {
      if (values.service || values.supplier || values.supplier_contact || values.currency) {
        const query = {};
        if (values.service) query.service = values.service;
        if (values.supplier) query.supplier = values.supplier;
        if (values.supplier_contact) query.contact = values.supplier_contact;
        if (values.currency) query.currency = values.currency;
        setQueryServiceRequestData(query);
      } else {
        setQueryServiceRequestData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service, values.supplier, values.supplier_contact, values.currency]);

  return (
    <>
      <DatePicker
        label={label}
        placeholderText="Seleccione"
        dateFormat="dd/MM/yyyy"
        name={name}
        valueFormat="yyyy-MM-dd"
        isClearable
        includeDates={serviceRequestData ? serviceRequestData.map(i => new Date(i.date_in_value)) : []}
      />
    </>
  );
};

export default ActionDatePicker;
