import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import { useYupRequired } from 'utils/hooks';

export default function BaseSelect({
  // * Props
  label,
  name,
  options = [],
  placeholder,
  optionLabel = 'name',
  optionValue = 'id',
  onSelect,
  modeValue = false,
  ...attr
}) {
  // * "Componente por defecto"
  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const required = useYupRequired(name);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 0);

  return (
    <>
      <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      {options?.length ? (
        <Select
          options={options || []}
          {...(modeValue
            ? { value: options?.find(i => i[optionValue] === value) || '' }
            : { defaultValue: options?.find(i => i[optionValue] === value) || '' })}
          // onChange={sel => setValue(sel ? sel[optionValue] : null)}
          placeholder={placeholder || 'Seleccionar'}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isDisabled={isSubmitting}
          getOptionLabel={i => i[optionLabel]}
          getOptionValue={i => i[optionValue]}
          onChange={sel => {
            debouncedHandleOnChange(sel ? sel[optionValue] : null);
            if (onSelect) {
              onSelect(sel);
            }
          }}
          {...attr}
        />
      ) : (
        <>
          <Select
            isDisabled
            placeholder={placeholder || 'Seleccionar'}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </>
      )}
      <div name={name} />
      <ErrorMessage name={name} />
    </>
  );
}

BaseSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};
