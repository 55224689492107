import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useYupRequired } from 'utils/hooks';

registerLocale('es', es);
const useStyles = makeStyles(() => ({
  input: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
}));

const handleSelect = (value, transform, showTimeSelectOnly = false) => {
  if (!value) {
    return null;
  }

  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string' && showTimeSelectOnly) {
    return parseISO(`${'2000-01-01T'}${value}`);
  }

  if (typeof value === 'string') {
    return parseISO(value);
  }
  if (transform) {
    return transform(value);
  }
  return null;
};

const DatePickerStyled = styled.div`
  /* STYLE PARA ANCHO */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  .react-datepicker__time-list-item {
    height: auto !important;

    background-color: #e3f2fd;
  }
  .react-datepicker__time-list-item:hover {
    height: auto !important;
    background-color: #fff !important;
    border: 1px solid #000;
  }
  .react-datepicker__time-list-item--selected:hover {
    height: auto !important;
    background-color: #216ba5 !important;
    border: 0;
    /* color: #000 !important; */
  }

  .react-datepicker__time-container--with-today-button {
    right: -87px;
    top: -1px;
  }
`;

export default function Picker({
  // * Props
  name,
  label,
  valueFormat,
  transform,
  showTimeSelectOnly = false,
  minDate,
  maxDate,
  disabled,
  placeholderText,
  ...attr
}) {
  // * Clases
  const classes = useStyles();
  const [, { value }, { setValue }] = useField(name);

  const [defaultValue, setDefaultValue] = useState(value);

  const { isSubmitting } = useFormikContext();
  const required = useYupRequired(name);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 1000);

  return (
    <>
      <Typography>
        {required && label && <strong>* </strong>}
        {label}
      </Typography>
      <DatePickerStyled>
        <Grid container>
          {required && !label && <strong>* </strong>}
          <DatePicker
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Hora"
            dateFormat="dd/MM/yyyy"
            isClearable
            customInput={<TextField fullWidth variant="outlined" size="small" className={classes.input} />}
            placeholderText={placeholderText || 'Seleccione'}
            locale="es"
            fixedHeight
            showTimeSelectOnly={showTimeSelectOnly}
            {...attr}
            onChange={seldate => {
              const dataInput = valueFormat && seldate ? format(seldate, valueFormat) || null : seldate;
              setDefaultValue(dataInput);
              debouncedHandleOnChange(dataInput);
            }}
            selected={handleSelect(defaultValue, transform, showTimeSelectOnly)}
            minDate={handleSelect(minDate, transform)}
            maxDate={handleSelect(maxDate, transform)}
            disabled={disabled || isSubmitting}
            name={name}
            todayButton="Hoy"
          />
        </Grid>
      </DatePickerStyled>
      <ErrorMessage name={name} />
    </>
  );
}
