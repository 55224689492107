import { Button, Grid, Paper, Slide, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ErrorMessage from 'components/ErrorMessage';
import PersonChip from 'components/PersonChip';
import { FieldArray } from 'formik';
import Person from 'Forms/Person';
import DialogForm from 'layouts/DialogForm';
import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import { API } from 'utils/api';
import { useFetchData, useYupRequired } from 'utils/hooks';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    // minHeight: '32px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%',
    padding: '0',
    margin: '0',
    backgroundColor: theme.palette.grey[100],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  button: {
    alignSelf: 'center',
  },
  placeholder: {
    display: 'flex',
    alignSelf: 'center',
    color: 'hsl(0,0%,50%)',
  },
}));

const Placeholder = props => (
  <components.Placeholder {...props}>
    <SearchIcon />
    <span>{props.children}</span>
  </components.Placeholder>
);

const searchPerson = inputValue => API.Person.all({ search: inputValue }).then(({ data: { result } }) => result);

const Option = props => {
  if (props.value === 'create') {
    return (
      <components.Option {...props}>
        <AddIcon />
        <strong>{props.children}</strong>
      </components.Option>
    );
  }
  if (props.value === 'nodata' && props.value === 'loading') {
    return (
      <components.Option {...props}>
        <div style={{ textAlign: 'center' }}>
          <strong>{props.children}</strong>
        </div>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

const optionsSearch = [
  { label: 'POR NOMBRES / APELLIDOS', value: 'name', color: '#651fff' },
  { label: 'POR DNI', value: 'dni', color: '#009688' },
  { label: 'POR RUC', value: 'ruc', color: '#f44336' },
  { label: 'POR CARNET DE EXTRANJERIA', value: 'carnet', color: '#ffeb3b' },
  { label: 'POR PASAPORTE', value: 'passport', color: '#cddc39' },
];

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

function PeoplePicker({
  // * Props
  label,
  name,
  placeholder,
  isDialog = false,
  actions,
  type,
  onLoadChip,
  highlight,
  onHigh,
  onDelete,
}) {
  // * "Componente por defecto"

  const classes = useStyles();
  // const [, { value: people }, { setValue: setResponsibles }] = useField(name);
  const {
    form: { isSubmitting, values },
    push,
    remove,
  } = actions;
  const { people = values[name] } = values;
  // const [high, setHigh] = useState(highlight || null);
  const [typeSearch, setTypeSearch] = useState('name');
  const [showOptions, setShowOptions] = useState(false);

  const [openSearch, setOpenSearch] = useState(!people.length);
  const required = useYupRequired(name);

  const [inputDoc, setInputDoc] = useState('');
  const [data, setQueryData] = useFetchData(API.Person.all, []);
  const [options, setOptions] = useState([]);

  const [openCreate, setOpenCreate] = useState(false);
  const closeCreate = useCallback(() => setOpenCreate(false), [setOpenCreate]);

  useEffect(() => {
    if (people.length === 0) {
      setOpenSearch(true);
    }
  }, [people.length]);

  useEffect(() => {
    if (data.length) {
      setOptions([...data]);
    } else {
      setOptions([
        {
          id: 'nodata',
          name: 'No se encontraron resultados',
          disabled: true,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (!inputDoc.length) {
      setShowOptions(false);
    }
  }, [inputDoc]);
  return (
    <Container disableGutters>
      <DialogForm
        open={openCreate}
        onClose={closeCreate}
        component={Person}
        onCreate={data => {
          push(data.id);
          setOpenCreate(false);
          Toast.fire({
            icon: 'success',
            title: `${data.name} registrado con éxito.`,
          });
        }}
        data={(() => {
          const item = {};
          if (typeSearch !== 'name') {
            item[typeSearch] = inputDoc;
          }
          return item;
        })()}
        type={type}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Button
            endIcon={openSearch ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setOpenSearch(prev => !prev)}
            className={classes.button}
          >
            {required && '* '}
            {label}
          </Button>
        </Grid>
        <Grid item md={10} xs={12}>
          {people.length ? (
            <Slide direction="up" in mountOnEnter unmountOnExit>
              <Paper
                component="ul"
                className={classes.root}
                variant="outlined"
                role="button"
                onClick={e => e.target === e.currentTarget && setOpenSearch(true)}
              >
                {people.map((person, index) => (
                  <Slide key={`slide${person}`} direction="up" in mountOnEnter unmountOnExit>
                    <li key={`li${person}`}>
                      <PersonChip
                        id={person}
                        onDelete={() => {
                          if (onDelete) {
                            onDelete({ person, remove: () => remove(index) });
                          } else {
                            remove(index);
                          }
                        }}
                        type={type}
                        onLoad={onLoadChip}
                        popButtons={
                          onHigh && highlight !== person
                            ? [
                                {
                                  title: 'Asignar como Pax Principal',
                                  icon: <AssignmentIndIcon />,
                                  onClick: ({ handleClose }) => onHigh({ handleClose, person }),
                                  color: 'secondary',
                                },
                              ]
                            : []
                        }
                        color={person === highlight ? 'secondary' : 'default'}
                      />
                    </li>
                  </Slide>
                ))}
              </Paper>
            </Slide>
          ) : (
            <div className={classes.placeholder}>{placeholder}</div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ display: openSearch ? 'flex' : 'none', margin: '15px 0' }}>
        <Grid item xs={12} md={6}>
          <Select
            options={optionsSearch}
            placeholder="Seleccionar"
            value={optionsSearch.find(i => i.value === typeSearch)}
            onChange={sel => setTypeSearch(sel.value)}
            isSearchable={false}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: provided => ({
                ...provided,
                backgroundColor: 'inherit', // * Para zIndex
              }),
              input: styles => ({
                ...styles,
                ...dot(),
              }),
              placeholder: styles => ({ ...styles, ...dot() }),
              singleValue: (styles, { data }) => ({
                ...styles,
                ...dot(data.color),
                // color: data.color,
                fontWeight: 'bold',
              }),
              dropdownIndicator: (base, { selectProps: { value } }) => ({
                ...base,
                color: value.color,
              }),
            }}
            menuPortalTarget={document.body}
            isDisabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {typeSearch.includes('name') ? (
            <AsyncCreatableSelect
              isMulti
              // cacheOptions
              placeholder="Ingrese nombres o apellidos..."
              loadOptions={searchPerson}
              controlShouldRenderValue={false}
              components={{ Placeholder, Option }}
              // noOptionsMessage={() => ('No se encontraron resultados')}
              // loadingMessage="Buscando..."
              getOptionLabel={i => i.name}
              getOptionValue={i => i.id}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                placeholder: styles => ({
                  ...styles,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }),
                option: (styles, props) =>
                  props.value === 'create'
                    ? {
                        ...styles,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }
                    : styles,
              }}
              onCreateOption={() => setOpenCreate(true)}
              getNewOptionData={inputValue => ({
                id: 'create',
                name: `REGISTRAR A ${inputValue.toUpperCase()}`,
              })}
              hideSelectedOptions={false}
              openMenuOnClick={false}
              isOptionDisabled={i => people.includes(i.id)}
              isDisabled={isSubmitting}
              value={people.map(i => ({ id: i, name: i }))}
              onChange={(sel, { option }) => {
                if (option) {
                  push(option.id);
                }
              }}
              menuPortalTarget={document.body}
              isClearable={false}
              menuPosition="fixed"
              isOptionSelected={() => false}
              menuPlacement={isDialog ? 'top' : 'auto'}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Creatable
                  isMulti
                  placeholder={`Ingrese ${typeSearch} completo`.toUpperCase()}
                  options={[...options]}
                  controlShouldRenderValue={false}
                  components={{
                    Option,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => <Button onClick={() => setInputDoc('')}>x</Button>,
                  }}
                  getOptionLabel={i => i.name}
                  getOptionValue={i => i.id}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    placeholder: styles => ({
                      ...styles,
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }),
                    option: (styles, props) =>
                      props.value === 'create'
                        ? {
                            ...styles,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }
                        : styles,
                    input: styles => ({
                      ...styles,
                      width: '100%',
                      '& div, input': {
                        width: '100% !important',
                      },
                    }),
                  }}
                  onCreateOption={() => {
                    setOpenCreate(true);
                  }}
                  onInputChange={(query, { action }) => {
                    setShowOptions(false);
                    if (action === 'input-change') {
                      setInputDoc(query);
                      return query;
                    }
                    return inputDoc;
                  }}
                  getNewOptionData={inputValue => ({
                    id: 'create',
                    name: `REGISTRAR A ${inputValue.toUpperCase()}`,
                  })}
                  hideSelectedOptions={false}
                  openMenuOnClick={false}
                  isOptionDisabled={i => people.includes(i.id) || i.disabled === true}
                  isDisabled={isSubmitting}
                  value={people.map(i => ({ id: i, name: i }))}
                  onChange={(sel, { option }) => {
                    if (option) {
                      push(option.id);
                      setShowOptions(false);
                      setInputDoc('');
                    }
                  }}
                  menuPortalTarget={document.body}
                  isClearable
                  menuPosition="fixed"
                  isOptionSelected={() => false}
                  menuPlacement={isDialog ? 'top' : 'auto'}
                  menuIsOpen={showOptions}
                  isValidNewOption={(inv, val, opt) => {
                    if (opt.find(i => i.id === 'loading')) {
                      return false;
                    }
                    if (opt.find(i => !people.includes(i.id))) {
                      return true;
                    }
                    return false;
                  }}
                  filterOption={() => true}
                  onBlur={() => {
                    setShowOptions(false);
                  }}
                  inputValue={inputDoc}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  startIcon={<SearchIcon />}
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    const item = { get_all: true };
                    item[typeSearch] = inputDoc;
                    setOptions([
                      {
                        id: 'loading',
                        name: 'Buscando...',
                        disabled: true,
                      },
                    ]);
                    setQueryData(item);

                    setShowOptions(true);
                  }}
                  disableElevation
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ErrorMessage name={name} />
    </Container>
  );
}
export default props => (
  <FieldArray name={props.name} render={actions => <PeoplePicker actions={actions} {...props} />} />
);
