import { Drawer, Hidden, Icon, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

const blackColor = '#000';
const whiteColor = '#FFF';

const drawerWidth = 200;

const hexToRgb = input => {
  input += '';
  input = input.replace('#', '');
  const hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];
  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
};

const boxShadow = {
  boxShadow: `0 10px 30px -12px rgba(${hexToRgb(blackColor)}, 0.42), 0 4px 25px 0px rgba(${hexToRgb(
    blackColor,
  )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
};

const grayColor = [
  '#999',
  '#777',
  '#3C4858',
  '#AAAAAA',
  '#D2D2D2',
  '#DDD',
  '#b4b4b4',
  '#555555',
  '#333',
  '#a9afbb',
  '#eee',
  '#e7e7e7',
];

const skycolor = '#959595';

const styles = theme => ({
  drawerPaper: {
    ...boxShadow,

    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
    width: drawerWidth,
  },

  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: `rgba(${hexToRgb(grayColor[6])}, 0.3)`,
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: 'right',
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    width: '35px',
    top: '22px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8',
    },
  },
  list: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: whiteColor,
    },
  },
  itemLink: {
    width: 'auto',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: `rgba(${hexToRgb(grayColor[1])}, 0.1)`,
    ...defaultFont,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(grayColor[1])}, 0.5)`,
    },
  },
  itemIcon: {
    width: '1.4em',
    height: '1.4em',
    fontSize: '0.8em',
    lineHeight: '1.4em',
    float: 'left',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: `rgba(${hexToRgb(whiteColor)}, 0.8)`,
    margin: '-0.4em 15px 0 0',
  },
  itemIconRTL: {
    marginRight: '3px',
    marginLeft: '15px',

    float: 'right',
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '0.8em',
    fontSize: '0.8em',
    color: whiteColor,
    fontWeight: 'bold',
  },
  itemTextRTL: {
    textAlign: 'right',
  },
  whiteFont: {
    color: whiteColor,
  },
  sky: {
    backgroundColor: skycolor,
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(skycolor)},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor,
    )},.12), 0 7px 8px -5px rgba(${hexToRgb(skycolor)},.2)`,
    '&:hover,&:focus': {
      backgroundColor: skycolor,
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(skycolor)},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(skycolor)},.2)`,
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '100%',
    zIndex: '4',
    // overflowScrolling: 'touch',
  },
  // activePro: {
  //   [theme.breakpoints.up('md')]: {
  //     position: 'absolute',
  //     width: '100%',
  //     bottom: '13px',
  //   },
  // },
  toolbar: theme.mixins.toolbar,
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
    zIndex: 5,
    color: '#FFFFFF',
    fontSize: '.9em',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  listNested: {
    // marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
});

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const { color, image, structure } = props;

  const arrStructure = Object.values(structure);
  const currentIndex = arrStructure.findIndex(entry => window.location.href.indexOf(entry.path) !== -1);
  const currentEntry = arrStructure[currentIndex];

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }

  const links = (
    <List className={classes.list}>
      {currentEntry.routes.map(route => {
        let listItemClasses = '';

        const isActive = activeRoute(currentEntry.path + route.path);

        listItemClasses = classNames({
          [` ${classes[color]}`]: isActive,
        });

        const whiteFontClasses = classNames({
          [` ${classes.whiteFont}`]: isActive,
        });

        return (
          <NavLink
            to={currentEntry.path + route.path}
            className={classes.item}
            activeClassName="active"
            key={`link${currentEntry.name}${route.name}`}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof route.icon === 'string' ? (
                <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>{route.icon}</Icon>
              ) : (
                <route.icon className={classNames(classes.itemIcon, whiteFontClasses)} />
              )}
              <ListItemText
                primary={route.title}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.sidebarWrapper}>{links}</div>
      {image !== undefined ? <div className={classes.background} /> : null}
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          variant="temporary"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}
