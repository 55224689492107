import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

registerLocale('es', es);
const useStyles = makeStyles(() => ({
  input: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
}));

const handleSelect = (value, transform, showTimeSelectOnly = false) => {
  if (!value) {
    return null;
  }

  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string' && showTimeSelectOnly) {
    return parseISO(`${'2000-01-01T'}${value}`);
  }

  if (typeof value === 'string') {
    return parseISO(value);
  }
  if (transform) {
    return transform(value);
  }
  return null;
};

const DatePickerStyled = styled.div`
  /* STYLE PARA ANCHO */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  .react-datepicker__time-list-item {
    height: auto !important;
  }
`;

export default function Picker({
  // * Props
  name,
  label,
  valueFormat,
  transform,
  showTimeSelectOnly = false,
  minDate,
  maxDate,
  disabled,
  placeholderText,
  defaultValue: defaultVal,
  setValRequest,
  ...attr
}) {
  // * Clases
  const classes = useStyles();

  const [defaultValue, setDefaultValue] = useState(defaultVal);

  return (
    <>
      <DatePickerStyled>
        <Box display="flex">
          <DatePicker
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Hora"
            dateFormat="dd/MM/yyyy"
            isClearable
            customInput={<TextField fullWidth variant="outlined" size="small" className={classes.input} />}
            placeholderText={placeholderText || 'Seleccione'}
            locale="es"
            fixedHeight
            showTimeSelectOnly={showTimeSelectOnly}
            {...attr}
            onChange={seldate => {
              const dataInput = valueFormat && seldate ? format(seldate, valueFormat) || null : seldate;
              setDefaultValue(dataInput);
              setValRequest(dataInput);
            }}
            selected={handleSelect(defaultValue, transform, showTimeSelectOnly)}
            minDate={handleSelect(minDate, transform)}
            maxDate={handleSelect(maxDate, transform)}
            // disabled={disabled || isSubmitting}
            // name={name}
          />
        </Box>
      </DatePickerStyled>
    </>
  );
}
