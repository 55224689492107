import { Box, Button, LinearProgress } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { grayColor } from 'assets/jss/material-dashboard-react.js';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CountryCityComp from 'components/CountryCityComp';
import CurrencySelect from 'components/CurrencySelect';

import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PackageGroupSelect from 'components/PackageGroupSelect';
import PriceInput from 'components/PriceInput';
import ServiceSelect from 'components/ServiceSelect';
import Viewer from 'components/Viewer';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { useEditPage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Demasiado corto.').max(250, 'Máximo permitido').required('Ingrese un nombre'),
  duration: Yup.number()
    .integer()
    .positive('Ingrese un número positivo')
    .typeError('Ingrese un número positivo')
    .required('Ingrese la duración'),
  image: Yup.string().min(20, 'Demasiado corto.'),
  description: Yup.string().min(20, 'Demasiado corto.'),
  price: Yup.string()
    .ensure()
    .trim()
    .matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, 'Ingrese un precio correcto')
    .required('Precio requerido'),
  currency: Yup.string().required('Seleccione moneda'),
  groups: Yup.array().ensure(),
  services: Yup.array().ensure().required('Debe seleccionar al menos un servicio'),
  city: Yup.number().nullable().pk().required('Seleccione Ciudad'),
  region: Yup.number().nullable().pk().required('Seleccione Región'),
  country: Yup.number().nullable().pk().required('Seleccione País'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
}));

export default function Add() {
  const classes = useStyles();

  const history = useHistory();

  const [fetchData, id, info] = useEditPage(id => API.Package.get(id));

  const ability = useContext(AbilityContext);

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  return (
    <YupProvider value={validationSchema}>
      <GridContainer justify="center">
        <GridItem md={12} xl={8}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>{info.isEdit ? 'EDITAR Tour' : 'AGREGAR Tour'}</h3>
            </CardHeader>

            <CardBody>
              <Formik
                initialValues={
                  info.isEdit
                    ? {
                        name: fetchData.name,
                        duration: fetchData.duration,
                        price: fetchData.price,
                        image: fetchData.image,
                        description: fetchData.description,
                        currency: fetchData.currency,
                        groups: fetchData.groups,
                        services: fetchData.services,
                        // languages: fetchData.languages
                        /// s? fetchData.languages.map(i => ({ value: i.id, label: i.lang })) : [],
                        // contacts: fetchData.contacts || [],
                        // custom_metas: customMetas
                        // services: fetchData.services.map(i => (
                        //   {
                        //     ...i,
                        //     currency: i.currency ? { label: i.currency, value: i.currency } : ''
                        //   }))
                        city: fetchData.city,
                        region: fetchData.region,
                        country: fetchData.country,
                      }
                    : {
                        name: '',
                        duration: '',
                        price: '',
                        image: '',
                        description: '',
                        currency: '',
                        // services: [],
                        // country: { value: 175, label: 'Perú' },
                        // languages: [{ value: 149, label: 'Español' }],
                        // contacts: [],

                        // "custom_fields": [
                        //     1
                        // ],
                        // "modules": []
                        // custom_metas: customMetas
                        groups: [],
                        services: [],
                        city: null,
                        region: null,
                        country: 175,
                      }
                }
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  const submit = {
                    name: values.name,
                    duration: values.duration,
                    price: values.price,
                    image: values.image,
                    description: values.description,
                    currency: values.currency,
                    services: values.services,
                    groups: values.groups,
                    city: values.city,
                  };

                  if (info.isEdit) {
                    API.Package.update(id, submit).then(() => {
                      actions.setSubmitting(false);
                      Swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Tour guardado.',
                      });
                    });
                  } else {
                    API.Package.create(submit).then(({ data }) => {
                      Swal.fire({
                        icon: 'success',
                        title: 'Creado',
                        text: 'Nuevo Tour creado.',
                      });
                      history.push(`${goto(MODS.PACKAGES)}/${data.id}`);
                    });
                  }
                }}
              >
                {({ values, isSubmitting, submitForm }) => (
                  <Form autoComplete="off">
                    <GridContainer>
                      <Grid item md={9} className={classes.gridItem}>
                        <FormInput label="Nombre de Tour" name="name" />
                      </Grid>
                      <Grid item md={3} className={classes.gridItem}>
                        <FormInput label="Dias de duración" name="duration" />
                      </Grid>
                    </GridContainer>
                    <GridContainer>
                      <Grid item md={3} className={classes.gridItem}>
                        <PriceInput label="Precio" name="price" currency={values.currency} />
                      </Grid>
                      <Grid item md={3} className={classes.gridItem}>
                        <CurrencySelect label="Moneda" name="currency" />
                      </Grid>
                      <Grid item md={6} className={classes.gridItem}>
                        <PackageGroupSelect label="Grupos de Filtrado" isMulti name="groups" />
                      </Grid>
                    </GridContainer>
                    <Box>
                      <CountryCityComp countryName="country" regionName="region" cityName="city" />
                    </Box>
                    {ability.can('edit', 'app') && (
                      <Box display="flex">
                        <Box width={1 / 2} pt={2} pr={1}>
                          <FormInput label="Imagen de Tour (App)" name="image" />
                        </Box>
                        <Box width={1 / 2} pt={2} pl={1}>
                          <FormInput
                            label="Descripción del Tour (App)"
                            name="description"
                            multiline
                            rows={8}
                            size="medium"
                          />
                        </Box>
                      </Box>
                    )}

                    <br />
                    <FieldArray
                      name="services"
                      render={arrayHelpers => (
                        <>
                          <GridContainer>
                            <GridItem md={12}>
                              <Typography color="primary" display="block" variant="body1">
                                SERVICIOS DE PROVEEDOR
                              </Typography>
                              <Divider />
                              <ErrorMessage name="services">
                                {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
                              </ErrorMessage>
                            </GridItem>
                          </GridContainer>
                          <br />

                          <ServiceSelect onSelect={arrayHelpers.push} exclude={values.services.map(s => s)} />
                          <br />
                          <Table style={{ width: '100%' }}>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ padding: '0 2px', color: '#FFF' }}>Nº</TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF' }}>Tipo</TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF' }}>Proveedor</TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF', width: '30%' }}>
                                  Servicio
                                </TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF', width: '10%' }} align="center">
                                  Costo
                                </TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF', width: '10%' }} align="center">
                                  IGV
                                </TableCell>
                                <TableCell style={{ padding: '0 2px', color: '#FFF' }} align="center">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.services.map((s, i) => (
                                <Viewer name="Service" value={s} key={`service${String(i)}`} loading={<tr />}>
                                  {service => (
                                    <TableRow>
                                      <TableCell style={{ padding: '0' }}>{String(i + 1)}</TableCell>
                                      <TableCell style={{ padding: '0' }}>{service.supplier_type}</TableCell>
                                      <TableCell style={{ padding: '0' }}>{service.supplier_name}</TableCell>
                                      <TableCell style={{ padding: '0' }}>{service.description}</TableCell>
                                      <TableCell style={{ padding: '0' }} align="center">
                                        {service.cost}
                                        <br />
                                        <small>{service.currency}</small>
                                      </TableCell>
                                      <TableCell style={{ padding: '0' }} align="center">
                                        {service.igv ? 'INCLUIDO' : 'SIN IGV'}
                                      </TableCell>
                                      <TableCell style={{ padding: '0' }}>
                                        <Box display="flex" justifyContent="center">
                                          <Box p={1}>
                                            <Tooltip title="Editar">
                                              <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                // onClick={() => {
                                                //   history.push(`${goto(MODS.SUPPLIERS)}/${service.supplier}`);
                                                // }}
                                                href={`${goto(MODS.SUPPLIERS)}/${service.supplier}`}
                                                target="_blank"
                                                disabled={isSubmitting}
                                              >
                                                <EditOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                          <Box p={1}>
                                            <Tooltip title="Eliminar">
                                              <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => {
                                                  arrayHelpers.remove(i);
                                                }}
                                                disabled={isSubmitting}
                                              >
                                                <RemoveCircleOutlineIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Viewer>
                              ))}
                            </TableBody>
                          </Table>
                          <br />
                        </>
                      )}
                    />

                    {isSubmitting && <LinearProgress />}
                    <Grid
                      container
                      // justify="center"
                      justify="center"
                      style={{ marginTop: '20px' }}
                    >
                      <GridItem md={3}>
                        <Button
                          color="primary"
                          // onClick={() => handleSubmit()}
                          variant="contained"
                          fullWidth
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Guardar
                        </Button>
                      </GridItem>
                    </Grid>
                    <FormikErrorFocus
                      offset={-100}
                      // align="top"
                      // focusDelay={50}
                      ease="out-bounce"
                      duration={50}
                    />
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </YupProvider>
  );
}
