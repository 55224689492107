import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createFilter } from 'react-select';
import { API } from 'utils/api';
import { MenuList } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
// import { useYupRequired } from 'utils/hooks';
import Select from 'components/Select';

export default function CitySelect({
  // * Props
  label, name, placeholder, region
}) {
  // * "Componente por defecto"

  const [data, setQuery] = useFetchData(API.City.all, []);
  const [, , { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const first = useRef(true);

  // const required = useYupRequired(name);

  useEffect(() => {
    // Para que se haga null a partir del segund iteración
    first.current = first.current ? false : !!setValue(null);

    if (region !== null) {
      setQuery({ get_all: true, region });
    }

    // if (region === null) {
    //   setValue(null);
    // }
  }, [region]);

  return (
    <>
      {/* <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      <Select

        {...(region === undefined && {
          filterOption: createFilter({ ignoreAccents: false }),
          components: { MenuList, Input: props => <div name={name}><components.Input {...props} /></div> }
        })}

        value={data?.find(i => i.id === value) || ''}
        onChange={sel => setValue(sel?.id || null)} // ! para tener null y no ''
        options={data}
        placeholder={placeholder || 'Seleccionar'}
        getOptionLabel={i => i.name}
        getOptionValue={i => i.id}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        isDisabled={isSubmitting || (region === null)}
      />
      <div name={name} />
      <ErrorMessage name={name} /> */}
      <Select
        {...(region === undefined && {
          filterOption: createFilter({ ignoreAccents: false }),
          components: { MenuList }
        })}
        options={data}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={isSubmitting || (region === null)}
        modeValue
      />
    </>
  );
}

CitySelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  region: PropTypes.number,
};
