import { Button, LinearProgress } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchText from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import CurrencySelect from 'components/CurrencySelect';
import DatePicker from 'components/DatePicker2';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IGVSwitch from 'components/IGVSwitch';
import PaymentMethodSelect from 'components/PaymentMethodSelect';
import PaymentVoucherSelect from 'components/PaymentVoucherSelect';
import StatusSwitch from 'components/StatusSwitch';
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { date2format, date2fullstr, date2str, str2date, str2format, str2fullformat } from 'utils/helpers';
import { useEditPage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  comment: Yup.string().ensure().trim(),
  voucher_code: Yup.string().ensure().trim().required('Ingrese la boleta o factura'),
  status: Yup.string().ensure().trim(),
  alter_receiver_name: Yup.string().ensure().trim(),
  alter_receiver_dni: Yup.string().ensure().trim(),
  paidout_date: Yup.string().ensure().trim(),
  voucher_type: Yup.number().positive().nullable().required('Seleccione comprobante'),
  payment_method: Yup.number().positive().nullable(),
  service_orders: Yup.array().required('Debe agregar al menos una orden de servicio'),
  cost: Yup.string()
    .ensure()
    .trim()
    .matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, 'Costo incorrecto')
    .required('Costo requerido'),
  currency: Yup.string().ensure().trim().required('Costo requerido'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `1px solid ${grayColor[5]}`,
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
  actionButton: {
    backgroundColor: '#EEE',
    margin: '10px 15px 10px 0',
  },
}));

const getsymbol = currency => {
  switch (currency) {
    case 'DOLARES':
      return '$';
    case 'EUROS':
      return '€';
    case 'SOLES':
    default:
      return 'S/';
  }
};

const Cost = props => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!props.costEdited) {
      setFieldValue(
        'cost',
        values.service_orders.reduce((a, i) => a + parseFloat(i.total_cost), 0),
      );
    }
  }, [values.service_orders]);

  return (
    <>
      <Badge badgeContent="E" color="primary" invisible={!props.costEdited}>
        <Typography>Total</Typography>
      </Badge>
      <div style={{ fontSize: '1.6em' }}>{`${getsymbol(values.currency)} ${parseFloat(values.cost).toFixed(2)}`}</div>
      <small style={{ margin: '-6px 0 0 0', display: 'block', color: values.igv ? '#FF0000' : '#000' }}>
        {values.igv ? 'INCLUYE IGV' : 'SIN IGV'}
      </small>
    </>
  );
};
export default function Add() {
  const classes = useStyles();

  const [fetchData, id, info] = useEditPage(id => API.ServiceOrderPayment.get(id));

  const [serviceOrderCode, setServiceOrderCode] = useState('');
  const [showAlternativeReceiver, setShowAlternativeReceiver] = useState(false);

  const onAdd = (values, setFieldValue, arrayHelpers) => {
    const idData = serviceOrderCode.trim();

    if (values.service_orders.find(i => i.code === idData)) {
      Toast.fire({
        icon: 'warning',
        title: 'Ya existe la orden de servicio.',
      });
    } else {
      API.ServiceOrder.action('search2pay', {
        order: idData,
      })
        .then(({ data }) => {
          // * Validaciones
          // ! Falta que no se pueda agregar los que ya están cerradas
          if (data.entered_group !== null) {
            Toast.fire({
              icon: 'error',
              title: 'La orden de servicio ya fue procesada.',
            });
            // * La primera vez cargamos supplier, moneda, igv
          } else if (!values.service_orders.length) {
            setFieldValue('supplier', data.supplier);
            setFieldValue('currency', data.currency);
            setFieldValue('igv', data.igv);

            arrayHelpers.push(data);
            setServiceOrderCode('');
          } else if (data.supplier !== values.supplier.id) {
            Toast.fire({
              icon: 'warning',
              title:
                `Las órdenes ${values.service_orders[0].code} y ${data.code}` +
                '<br /> deben tener el mismo proveedor.',
            });
          } else if (data.currency !== values.currency) {
            Toast.fire({
              icon: 'warning',
              title: `Las órdenes ${values.service_orders[0].code} y ${data.code} <br /> deben tener la misma moneda.`,
            });
          } else if (data.igv !== values.igv) {
            if (data.igv) {
              Toast.fire({
                icon: 'warning',
                title: `Todas las ordenes (${values.service_orders[0].code} y ${data.code}) deben incluir IGV.`,
              });
            } else {
              Toast.fire({
                icon: 'warning',
                title: `Ninguna órden (${values.service_orders[0].code} ó ${data.code}) debe incluir IGV.`,
              });
            }
          } else {
            arrayHelpers.push(data);
            setServiceOrderCode('');
          }
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'No existe la orden de servicio.',
          });
        });
    }
  };

  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (fetchData && (fetchData.alter_receiver_dni || fetchData.alter_receiver_name)) {
      setShowAlternativeReceiver(true);
    }
  }, [fetchData]);

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  return (
    <YupProvider value={validationSchema}>
      <GridContainer justify="center">
        <GridItem md={12} xl={8}>
          <Formik
            initialValues={{
              comment: fetchData.comment,
              voucher_code: fetchData.voucher_code,
              status: fetchData.status,
              supplier: fetchData.supplier,

              alter_receiver_name: fetchData.alter_receiver_name,
              alter_receiver_dni: fetchData.alter_receiver_dni,
              paidout_date: fetchData.paidout_date ? str2date(fetchData.paidout_date) : null,
              voucher_type: fetchData.voucher_type?.id || fetchData.voucher_type,
              approved_date: fetchData.approved_date ? new Date(fetchData.approved_date) : null,
              closed_date: fetchData.closed_date ? new Date(fetchData.closed_date) : null,
              rejected_date: fetchData.rejected_date ? new Date(fetchData.rejected_date) : null,

              payment_method: fetchData.payment_method?.id || fetchData.payment_method,
              service_orders: fetchData.entered_group.service_orders,
              entered_group: fetchData.entered_group,
              cost: parseFloat(fetchData.cost).toFixed(2),
              igv: fetchData.igv,
              currency: fetchData.currency,
              reply: fetchData.reply,
              created: fetchData.created,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              API.ServiceOrderPayment.update(id, {
                comment: values.comment,
                voucher_code: values.voucher_code,
                status: values.status,
                supplier: values.supplier?.id || values.supplier,

                alter_receiver_name: values.alter_receiver_name,
                alter_receiver_dni: values.alter_receiver_dni,
                paidout_date: values.paidout_date ? date2str(values.paidout_date) : null,
                voucher_type: values.voucher_type,
                approved_date: values.approved_date ? date2fullstr(values.approved_date) : null,
                closed_date: values.closed_date ? date2fullstr(values.closed_date) : null,

                cost_edited: values.cost_edited,
                payment_method: values.payment_method,
                entered_group: {
                  id: values.entered_group.id, // * Update
                  service_orders: values.service_orders.map(i => i.id),
                  // status_group: 'COMPLETE',
                  // payment_mode: 'ABSOLUTE'
                },
                cost: values.cost,
                igv: values.igv,
                currency: values.currency,
                reply: values.reply,
              }).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Actualizado',
                  text: 'Se guardaron los cambios',
                });
                actions.setSubmitting(false);
              });
            }}
          >
            {({ values, isSubmitting, submitForm, setFieldValue }) => (
              <Form autoComplete="off">
                <>
                  <GridContainer>
                    <Grid item md={6} className={classes.gridItem}>
                      <Typography>Ordernar pago a :</Typography>
                      <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>{values.supplier?.name}</div>
                    </Grid>
                    {values.cost_edited ? (
                      <>
                        <Grid item md={6}>
                          <Grid container>
                            <Grid item md={3} className={classes.gridItem}>
                              <FormInput label="Costo" name="cost" style={{ backgroundColor: '#FFF' }} />
                            </Grid>
                            <Grid item md={4} className={classes.gridItem}>
                              <CurrencySelect label="Moneda" name="currency" />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                textAlign: 'center',
                                // backgroundColor: 'lightyellow',
                                margin: '0 0 20px',
                              }}
                            >
                              <IGVSwitch label="¿Incluye IGV?" name="igv" />
                            </Grid>
                            <Grid item md={2} className={classes.gridItem}>
                              <Tooltip
                                title="Calcular Automático"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  aria-label="Aceptar"
                                  className={classes.actionButton}
                                  color="primary"
                                  onClick={() => setFieldValue('cost_edited', false)}
                                >
                                  <RotateLeftIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={2} className={classes.gridItem} style={{ textAlign: 'left' }}>
                          <Cost costEdited={values.cost_edited} />
                        </Grid>
                        <Grid item md={3} className={classes.gridItem}>
                          <Tooltip title="Editar Manualmente" placement="top" classes={{ tooltip: classes.tooltip }}>
                            <IconButton
                              aria-label="Close"
                              className={classes.actionButton}
                              color="primary"
                              onClick={() => {
                                setFieldValue('cost_edited', true);
                              }}
                            >
                              <LockOpenIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <Grid item md={3} className={classes.gridItem}>
                      <PaymentVoucherSelect name="voucher_type" label="Tipo de Comprobante" />
                    </Grid>
                    <Grid item md={3} className={classes.gridItem}>
                      <FormInput label="Código comprobante" name="voucher_code" placeholder="Código" />
                    </Grid>
                    <Grid item md={3} className={classes.gridItem}>
                      <Typography>Fecha de Ingreso :</Typography>
                      <div style={{ fontSize: '1.4em' }}>{str2fullformat(values.created)}</div>
                    </Grid>

                    <Grid item md={3} className={classes.gridItem}>
                      <PaymentMethodSelect label="Método de Pago" name="payment_method" isClearable />
                    </Grid>
                  </GridContainer>
                  <GridContainer>
                    <Grid item md={6} className={classes.gridItem}>
                      <StatusSwitch
                        label="Cambiar estado"
                        name="status"
                        helpText0={values.rejected_date ? date2format(values.rejected_date) : ''}
                        helpText1={date2format(new Date(values.created))}
                        helpText2={values.approved_date ? date2format(values.approved_date) : ''}
                        helpText3={values.paidout_date ? date2format(values.paidout_date) : ''}
                      />
                    </Grid>
                    <Grid item md={3} className={classes.gridItem}>
                      <Typography>Fecha de Aprobación</Typography>
                      <div style={{ fontSize: '1.5em' }}>{str2fullformat(values.approved_date)}</div>
                    </Grid>
                    <Grid item md={3} className={classes.gridItem}>
                      <DatePicker
                        label="Fecha de Pago"
                        placeholderText="Seleccione"
                        name="paidout_date"
                        // todayButton="Hoy"
                        popperPlacement="bottom-end"
                        // minDate={new Date(values.created)}
                      />
                    </Grid>
                  </GridContainer>
                  {/* {showComments && (
                    <>
                      <Grid container style={{ margin: '10px -5px' }}>
                        <Grid item md={12}>
                          <Typography
                            color="primary"
                            display="block"
                            variant="body1">
                          AGREGAR COMENTARIOS
                          </Typography>
                          <Divider />
                        </Grid>
                      </Grid>
                      <Grid container style={{ margin: '10px 10px 30px 0' }}>
                        <Grid item md={12}>
                          <FormInput name="comment" multiline />
                        </Grid>
                      </Grid>
                    </>
                  )} */}

                  {!showAlternativeReceiver ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showAlternativeReceiver}
                          onChange={({ target: { checked } }) => setShowAlternativeReceiver(checked)}
                          name="alter_receiver"
                          // color="primary"
                        />
                      }
                      label="RECEPTOR DE PAGO ALTERNATIVO"
                      style={{
                        position: 'relative',
                        top: '-10px',
                        // margin: '-15px -15px'
                      }}
                    />
                  ) : (
                    <>
                      <Grid container style={{ margin: '10px -5px' }}>
                        <Grid item md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={showAlternativeReceiver}
                                onChange={({ target: { checked } }) => setShowAlternativeReceiver(checked)}
                                name="alter_receiver"
                                // color="primary"
                                style={{ margin: '-8px -4px' }}
                              />
                            }
                            label={
                              <Typography color="primary" display="block" variant="body1">
                                RECEPTOR DE PAGO ALTERNATIVO
                              </Typography>
                            }
                            style={{
                              position: 'relative',
                              top: '-5px',
                              margin: '-15px -5px',
                            }}
                          />
                          <Divider />
                        </Grid>
                      </Grid>
                      <GridContainer>
                        <GridItem md={8}>
                          <FormInput placeholder="INGRESE NOMBRE DE RECEPTOR" name="alter_receiver_name" />
                        </GridItem>
                        <GridItem md={4}>
                          <FormInput placeholder="INGRESE DNI DE RECEPTOR" name="alter_receiver_dni" />
                        </GridItem>
                      </GridContainer>
                    </>
                  )}

                  <Grid container style={{ margin: '10px -5px' }}>
                    <Grid item md={12}>
                      <Typography color="primary" display="block" variant="body1">
                        ORDENES DE SERVICIO
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                </>

                <FieldArray
                  name="service_orders"
                  render={arrayHelpers => (
                    <>
                      <GridContainer>
                        <Grid item md={2} style={{ textAlign: 'right' }}>
                          <Typography style={{ lineHeight: '2.5' }}>INGRESE ORDEN :</Typography>
                        </Grid>
                        <GridItem md={3}>
                          <SearchText
                            value={serviceOrderCode}
                            onChange={({ target: { value } }) => setServiceOrderCode(value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            inputProps={{
                              autoComplete: 'off',
                            }}
                            type="search"
                            placeholder="Ej ORD-00000001"
                            // onKeyUp={event => {
                            //   if (event.ctrlKey && event.key === 'Enter') {
                            //     onAdd(arrayHelpers);
                            //   }
                            // }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                onAdd(values, setFieldValue, arrayHelpers);
                                e.preventDefault();
                              }
                            }}
                          />
                        </GridItem>
                        <Grid item md={3} style={{ textAlign: 'left' }}>
                          <Fab
                            size="small"
                            color="secondary"
                            variant="extended"
                            aria-label="add"
                            className={classes.margin}
                            onClick={() => onAdd(values, setFieldValue, arrayHelpers)}
                            disabled={isSubmitting}
                          >
                            <SearchIcon />
                            Agregar
                          </Fab>
                        </Grid>
                      </GridContainer>
                      <ErrorMessage name="service_orders">
                        {msg => <div style={{ color: 'red', marginTop: '.5rem' }}>{msg}</div>}
                      </ErrorMessage>
                      <br />
                      <Table className={classes.table}>
                        <TableBody>
                          {values.service_orders.map((item, i) => (
                            <TableRow key={`task${String(item.id)}`} className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>
                                {ability.can('manage', 'operations') ? (
                                  <Link to={`${goto(MODS.SERVICE_ORDERS)}/${item.id}`}>{item.code}</Link>
                                ) : (
                                  <span>{item.code}</span>
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>{item.service_description}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {item.date_in ? str2format(item.date_in) : '---'}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {`${item.total_cost} ${item.currency}`}
                              </TableCell>

                              <TableCell className={classes.tableActions}>
                                <Tooltip
                                  id="tooltip-top-start"
                                  title="Eliminar"
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="Close"
                                    className={classes.tableActionButton}
                                    onClick={() => {
                                      if (values.service_orders?.length - 1 > 0) {
                                        arrayHelpers.remove(i);
                                      } else {
                                        Swal.fire({
                                          icon: 'info',
                                          title: 'Advertencia',
                                          text: 'Se necesita por lo menos una orden de servicio',
                                          // footer: '<a href>Why do I have this issue?</a>'
                                        });
                                      }
                                    }}
                                  >
                                    <Close className={`${classes.tableActionButtonIcon} ${classes.close}`} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  )}
                />

                <Grid container style={{ margin: '15px -5px' }}>
                  <Grid item md={12}>
                    <Typography color="primary" display="block" variant="body1">
                      COMENTARIOS
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid container style={{ margin: '10px 10px 30px 0' }}>
                  <GridItem md={6}>
                    {/* <Typography>Desde ingreso :</Typography> */}
                    <FormInput label="Desde ingreso :" name="comment" multiline rows={3} />
                  </GridItem>
                  <GridItem md={6}>
                    {/* <Typography>Respuesta :</Typography> */}
                    <FormInput label="Respuesta" name="reply" multiline rows={3} />
                    {/* <small>{values.reply}</small> */}
                  </GridItem>
                </Grid>

                {isSubmitting && <LinearProgress />}
                <Grid
                  container
                  // justify="center"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <GridItem md={3}>
                    {values.service_orders?.length ? (
                      <Button
                        color="primary"
                        // onClick={() => handleSubmit()}
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Guardar Cambios
                      </Button>
                    ) : (
                      <div style={{ color: 'red' }}>El Pago debe tener al menos una orden de servicio</div>
                    )}
                  </GridItem>
                </Grid>
              </Form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
    </YupProvider>
  );
}
