import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  dangerColor,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-react.js';
import TableSelectDate from 'components/TableSelectDate';
import { localization } from 'config';
import { format } from 'date-fns';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';

import JsPDF from 'jspdf';
import 'jspdf-autotable';

import FileSaver from 'file-saver';

import Excel from 'exceljs';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 200,
    },
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  title: {
    margin: '0',
  },
  input: {
    margin: '15px 0',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  tableToolbar: {
    border: '1px solid #EEE',
    backgroundColor: '#EEEEEE',
    '& .Mui-disabled': {
      color: 'transparent',
    },
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },

  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    // ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
    // fontSize: '0.8125rem',
    border: 'none',
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  // table: {
  //   marginBottom: '0',
  //   overflow: 'visible'
  // },
  tableRow: {
    position: 'relative',
    borderTop: `1px solid ${grayColor[5]}`,
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  // tableCell: {
  //   padding: '8px',
  //   verticalAlign: 'middle',
  //   border: 'none',
  //   lineHeight: '1.42857143',
  //   fontSize: '14px'
  // },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },

  toolbar: {
    // '&>.MuiToolbar-root': {
    //   zIndex: '-1',
    // },
    // '& button[aria-label="Mostrar columnas"] span::before': {
    //   content: 'Mostrar columnas',
    //   backgroundColor: '#EEE',
    // },
    // zIndex: '-1',
  },
  gridToolbar: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
      marginTop: '15px',
    },
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));
const tableRef = React.createRef();

export default function Pending() {
  const classes = useStyles();

  const [filters, setFilters] = useState({});

  const handleSearch = (query, getData, filters) =>
    getData({
      // status: 'APPROVED',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
      ...filters,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  useEffect(() => {
    if (Object.keys(filters).length) {
      tableRef.current.onQueryChange({ search: '' }, () => {
        tableRef.current.setState({ searchText: '' });
      });
    }
  }, [filters]);

  function exportData(data) {
    return {
      data: data.map(i => ({
        ...i,
        start_date_format: i.start_date ? format(new Date(i.start_date), 'dd/MM/yyyy hh:mm aa') : '---',
        reservation_client: i.reservation_obj.client,
        language: i.reservation_obj.language,
        pick_up_point: i.reservation_obj.pick_up_point,
        origin: i.reservation_obj.origin,
        supplier_service: i.supplier_service.map(s => `• ${s.supplier}: ${s.service}`).join('\n'),
        specialreqs: i.reservation_obj.specialreqs,
        reservation_observation: i.reservation_obj.observations,
        reservation_code: i.reservation_obj.code,
        created_by:
          `${
            i.created_by_data?.first_name
              ? `${i.created_by_data?.first_name} ${i.created_by_data?.last_name}`
              : i.created_by_data?.username || '---'
          }\n` + `[${i.created_by_data?.groups[0].name}]`,
        created: i.created ? format(new Date(i.created), 'dd/MM/yyyy (HH:mm)') : '---',
      })),
      filename: `Biblia - Resumen diario ${format(new Date(), 'dd-MM-yyyy HH-mm')}`,
    };
  }

  return (
    <Grid container direction="collumn" justifyContent="flex-start">
      <Grid item xs={12} md={4}>
        <TableSelectDate
          init={new Date()}
          onSelect={(st, ed) =>
            setFilters(prev => ({
              ...prev,
              start_date__gte: st,
              start_date__lte: ed,
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          columns={[
            {
              // Web
              title: 'Fecha y Hora de Inicio',
              field: 'start_date',
              render: ({ start_date: startDate }) =>
                startDate ? format(new Date(startDate), 'dd/MM/yyyy hh:mm aa') : '---',
              sorting: true,
              export: false,
              cellStyle: { border: '1px solid #eee' },
              defaultSort: 'asc',
            },
            {
              // Web
              title: 'Tour',
              field: 'package__name',
              render: ({ package_name: packageName }) => packageName || '---',
              sorting: true,
              export: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              // Web
              title: 'Pax Principal',
              field: 'reservation__client__name',
              render: ({ reservation_obj: res }) => <Box>{res.client}</Box>,
              sorting: true,
              export: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Num Pax',
              field: 'num_pax',
              type: 'numeric',
              sorting: false,
              align: 'center',
              cellStyle: { border: '1px solid #eee' },
            },
            {
              // Web
              title: 'Idioma',
              field: 'reservation__language__lang',
              render: ({ reservation_obj: res }) => <Box>{res.language}</Box>,
              sorting: true,
              export: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Punto de Recojo',
              field: 'reservation_obj.pick_up_point',
              sorting: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              // Web
              title: 'Procedencia',
              field: 'reservation__origin__name',
              render: ({ reservation_obj: res }) => <Box>{res.origin}</Box>,
              sorting: true,
              export: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Proveedor - Servicio',
              field: 'supplier_service',
              render: ({ supplier_service: i }) => (
                <Box>
                  {i.map(s => (
                    <li key={s.service}>
                      <strong>{s.supplier}</strong>: {s.service}
                    </li>
                  ))}
                </Box>
              ),
              sorting: false,
              // hiddenByColumnsButton: true,
              // hidden: true,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Observaciones Generales',
              field: 'reservation_obj.observations',
              sorting: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Observación de Tour',
              field: 'observations',
              sorting: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Indicaciones para el cliente',
              field: 'reservation_obj.specialreqs',
              sorting: false,
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Reserva',
              field: 'reservation_obj.code',
              sorting: false,
            },
            // {
            //   title: 'Progreso',
            //   field: 'progress',
            //   lookup: {
            //     NotStarted: 'No iniciado',
            //     OnGoing: 'En progreso',
            //     Finalized: 'Finalizado',
            //   },
            //   hidden: true,
            // },
            {
              title: 'Creado Por',
              field: 'created_by',
              render: ({ created_by_data: userData }) => (
                <Box>
                  <div>
                    {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : userData?.username}
                  </div>
                  <small>{`[${userData?.groups[0].name}] `}</small>
                </Box>
              ),
              emptyValue: '---',
              // sorting: false,
              // hidden: true,
              // hiddenByColumnsButton: hiddenCreate
              cellStyle: { border: '1px solid #eee' },
            },
            {
              title: 'Fecha de creación',
              field: 'created',
              render: ({ created }) => (created ? format(new Date(created), 'dd/MM/yyyy (HH:mm)') : '---'),
              hidden: true,
              hiddenByColumnsButton: true,
              cellStyle: { border: '1px solid #eee' },
            },
          ]}
          tableRef={tableRef}
          data={query => handleSearch(query, API.Book.all, filters)}
          options={{
            // headerStyle: {
            //   backgroundColor: '#1565c0',
            // },
            draggable: false,
            loadingType: 'linear',
            // toolbarButtonAlignment: 'left',
            // searchFieldAlignment: 'left',
            // actionsColumnIndex: -1,
            columnsButton: true,
            exportButton: true,
            actionsCellStyle: {
              // display: 'flex',
              // justifyContent: 'center',
              // padding: '24px',
              // width: '100%',
              // marginBottom: '-1px',
              // textAlign: 'center'
              // border: '1px solid #EEE',
            },
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportPdf: (h, d) => {
              const { data: body, filename } = exportData(d);

              const columns = [
                { header: 'Fecha de Inicio / Hora', dataKey: 'start_date_format' },
                { header: 'Tour', dataKey: 'package_name' },
                { header: 'Pax Principal', dataKey: 'reservation_client' },
                { header: 'Núm Pax', dataKey: 'num_pax' },
                { header: 'Idioma', dataKey: 'language' },
                { header: 'Procedencia', dataKey: 'origin' },
                { header: 'Proveedor - Servicio', dataKey: 'supplier_service' },
                { header: 'Observaciones Generales', dataKey: 'reservation_observation' },
                { header: 'Observación de Tour', dataKey: 'observations' },
                { header: 'Indicaciones para el cliente', dataKey: 'specialreqs' },
                { header: 'Reserva', dataKey: 'reservation_code' },
                // { header: 'Creado Por', dataKey: 'created_by' },
                // { header: 'Fecha de creación', dataKey: 'created' },
              ];

              const doc = new JsPDF({
                orientation: 'l',
                format: 'a4',
              });

              doc.autoTable({
                margin: { top: 8, right: 2, bottom: 8, left: 2 },
                styles: {
                  fontSize: 8,
                },
                body,
                columns,
                theme: 'grid',
                addPageContent(data) {
                  // Header
                  doc.setFontSize(8);
                  doc.setTextColor(160);
                  doc.text('Biblia - Resumen diario', data.settings.margin.left, 5);

                  // Footer
                  doc.setTextColor(180);
                  const str = `página ${doc.internal.getNumberOfPages()}`;
                  const { pageSize } = doc.internal;
                  doc.text(str, pageSize.getWidth() - (data.settings.margin.left + 12), pageSize.getHeight() - 4);
                },
              });

              doc.save(`${filename}.pdf`);
              // window.open(URL.createObjectURL(doc.output('blob')));
            },
            exportCsv: async (h, d) => {
              const { data, filename } = exportData(d);
              const workbook = new Excel.Workbook();

              // const workbook = new Excel.WorkbookWriter({ useSharedStrings: true, useStyles: true });

              const worksheet = workbook.addWorksheet('Biblia', {
                views: [{ state: 'frozen', ySplit: 1 }],
                pageSetup: { fitToPage: true },
              });

              worksheet.columns = [
                { header: 'Fecha de Inicio / Hora', key: 'start_date_format', width: 25 },
                { header: 'Tour', key: 'package_name', width: 50 },
                { header: 'Pax Principal', key: 'reservation_client', width: 50 },
                { header: 'Núm Pax', key: 'num_pax', width: 10 },
                { header: 'Idioma', key: 'language', width: 25 },
                { header: 'Procedencia', key: 'origin', width: 35 },
                {
                  header: 'Proveedor - Servicio',
                  key: 'supplier_service',
                  width: 75,
                },
                { header: 'Observaciones Generales', key: 'reservation_observation', width: 75 },
                { header: 'Observación de Tour', key: 'observations', width: 35 },
                { header: 'Indicaciones para el cliente', key: 'specialreqs', width: 35 },

                { header: 'Reserva', key: 'reservation_code', width: 35 },

                { header: 'Creado Por', key: 'created_by', width: 40 },
                { header: 'Fecha de creación', key: 'created', width: 25 },
              ];

              data.forEach(d => {
                const row = worksheet.addRow(d);
                row.eachCell(r => {
                  r.alignment = { vertical: 'middle', wrapText: true };
                  if (r.col === worksheet.getColumnKey('supplier_service').number) {
                    const calculate = r.value.split('\n').length * 16;
                    row.height = calculate < 32 ? 32 : calculate;
                  }
                });
              });

              worksheet.getRow(1).font = {
                size: 12,
                bold: true,
              };

              worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'e6ee9c' },
              };

              worksheet.getColumnKey('num_pax').alignment = { horizontal: 'center', vertical: 'middle' };

              const excelBuffer = await workbook.xlsx.writeBuffer();
              const save = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
              });

              FileSaver.saveAs(save, `${filename}.xlsx`);
            },
          }}
          components={{
            OverlayLoading: () => <></>,
            Container: props => props.children,
            Toolbar: props => (
              <Grid container className={classes.gridToolbar}>
                <MTableToolbar {...props} />
              </Grid>
            ),
          }}
          localization={localization}
        />
      </Grid>
    </Grid>
  );
}
