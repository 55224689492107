import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorConexion from 'components/ErrorConexion';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Frame from 'layouts/Frame';
import React from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';
import InformationSpace from './Information';
import InOutSpace from './InOut';
import OriginSpace from './Origin';
import PassengersSpace from './Passengers';
import ServicesSpace from './Services';

import TypeSpace from './Type';
import Documents from './Documents';

// Nullable siempre arriba
const schema = Yup.object().shape({
  client: Yup.number().nullable().pk().required('Seleccione un pasajero principal'),
  passengers: Yup.array().of(Yup.number().pk()),
  group: Yup.array().of(Yup.number().pk()).required('Debe ingresar pasajeros'),
  specialreqs: Yup.string().ensure().trim(),
  coming_from: Yup.string().required('Seleccione origen'),
  language: Yup.number().nullable().pk().required('Seleccione un idioma'),
  origin: Yup.number().nullable().pk('Incorrecto').required('Seleccione una procedencia'),
  responsible: Yup.number().nullable().pk().required('Seleccione un responsable'),
  origin_details: Yup.string().ensure().trim(),
  reservation_details: Yup.array()
    .of(
      Yup.object().shape({
        // package: Yup.number()
        //   .pk()
        //   .required('Seleccione un procedencia'),
        service_requests: Yup.array()
          .of(
            Yup.object().shape({
              service: Yup.number().pk().required('Seleccione un servicio'),
              // quantity: Yup.number()
              //   .positive()
              //   .required('Ingrese una cantidad'), // ! Puede ser por el número de pasajeros
              date_in: Yup.date().when('include', {
                is: true,
                then: Yup.date().nullable().format('yyyy-MM-dd').required('Seleccione una Fecha'),
                otherwise: Yup.date().nullable().format('yyyy-MM-dd'),
              }),
              time_in: Yup.date().when('include', {
                is: true,
                then: Yup.date().nullable().format('HH:mm:ss', 'time'), // .required('Seleccione una hora')

                otherwise: Yup.date().nullable().format('HH:mm:ss', 'time'),
              }),
              date_out: Yup.date().when('include', {
                is: true,
                then: Yup.date().nullable().format('yyyy-MM-dd'), // .required('Seleccione una Fecha')
                otherwise: Yup.date().nullable().format('yyyy-MM-dd'),
              }),
              // indications: Yup.string()
              //   .ensure()
              //   .trim(),
            }),
          )
          // })
          .minselected('Debe seleccionar por lo menos un servicio de proveedor')
          .required('Debe agregar por lo menos un servicio de proveedor'),
        start_date: Yup.date().nullable().format("yyyy-MM-dd'T'HH:mm:ss").required('Seleccione una Fecha'),
        comple_date: Yup.date().nullable().format("yyyy-MM-dd'T'HH:mm:ss").required('Seleccione una Fecha'),
        // observations: Yup.string()
        //   .ensure()
        //   .trim(),
        mode: Yup.string().required('Seleccione el modo'),
        price: Yup.string().trim().price('Precio incorrecto').required('Precio requerido'), // '0.00',
        currency: Yup.string().trim().required('Seleccionar moneda'), // 'SOLES',
        // // exchange_rate: '0.00'
        // package_name: item.name
      }),
    )
    .required('Debe agregar por lo menos un Tour'),
  // reservation_details: Yup.array()
  //   .required('Debe agregar por lo menos un Tour'),
  arrival_date: Yup.date().nullable().format('yyyy-MM-dd'),
  departure_date: Yup.date().nullable().format('yyyy-MM-dd'),
  arrival_via: Yup.string().nullable(),
  departure_via: Yup.string().nullable(),
  arrival_detail: Yup.string().ensure().trim(),
  departure_detail: Yup.string().ensure().trim(),
  observations: Yup.string().ensure().trim(),
  pick_up_point: Yup.string().ensure().trim(),
});

const API_CALL = API.Reservation;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  data,
  create,
  children: render,
  isDialog = false,
}) => (
  <Form autoComplete="off" autoCorrect="off">
    {render({
      body: (
        <YupProvider value={schema}>
          {create && (
            <Frame>
              <TypeSpace />
            </Frame>
          )}
          <Frame>
            <PassengersSpace isDialog={isDialog} />
          </Frame>
          <Frame title="PROCEDENCIA">
            <OriginSpace />
          </Frame>
          <Frame title="LLEGADA Y SALIDA">
            <InOutSpace />
          </Frame>
          <Frame title="TOURS">
            <ServicesSpace />
          </Frame>
          <Frame title="INFORMACIÓN GENERAL">
            <InformationSpace />
          </Frame>
          {!create && (
            <Frame title="DOCUMENTOS">
              <Documents />
            </Frame>
          )}

          {isSubmitting && <LinearProgress />}
          <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
        </YupProvider>
      ),
      title: create ? 'Registrar Reserva' : `${data.code}`,
      handleSubmit,
      isSubmitting,
    })}
  </Form>
);

const CustomForm = withFormik({
  // - Schema
  validationSchema: schema,
  validateOnChange: false,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    filetype: data.filetype || 'FILE',
    client: data.client || null,
    passengers: data.passengers || [],
    specialreqs: data.specialreqs || '',
    coming_from: data.coming_from || '',
    language: data.language || null,
    origin: data.origin || null,
    responsible: data.responsible || null,
    origin_details: data.origin_details || '',
    reservation_details: data.reservation_details || [],
    arrival_date: data.arrival_date || null,
    departure_date: data.departure_date || null,
    arrival_via: data.arrival_via || null,
    departure_via: data.departure_via || null,
    arrival_detail: data.arrival_detail || '',
    departure_detail: data.departure_detail || '',
    observations: data.observations || '',
    pick_up_point: data.pick_up_point || '',
    group: [...(data.client ? [data.client] : []), ...(data.passengers?.length ? data.passengers : [])],
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      filetype: values.filetype || 'FILE',
      client: values.client,
      passengers: values.passengers,
      specialreqs: values.specialreqs,
      coming_from: values.coming_from,
      language: values.language,
      origin: values.origin,
      responsible: values.responsible,
      origin_details: values.origin_details,
      reservation_details: values.reservation_details.map(red => ({
        ...red,
        service_requests: red.service_requests.filter(sr => sr.include),
      })),
      arrival_date: values.arrival_date,
      departure_date: values.departure_date,
      arrival_via: values.arrival_via,
      departure_via: values.departure_via,
      arrival_detail: values.arrival_detail,
      departure_detail: values.departure_detail,
      observations: values.observations,
      pick_up_point: values.pick_up_point,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id));

  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default ({ id = null, create = true, ...res }) => {
  if (id) {
    return <UpdateForm id={id} {...res} />;
  }
  if (create) {
    return <CustomForm create {...res} />;
  }
  return <ErrorConexion code={404} />;
};
