import SvgIcon from '@material-ui/core/SvgIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function Services(props) {
  const [supplierTypeData] = useFetchData(API.SupplierType.all);
  const [selSupplierType, setSelSupplierType] = useState(null);
  const [supplierData, setQuerySupplier] = useFetchData(API.Supplier.all, []);
  const [selSupplier, setSelSupplier] = useState(null);
  const [serviceData, setQueryService] = useFetchData(API.Service.all, []);
  const [selService, setSelService] = useState(null);

  const [servicesOptions, setServicesOptions] = useState([]);

  const IconOption = props => (
    <components.Option {...props}>
      <SvgIcon style={{ fontSize: '14px' }}>
        <path d={props.data.icon_svg} />
      </SvgIcon>
      <span style={{ margin: '0 10px' }}>{props.data.type_name}</span>
    </components.Option>
  );

  useEffect(() => {
    setServicesOptions(
      serviceData.map(i => ({
        ...i,
        disabled: !!props?.exclude?.includes(i.id),
      })),
    );
  }, [serviceData]);

  return (
    <GridContainer>
      <GridItem md={4}>
        <Select
          value={supplierTypeData?.find(i => i.id === selSupplierType) || ''}
          onChange={sel => {
            setSelSupplierType(sel.id);
            setQuerySupplier({ get_all: true, supplier_type: sel.id });
            setSelService(null);
            setSelSupplier(null);
          }}
          components={{
            Option: IconOption,
            Input: props => (
              <div name={props.name}>
                <components.Input {...props} />
              </div>
            ),
          }}
          options={supplierTypeData || []}
          placeholder="Seleccione el tipo"
          getOptionLabel={i => i.type_name}
          getOptionValue={i => i.id}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </GridItem>
      <GridItem md={4}>
        <Select
          value={supplierData?.find(i => i.id === selSupplier) || ''}
          onChange={sel => {
            setSelSupplier(sel.id);
            setQueryService({ get_all: true, supplier: sel.id });
            setSelService(null);
          }}
          options={supplierData || []}
          placeholder="Seleccione el proveedor"
          isDisabled={!selSupplierType}
          getOptionLabel={i => i.name}
          getOptionValue={i => i.id}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <Select
          value={serviceData?.find(i => i.id === selService) || ''}
          onChange={sel => {
            props.onSelect(sel.id);
            setSelService(null);
            setSelSupplier(null);
            setSelSupplierType(null);
          }}
          options={servicesOptions}
          placeholder="Seleccione el servicio"
          isDisabled={!selSupplier}
          isOptionDisabled={i => i.disabled}
          getOptionLabel={i => i.description}
          getOptionValue={i => i.id}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </GridItem>
    </GridContainer>
  );
}
