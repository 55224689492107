import { IconButton, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import { ageGroupOptions } from 'config';
import Person from 'Forms/Person';
import DialogForm from 'layouts/DialogForm';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#FFF',
    zIndex: 2
  },
  typography: {
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 3,
    // position: 'relative',
    // bottom: 10,
    '&[x-placement*="bottom"] $arrow': {
      top: 10,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.primary.main} transparent`,
      },
      '&+$popContainer': {
        margin: '12px 0'
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 10,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
      },
      '&+$popContainer': {
        margin: '12px 0'
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 10,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
      },
      '&+$popContainer': {
        margin: '0 12px'
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 10,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
      },
      '&+$popContainer': {
        margin: '0 12px'
      }
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 10,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  button: {
    padding: '5%',
    // margin: '24px 10px 0 -10px',
    borderRadius: '50%',
    border: '1px solid #CCC',
    margin: '0 2px',
    alignSelf: 'flex-end'
  },
  popContainer: {
  }
}));

// const pop = (open, anchor) => (
//   <Popover
//     open={open}
//     anchorEl={anchor}
//     // onClose={handleClose}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'center',
//     }}
//   >
//     <Typography>The content of the Popover.</Typography>
//   </Popover>
// );

// const AgeGroup = ({ source }) => ageGroupOptions[source];

export default function PersonChip({
  // * Props
  id, type, onLoad, popButtons, ...res
}) {
  const classes = useStyles();
  const [data, setQuery, { isLoading }] = useFetchData(() => API.Person.get(id));
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(
    () => setAnchorEl(null), [setAnchorEl]
  );
  const handleOpen = useCallback(
    event => setAnchorEl(event.currentTarget), [setAnchorEl]
  );

  const reload = useCallback(
    () => setQuery({ time: Date.now() }), [setQuery]
  );

  const [openEdit, setOpenEdit] = useState(false);
  const closeEdit = useCallback(
    () => setOpenEdit(false), [setOpenEdit]
  );

  const avatarAge = useCallback(() => {
    if (data) {
      if (ageGroupOptions[data.age_group] === ageGroupOptions.CHILD) {
        return <Avatar>N</Avatar>;
      }
      if (ageGroupOptions[data.age_group] === ageGroupOptions.INFANT) {
        return <Avatar>I</Avatar>;
      }
    }
    return null;
  }, [data]);

  const icon = {};
  const avatar = avatarAge();
  if (avatar) {
    icon.avatar = avatar;
  } else {
    icon.icon = <FaceIcon />;
  }

  useEffect(() => {
    if (data && onLoad) {
      onLoad(data);
    }
  }, [data]);

  return (
    <>
      <DialogForm
        open={openEdit}
        onClose={closeEdit}
        component={Person}
        id={id}
        // onCreate={data => onCreate({ data, close, reload })}
        // onUpdate={data => onUpdate({ data, close, reload })}
        onUpdate={data => {
          reload();
          closeEdit();
          Toast.fire({
            icon: 'success',
            title: `${data.name} actualizado con éxito.`
          });
        }}
        type={type}
      />
      {!isLoading && (
        <Popper
          placement="bottom"
          disablePortal
          open={open}
          anchorEl={anchorEl}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
            // preventOverflow: {
            //   enabled: true,
            //   boundariesElement: 'window',
            // },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          transition
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={50}>
                <div>
                  <span className={classes.arrow} ref={setArrowRef} />
                  <div className={classes.popContainer}>
                    <Box
                      component={Paper}
                      // p={1}
                      bgcolor="#f9fbe7"
                      // border="2px solid #CCC"
                      elevation={3}
                      display="flex"
                      minWidth={175}
                    >
                      <Box flexGrow={1} p={1}>
                        <Box component="small" m={1}>
                          <strong>{data.name.toUpperCase()}</strong>
                        </Box>
                        <Box display="flex">
                          <Box width={1 / 2} display="table">
                            <Box marginX={1}>
                              <small>
                                {data.email}
                              </small>
                            </Box>
                            <Box marginX={1}>
                              <small>
                                {`Tel: ${data.phone}`}
                              </small>
                            </Box>
                          </Box>

                          <Box width={1 / 2}>
                            {/* <Box>
                        <small>
                          {ageGroupOptions[data.age_group]}
                        </small>
                      </Box> */}
                            {/* <Box>{genderOptions[data.gender]}</Box> */}
                            {/* <Box>{data.address}</Box> */}
                            {/* <Box>{data.country}</Box> */}
                            <Box display="inline-flex" flexDirection="column" marginX={1}>
                              {!!data.dni && (
                                <small>{`DNI ${data.dni}`}</small>
                              )}
                              {!!data.ruc && (
                                <small>{`RUC ${data.ruc}`}</small>
                              )}
                              {!!data.passport && (
                                <small>{`DNI ${data.passport}`}</small>
                              )}
                              {!!data.carnet && (
                                <small>{`DNI: ${data.carnet}`}</small>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>

                        <Box p="4px">
                          <Tooltip title="Editar persona">
                            <IconButton
                              size="small"
                              color="primary"
                              aria-label="edit"
                              className={classes.button}
                              onClick={() => {
                                handleClose();
                                setOpenEdit(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        {popButtons?.map(({
                          title, icon, onClick, color
                        }, index) => (
                          <Box p="4px" key={`icon${String(index)}`}>
                            <Tooltip title={title}>
                              <IconButton
                                size="small"
                                color={color}
                                aria-label="assign"
                                className={classes.button}
                                onClick={() => {
                                  onClick({ handleClose });
                                }}
                              >
                                {icon}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </div>
                </div>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
      <Chip
        {
          ...icon
        }
        label={data?.name || 'Cargando...'}
        clickable
        color={open ? 'primary' : 'default'}
        variant="outlined"
        className={classes.chip}
        // size="small"
        onClick={handleOpen}
        {...res}
      />
    </>
  );
}

PersonChip.propTypes = {
  id: PropTypes.number.isRequired
};
