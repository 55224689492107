import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDebouncedCallback } from 'use-debounce';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'visible',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export default function IGVSwitch({ name, label }) {
  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const [defaultValue] = useState(value);
  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 200);

  return (
    <>
      <Typography>{label}</Typography>
      <FormControlLabel
        control={
          <IOSSwitch
            defaultChecked={defaultValue}
            // checked={value}
            onChange={({ target: { checked } }) => debouncedHandleOnChange(checked)}
            disabled={isSubmitting}
          />
        }
        style={{ margin: '0' }}
      />
    </>
  );
}
//   {/* </GridItem>
// </GridContainer> */}
