import SvgIcon from '@material-ui/core/SvgIcon';
import Select from 'components/Select';
import { useFormikContext } from 'formik';
import React from 'react';
import { components } from 'react-select';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

const IconOption = props => (
  <components.Option {...props}>
    <SvgIcon style={{ fontSize: '14px' }}>
      <path d={props.data.icon_svg} />
    </SvgIcon>
    <span style={{ margin: '0 10px' }}>{props.data.type_name}</span>
  </components.Option>
);

export default function SupplierTypeSelect({ name, label, placeholder, isDisabled = false }) {
  const [data] = useFetchData(API.SupplierType.all);

  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Select
        options={data}
        placeholder={placeholder}
        name={name}
        label={label}
        components={{ Option: IconOption }}
        optionLabel="type_name"
        // isDisabled={isSubmitting || (origin === null)}
        isDisabled={isSubmitting || isDisabled}
        // onSelect={sel => onSelect(data.find(i => i.id === sel.id))}
      />
    </>
  );
}
