import React, { useState } from 'react';
import FormInput from 'components/FormInput';
// import Divider from '@material-ui/core/Divider';
// import Typography from '@material-ui/core/Typography';

import { useFormikContext } from 'formik';

import ResponsibleSelect from 'components/ResponsibleSelect';
import OriginSelect from 'components/OriginSelect';
// import Swal from 'sweetalert2';
// import Paper from '@material-ui/core/Paper';
import Toast from 'utils/toast';
import { Grid } from '@material-ui/core';

export default function Origin() {
  const { values } = useFormikContext();

  const [time, setTime] = useState(Date.now);

  return (
    <Grid style={{ padding: '0 10px' }} container direction="column">
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6}>
          <OriginSelect
            label="Seleccione procedencia"
            name="origin"
            placeholder="Seleccione procedencia"
            onCreate={({ close, reload, data }) => {
              Toast.fire({
                icon: 'success',
                title: `${data.name} registrado con éxito.`,
              });
              reload();
              setTime(Date.now);
              close();
            }}
            onUpdate={({ close, reload, data }) => {
              Toast.fire({
                icon: 'success',
                title: `${data.name} actualizado con éxito.`,
              });
              reload();
              setTime(Date.now);
              close();
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ResponsibleSelect
            label="Responsable"
            name="responsible"
            placeholder="Seleccione el responsable"
            origin={values.origin}
            time={time}
          />
        </Grid>
      </Grid>
      <Grid style={{ padding: '10px 0' }} item xs={12}>
        <FormInput label="Detalles de procedencia" name="origin_details" multiline />
      </Grid>
    </Grid>
  );
}
