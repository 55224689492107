import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { genderOptions } from 'config';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { useYupRequired } from 'utils/hooks';

const options = Object.entries(genderOptions).map(([value, label]) => ({ value, label }));

export default function GenderSelect({
  // * Props
  label,
  name,
}) {
  // * "Componente por defecto"
  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();
  const required = useYupRequired(name);

  return (
    <>
      <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      <Select
        options={options}
        value={options?.find(i => i.value === value) || ''}
        onChange={sel => setValue(sel?.value || null)}
        placeholder="Seleccionar"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        isDisabled={isSubmitting}
      />
      <div name={name} />
      <ErrorMessage name={name} />
    </>
  );
}

GenderSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
