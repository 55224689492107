import { Box, Button, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { localization } from 'config';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { str2format } from 'utils/helpers';
import { useLocalStorage } from 'utils/hooks';
import { commonCols } from 'utils/site';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  gridToolbar: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
}));

export default function Pending() {
  const classes = useStyles();

  const history = useHistory();

  const tableRef = React.createRef();
  const ability = useContext(AbilityContext);

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useLocalStorage('user');

  const handleSearch = (query, getData) =>
    getData({
      // status: 'APPROVED',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Editar',
      onClick: (event, rowData) => history.push(`${goto(MODS.SERVICE_ORDERS)}/${rowData.id}`),
    },
    {
      icon: 'get_app',
      tooltip: 'Exportar',
      onClick: (event, rowData) => {
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = API.ServiceOrder.pdf({
          order: rowData.id,
          r: user.id,
        });
        a.click();
      },
    },
  ];

  if (ability.can('delete', 'serviceorders')) {
    actions.push({
      icon: 'cancel_presentation_outlined',
      tooltip: 'Eliminar Orden de Servicio',
      onClick: (e, row) => {
        Swal.fire({
          title: `¿Eliminar ${row.code || 'el registro'}?`,
          text: 'Se eliminará completamente.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: '¡Eliminar!',
          cancelButtonText: 'Mantener.',
        }).then(result => {
          if (result.value) {
            API.ServiceOrder.remove(row.id).then(() => {
              Toast.fire({
                icon: 'info',
                title: 'El registro fue eliminado.',
              });
              tableRef.current.onQueryChange();
            });
          }
        });
      },
    });
  }

  return (
    <Grid container direction="row">
      <Grid item md={10} xs={12} lg={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Código', field: 'code' },
            { title: 'Proveedor', field: 'supplier' },
            // { title: 'Nro Pedidos', field: 'total_quantity' },
            {
              title: 'Servicio',
              field: 'service_description',
              render: i => `${i.total_quantity || '0'} ${i.service_description}`,
            },
            { title: 'Costo Total', field: 'total_cost_format' },
            {
              title: 'Contabilidad',
              field: 'entered_group',
              render: i => (i.entered_group === null ? <Box color="#f44336">Sin procesar</Box> : 'Procesada'),
            },
            {
              title: 'Reservas',
              field: 'service_request',
              render: ({ total_quantity: totalQuantity, service_request: servicesRequest }) => (
                <>
                  {!totalQuantity ? (
                    <div style={{ color: 'brown' }}>Esta orden no tiene RESERVAS</div>
                  ) : (
                    <>
                      {servicesRequest?.map(serv => (
                        <li key={`file${serv.id}`}>
                          {ability.can('manage', 'reservations') ? (
                            <Link
                              to={`${goto(MODS.RESERVATIONS)}/${parseInt(
                                serv.reservation_detail.reservation.id,
                                10,
                              )}/ver`}
                            >
                              {serv.reservation_detail.reservation.code}
                            </Link>
                          ) : (
                            <span>{serv.reservation_detail.reservation.code}</span>
                          )}
                        </li>
                      ))}
                    </>
                  )}
                </>
              ),
            },
            {
              title: 'Fecha de Inicio',
              field: 'date_in',
              render: i => (i.date_in ? str2format(i.date_in) : ''),
              emptyValue: '---',
            },

            ...commonCols(false),
          ]}
          tableRef={tableRef}
          data={query => handleSearch(query, API.ServiceOrder.all)}
          options={{
            headerStyle: {
              // backgroundColor: '#01579b',
              color: '#FFF',
            },
            draggable: false,
            // loadingType: 'linear',
            toolbarButtonAlignment: 'left',
            searchFieldAlignment: 'left',
            // actionsColumnIndex: -1,
            columnsButton: true,
            exportButton: true,
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
          components={{
            // OverlayLoading: () => <></>,
            Container: props => props.children,
            Toolbar: props => (
              <Container>
                <Grid className={classes.gridToolbar} alignItems="center" container direction="row">
                  <Grid item xs={12} md={8}>
                    <MTableToolbar {...props} />
                  </Grid>
                  <Grid item xs={12} md={1} />
                  <Grid item xs={12} md={3}>
                    <Link to={goto(MODS.SERVICE_ORDERS, 'add')}>
                      <Button color="secondary" variant="contained">
                        Nueva Orden de Servicio
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            ),
          }}
          localization={{
            ...localization,
            toolbar: {
              ...localization.toolbar,
              searchPlaceholder: 'Buscar por Codigo o Reserva',
            },
          }}
          actions={actions}
          pageSize={20}
          pageSizeOptions={[5, 10, 20, 50, 100]}
        />
      </Grid>
    </Grid>
  );
}
