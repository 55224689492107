import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorConexion from 'components/ErrorConexion';
import FormInput from 'components/FormInput';
import { Form, withFormik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';
import React from 'react';
import { API } from 'utils/api';
import { FormContainer } from 'utils/helpers';
import { useFetchData } from 'utils/hooks';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Ingrese nombre del Grupo de Tour'),
  description: Yup.string().trim(),
  // .required('Ingrese descripción del Grupo de Tour'),
});

const API_CALL = API.PackageGroup;

const BodyForm = ({
  // * Props
  isSubmitting,
  handleSubmit,
  create,
  children: render,
}) => (
  <Form autoComplete="off" autoCorrect="off">
    {render({
      body: (
        <YupProvider value={validationSchema}>
          <Box>
            <Box marginY={2} marginX={2}>
              <FormInput label="Nombre" name="name" placeholder="Ingrese nombre" />
            </Box>
            <Box marginY={2} marginX={2}>
              <FormInput label="Descripción" name="description" placeholder="Enter para más lineas" multiline />
            </Box>
          </Box>

          {isSubmitting && <LinearProgress />}
          <FormikErrorFocus offset={-100} ease="out-bounce" duration={50} />
        </YupProvider>
      ),
      title: create ? 'Agregar Grupo' : 'Editar Grupo',
      handleSubmit,
      isSubmitting,
    })}
  </Form>
);

const CustomForm = withFormik({
  // - Schema
  validationSchema,

  // - InitialValues
  mapPropsToValues: ({ data = {} }) => ({
    name: data.name || '',
    description: data.description || '',
  }),

  // - Submit
  handleSubmit: async (
    values,
    {
      props: {
        // * Props default
        create = false,
        id = null,
        onCreate = () => {},
        onUpdate = () => {},
      },
    },
  ) => {
    // Submit
    const submit = {
      ...values,
    };

    if (create) {
      // Create
      const { data } = await API_CALL.create(submit);
      onCreate(data);
    } else {
      // Update
      const { data } = await API_CALL.update(id, submit);
      onUpdate(data);
    }
    return true;
  },
})(BodyForm);

const UpdateForm = ({ id, ...res }) => {
  const [data, , options] = useFetchData(() => API_CALL.get(id));
  return (
    <FormContainer options={options}>
      <CustomForm id={id} data={data} {...res} />
    </FormContainer>
  );
};

export default function Payment({
  // * Props
  id = null,
  create = true,
  ...res
}) {
  if (id) {
    return <UpdateForm id={id} {...res} />;
  }
  if (create) {
    return <CustomForm create {...res} />;
  }
  return <ErrorConexion code={404} />;
}

Payment.propTypes = {
  create: PropTypes.bool,
  id: PropTypes.number,
};
