import InputAdornment from '@material-ui/core/InputAdornment';
import FormInput from 'components/FormInput';
import { symbolCurrency } from 'config';
import React from 'react';

const PriceInput = ({
  // * Props
  name, label, currency
}) => (
  <FormInput
    label={label}
    name={name}
    delay={500}
    {
      ...(currency
        ? {
          InputProps: { startAdornment: <InputAdornment position="start">{symbolCurrency[currency]}</InputAdornment>, }
        } : {}
      )
    }
  />
);

export default PriceInput;
