// import { API } from 'utils/api';
import Select from 'components/Select';
import { modeServiceOptions } from 'config';
import React from 'react';

const options = Object.entries(modeServiceOptions).map(([id, name]) => ({ id, name }));

export default function ModeServiceSelect({
  // * Props
  label,
  name,
}) {
  return (
    <>
      <Select options={options} name={name} label={label} />
    </>
  );
}
