import React from 'react';

export default function ErrorConexion({
  error, code = null, componentStack, resetErrorBoundary
}) {
  const status = code || error.response.status;
  // const { response: { status } } = error;
  return {
    404: (
      <div role="alert">
        <p style={{ padding: '15px' }}>La página no existe</p>
      </div>
    )
  }[status] || (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button type="button" onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
