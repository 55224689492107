import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DatePicker from 'components/DatePicker2';
import ErrorMessage from 'components/ErrorMessage';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NumericInput from 'components/NumericInput';
import ServiceSelect from 'components/ServiceSelect';
import SimpleCheckbox from 'components/SimpleCheckbox';
import Viewer from 'components/Viewer';
import { useField } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AbilityContext } from 'utils/Can';
import { goto, MODS } from 'utils/structure';
import API from 'utils/api';
import Swal from 'sweetalert2';

const useStyles = makeStyles(() => ({
  actions: {
    width: '5%',
    padding: '0 !important',
  },
  disablerow: {
    '& td, select, input': {
      backgroundColor: '#CCCCCC !important',
      color: 'grey',
    },
  },
  gridItem: {
    padding: '0 15px',
    margin: '10px 0 20px',
  },
  datepick: {
    width: '120px !important',
    '& .react-datepicker__input-container': {
      width: '100%',
    },
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__input-container input': {
      width: 'calc(100% - 16px)',
      fontSize: '0.8rem',
    },
    '& .react-datepicker__close-icon::after': {
      padding: '0',
    },
  },
  quantityCell: {
    width: '8% !important',
    padding: '3px',
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: 'rgb(98 189 25 / 42%)',
    borderRadius: '50%',
    margin: '0 0 0 10px',
  },
  table: {
    '& tr:nth-child(odd) td': {
      backgroundColor: '#e1f5fe45',
    },
    // '& tr:nth-child(even) td': {
    //   backgroundColor: '#FFFFFF45',
    // },
    '& tr td': {
      borderBottom: '0.5px solid rgb(224, 224, 224)',
    },
  },
  order: {
    // width: '100px !important',
    padding: '0px !important',
    fontSize: '9px !important',
  },
}));

export default function Services(props) {
  // * Clases
  const classes = useStyles();
  const ability = useContext(AbilityContext);

  const { detail, name, addRow, setAddRow } = props;

  const [, { value: requests }, { setValue: setRequests }] = useField(name);

  const [alertOrders, setAlertOrders] = useState(true);

  useEffect(() => {
    const update = !!requests.find(i => !('include' in i));

    async function updatePromise() {
      const servrequests = await Promise.all(
        requests.map(async s => {
          const { data: serv } = await API.Service.get(s.service);

          return 'include' in s
            ? s
            : {
                quantity: 1, // ! Puede ser por el número de pasajeros
                include: true,
                date_in: null,
                // date_in: detail.start_date,
                time_in: null,
                date_out: null,
                indications: '',
                service_description: serv.description,
                supplier_name: serv.supplier_name,
                cost: serv.cost,
                igv: serv.igv,
                currency: serv.currency,
                ...s,
              };
        }),
      );

      setRequests(servrequests);
    }

    if (update) {
      updatePromise();
    }
  }, [requests.length]);

  return (
    <>
      <GridContainer>
        <GridItem md={12}>
          <hr />
          <Table
            className={classes.table}
            size="small"
            // aria-label="a dense table"
            style={{ tableLayout: 'auto' }}
            padding="none"
          >
            <TableBody>
              {requests?.map((itemRequest, index) => (
                <Slide direction="up" in mountOnEnter unmountOnExit key={`itemRequest${String(index)}`}>
                  <TableRow
                    className={
                      !itemRequest?.include || !detail.start_date || !detail.comple_date ? classes.disablerow : null
                    }
                  >
                    <TableCell className={classes.order}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        {itemRequest?.service_order ? (
                          <>
                            <div className={classes.circle} style={{ marginRight: '4px' }} />
                            <Viewer name="ServiceOrder" value={itemRequest.service_order}>
                              {order => (
                                <>
                                  {ability.can('manage', 'operations') ? (
                                    <Link target="_blank" to={`${goto(MODS.SERVICE_ORDERS)}/${parseInt(order.id, 10)}`}>
                                      {order.code}
                                    </Link>
                                  ) : (
                                    <span>{order.code}</span>
                                  )}
                                </>
                              )}
                            </Viewer>
                          </>
                        ) : (
                          <>
                            {!itemRequest.supplier_orders_exclude && (
                              <div
                                className={classes.circle}
                                style={{ marginRight: '4px', backgroundColor: 'tomato' }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.quantityCell}>
                      <div style={{ padding: '8px 0', display: 'flex', flexDirection: 'column' }}>
                        <NumericInput name={`${name}.${index}.quantity`} />
                        <small style={{ marginTop: '-2px' }}>servicios de</small>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          padding: '0px 3px 0px 5%',
                        }}
                      >
                        <div>
                          {itemRequest?.service_description || 'Cargando...'}
                          <br />
                          <small>{itemRequest?.supplier_name || 'Cargando...'}</small>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: '8%',
                        padding: '0 3px',
                      }}
                    >
                      <small>desde</small>
                    </TableCell>
                    <TableCell align="center" className={classes.datepick}>
                      <DatePicker
                        minDate={detail.start_date}
                        placeholderText="Fecha de Entrada"
                        dateFormat="dd/MM/yyyy"
                        name={`${name}.${index}.date_in`}
                        maxDate={detail.comple_date}
                        customInput={null}
                        valueFormat="yyyy-MM-dd"
                        disabled={!detail.start_date || !detail.comple_date}
                        // isClearable={false}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textAlign: 'center',
                        width: '8%',
                        padding: '0 3px',
                      }}
                    >
                      <small>con hora</small>
                    </TableCell>
                    <TableCell align="center" className={classes.datepick}>
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Hora"
                        placeholderText="Seleccione Hora"
                        dateFormat="h:mm aa"
                        name={`${name}.${index}.time_in`}
                        customInput={null}
                        valueFormat="HH:mm:ss"
                        disabled={!detail.start_date || !detail.comple_date}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textAlign: 'center',
                        width: '8%',
                        padding: '0 3px',
                      }}
                    >
                      <small>hasta</small>
                    </TableCell>
                    <TableCell align="center" className={classes.datepick}>
                      <DatePicker
                        placeholderText="Fecha de Salida"
                        dateFormat="dd/MM/yyyy"
                        minDate={itemRequest.date_in}
                        name={`${name}.${index}.date_out`}
                        maxDate={detail.comple_date}
                        customInput={null}
                        valueFormat="yyyy-MM-dd"
                        disabled={!detail.start_date || !detail.comple_date}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.actions}>
                      <SimpleCheckbox
                        name={`${name}.${index}.include`}
                        onChange={checked => {
                          if (!checked) {
                            if (alertOrders) {
                              Swal.fire({
                                icon: 'warning',
                                title: 'Alerta',
                                html:
                                  'Cuando guarde la reserva, se eliminarán todos los servicios desmarcados.<br/>' +
                                  'También se eliminarán dichos servicios de su Orden de Servicio vinculada',
                                showCancelButton: true,
                                // confirmButtonColor: '#d33',
                                // cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Entendido y Continuar',
                                cancelButtonText: 'Cancelar',
                              }).then(result => {
                                if (result.value) {
                                  setAlertOrders(false);
                                } else {
                                  setTimeout(() => {
                                    document.getElementsByName(`${name}.${index}.include`)[0].click();
                                  }, 100);
                                }
                              });
                            }
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </Slide>
              ))}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {addRow && (
          <Grow in>
            <Grid item md={12} style={{ margin: '10px 15px' }}>
              <ServiceSelect
                onSelect={service => {
                  setRequests([...requests, { service }]);
                  setAddRow(false);
                }}
                exclude={requests.map(i => i.service)}
              />
            </Grid>
          </Grow>
        )}
      </GridContainer>
      <ErrorMessage name={name} />
    </>
  );
}
