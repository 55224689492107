import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { useField } from 'formik';
import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useYupRequired } from 'utils/hooks';

export default function RichInput({
  // * Props
  label,
  name,
  delay = 500,
}) {
  // * "Componente por defecto"
  const [, { value }, { setValue }] = useField(name);
  // const { isSubmitting } = useFormikContext();
  const required = useYupRequired(name);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, delay);

  const editor = useRef(null);
  const [content] = useState(value);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      insertImageAsBase64URI: true,
    },
    language: 'es',
    toolbarButtonSize: 'small',
    defaultMode: '1',
  };
  return (
    <>
      <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onChange={newContent => debouncedHandleOnChange(newContent)}
      />
      <ErrorMessage name={name} />
    </>
  );
}
