import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles(() => ({
  checkControl: {
    padding: '0 12px',
    '& .MuiIconButton-edgeStart': {
      margin: 0,
    },
    marginLeft: 0,
  },
}));

const SimpleCheckbox = ({ name, label, onChange = null }) => {
  // * Clases
  const classes = useStyles();
  const [, { value }, { setValue }] = useField(name);
  const [defaultValue, setDefaultValue] = useState(undefined);

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 0);

  useEffect(() => {
    if (value !== undefined && defaultValue === undefined) {
      setDefaultValue(value);
    }
  }, [value]);

  return (
    <>
      <Typography>{label}</Typography>
      {defaultValue !== undefined && (
        <Checkbox
          edge="start"
          defaultChecked={defaultValue}
          tabIndex={-1}
          disableRipple
          // size="small"
          className={classes.checkControl}
          onChange={({ target: { checked } }) => {
            debouncedHandleOnChange(checked);
            if (onChange) onChange(checked);
          }}
          name={name}
        />
      )}
      <ErrorMessage name={name}>
        {msg => (
          <div
            style={{
              color: 'red',
              marginTop: '.5rem',
              margin: '8px 14px 0',
              fontSize: '0.75rem',
              minHeight: '1em',
              textAlign: 'left',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: '400',
              lineHeight: '1em',
            }}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </>
  );
};

export default SimpleCheckbox;
