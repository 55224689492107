import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import WcIcon from '@material-ui/icons/Wc';
import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import Contacts from './Contacts';
import Responsibles from './Responsibles';
import Save from './Save';

export default function ScrollableTabsButtonForce() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);
  const { path } = useRouteMatch();

  useEffect(() => {
    const pat = [];
    let route = ''
    if (ability.can('manage_contacts', MODS.CONTACTS)) {
      route = '/contactos';
      pat.push(
        ...[
          {
            title: 'CONTACTOS DE PROVEEDOR',
            icon: <TransferWithinAStationIcon />,
            route,
            get content() {
              return (
                <Switch>
                  <Route path={`${path + route}/nuevo`}>
                    <Save />
                  </Route>
                  <Route path={`${path + route}/:id`}>
                    <Save />
                  </Route>
                  <Route path={path + route}>
                    <Contacts
                      path={path + route}
                    />
                  </Route>
                </Switch>
              );
            },
          },
        ],
      );
    }
    if (ability.can('manage_responsibles', MODS.CONTACTS)) {
      route = '/responsables';
      pat.push(
        ...[
          {
            title: 'RESPONSABLES DE AGENCIA',
            icon: <WcIcon />,
            route,
            get content() {
              return (
                <Switch>
                  <Route path={`${path + route}/nuevo`}>
                    <Save />
                  </Route>
                  <Route path={`${path + route}/:id`}>
                    <Save />
                  </Route>
                  <Route path={path + route}>
                    <Responsibles path={path + route} />
                  </Route>
                </Switch>
              );
            },
          },
        ],
      );
    }
    if (pat.length) {
      setTabs(pat);
    }
  }, []);

  return <ContentTabs tabs={tabs} defaultIndex={0} />;
}
