import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  headerTitle: {
    margin: '8px 24px 0 24px',
    fontWeight: 'bold',

    color: theme.palette.grey[400],
    textTransform: 'uppercase',
  },
}));
export default function DialogForm({
  open,
  onClose,
  component: Form,
  id,
  onCreate,
  onUpdate,
  createLabel,
  maxWidth = 'xs',
  ...attr
}) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="body"
      fullWidth
      onClose={onClose}
    >
      <Form id={id} onCreate={onCreate} onUpdate={onUpdate} isDialog {...attr}>
        {({ body, title, handleSubmit, isSubmitting }) => (
          <>
            <h4 className={classes.headerTitle}>{title}</h4>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="default"
                // variant="outlined"
              >
                Cancelar
              </Button>
              {!id ? (
                <Button
                  color="primary"
                  variant="contained"
                  // fullWidth
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  // type="submit"
                >
                  {createLabel || 'Agregar'}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  // fullWidth
                  startIcon={<SaveIcon />}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
}

DialogForm.propTypes = {
  component: PropTypes.func,
  id: PropTypes.number,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
};
