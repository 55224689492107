import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { localization } from 'config';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { str2format } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';

const MaterialTableStyled = styled.div`
  tbody > .MuiTableRow-root {
    height: auto !important;
    min-height: 50px;
  }
`;

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        width: 'max-content',
        margin: 'auto',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff',
        '&:hover': {
          color: '#ffebe9',
        },
        '&:focus': {
          color: '#fff',
        },
      },
      active: {
        color: '#fff !important',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 10px !important',
        fontSize: '0.75em !important',
        width: 'max-content !important',
      },
      head: {
        backgroundColor: '#9c27b0 !important',
        color: '#FFF',
      },
      body: {
        minHeight: '50px',
      },
    },
  },
});

export default function Pending() {
  const history = useHistory();

  const handleSearch = (query, getData) =>
    getData({
      entered_group__isnull: true,
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  const ability = useContext(AbilityContext);

  return (
    <Grid
      container
      style={{ marginTop: '15px' }}
      jjustifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Grid item xs={12} xl={8}>
        <ThemeProvider theme={theme}>
          <MaterialTableStyled>
            <MaterialTable
              title="Esperando aprobación"
              columns={[
                {
                  title: 'Orden',
                  field: 'code',
                  render: item =>
                    ability.can('manage', 'operations') ? (
                      <Link to={`${goto(MODS.SERVICE_ORDERS)}/${item.id}`}>{item.code}</Link>
                    ) : (
                      <span>{item.code}</span>
                    ),
                },
                { title: 'Proveedor', field: 'supplier' },
                // { title: 'Nro Pedidos', field: 'total_quantity' },
                {
                  title: 'Nro P. - Servicio',
                  field: 'service_description',
                  render: i => `${i.total_quantity || '0'} ${i.service_description}`,
                },
                {
                  title: 'File / Pax Principal',
                  field: 'service_request',
                  render: ({ total_quantity: totalQuantity, service_request: servicesRequest }) => (
                    <>
                      {!totalQuantity ? (
                        <div style={{ color: 'brown' }}>Esta orden no tiene RESERVAS</div>
                      ) : (
                        <>
                          {servicesRequest?.map(serv => (
                            <li key={`file${serv.id}`}>
                              {ability.can('manage', 'reservations') ? (
                                <Link
                                  to={`${goto(MODS.RESERVATIONS)}/${parseInt(
                                    serv.reservation_detail.reservation.id,
                                    10,
                                  )}/ver`}
                                >
                                  {/* {serv.reservation_detail.reservation.code} */}
                                  {serv.reservation_detail.reservation.code} (
                                  {serv.reservation_detail.reservation.client.first_name}{' '}
                                  {serv.reservation_detail.reservation.client.last_name})
                                </Link>
                              ) : (
                                <span>
                                  {serv.reservation_detail.reservation.code} (
                                  {serv.reservation_detail.reservation.client.first_name}{' '}
                                  {serv.reservation_detail.reservation.client.last_name})
                                </span>
                              )}
                            </li>
                          ))}
                        </>
                      )}
                    </>
                  ),
                },
                { title: 'Costo Total', field: 'total_cost_format' },
                {
                  title: 'Fecha servicio',
                  field: 'date_in',
                  render: i => (i.date_in ? str2format(i.date_in) : '---'),
                  emptyValue: '---',
                },
              ]}
              data={query => handleSearch(query, API.ServiceOrder.all)}
              options={{
                headerStyle: {
                  color: '#FFF',
                },
                draggable: false,
                loadingType: 'linear',
                pageSize: 20,
                paginationType: 'stepped',
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              components={{
                Toolbar: () => <></>,
                OverlayLoading: () => <></>,
                Container: props => props.children,
              }}
              localization={localization}
              actions={[
                // rowData => ({
                //   icon: 'delete',
                //   tooltip: 'Delete User',
                //   onClick: (event, rowData) => console.log(`You want to delete ${rowData.code}`),
                //   disabled: rowData.birthYear < 2000
                // }),
                // eslint-disable-next-line no-unused-vars
                rowData => ({
                  icon: 'visibility',
                  tooltip: 'Revisar',
                  onClick: (event, rowData) => history.push(`${goto(MODS.SERVICE_ORDERS)}/${rowData.id}`),
                }),
              ]}
              // options={{
              //   actionsColumnIndex: -1
              // }}
            />
          </MaterialTableStyled>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
