import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';
import { ROLE } from 'utils/site';
import { MODS } from 'utils/structure';

// eslint-disable-next-line no-unused-vars
export default defineAbility((can, cannot) => {
  can('view', 'login');
});

function updateAbility(ability, user) {
  const { can, rules, cannot } = new AbilityBuilder();

  // read, create, update, delete = manage

  if (user.groups.find(i => i.id === ROLE.ADMIN)) {
    // * Administración
    can('manage', 'all');
    cannot('delete', 'reservation');
    // cannot('delete', MODS.SERVICE_ORDERS);
  } else if (user.groups.find(i => i.id === ROLE.ACCOUNTING)) {
    // * Contabilidad
    can('manage', 'accounting'); // Tabs superiores
    can('create', MODS.SERVICE_ORDERS_PAYMENTS);
    can('pay', MODS.SERVICE_ORDERS_PAYMENTS);
    can('view_archive', MODS.SERVICE_ORDERS_PAYMENTS);
    // can('create', MODS.SERVICE_ORDERS_PAYMENTS);
    // can('create', MODS.SERVICE_ORDERS_PAYMENTS);
    cannot('delete', 'reservation');
    cannot('delete', MODS.SERVICE_ORDERS);
  } else if (user.groups.find(i => i.id === ROLE.COUNTER)) {
    // * Recepción
    can('manage', 'counter');
    can('manage', MODS.RESERVATIONS);
    can('manage', MODS.PASSENGERS);
    can('manage', MODS.BOOK);
    can('manage', 'app');
    // can('approve', 'reservation_button_approve');
    cannot('delete', 'reservation');
    cannot('delete', MODS.SERVICE_ORDERS);
  } else if (user.groups.find(i => i.id === ROLE.SUPER)) {
    // * Operaciones
    can('manage', MODS.CONTACTS);
    can('manage', 'operations');
    can('manage', 'supplier_types');
    can('manage', 'suppliers');
    can('manage', 'services');
    can('manage', 'app');
    cannot('delete', 'reservation');
    cannot('delete', MODS.SERVICE_ORDERS);
  } else if (user.groups.find(i => i.id === ROLE.SUPER_COUNTER)) {
    // * Counter + Operaciones

    can('manage', 'counter');
    can('manage', 'suppliers');
    can('manage', MODS.RESERVATIONS);
    can('manage', MODS.PASSENGERS);
    can('manage', MODS.BOOK);
    cannot('delete', 'reservation');

    can('manage', MODS.CONTACTS);
    can('manage', 'operations');
    can('manage', 'supplier_types');
    can('manage', 'suppliers');
    can('manage', 'services');
    can('manage', 'app');
    cannot('delete', MODS.SERVICE_ORDERS);

    can('approve', 'reservation_button_approve');
  } else if (user.groups.find(i => i.id === ROLE.OPERATION_COUNTER)) {
    can('manage', MODS.CONTACTS);
    can('manage', 'operations');
    can('manage', 'supplier_types');
    can('manage', 'suppliers');
    can('manage', 'services');
    // can('manage', 'app');
    cannot('delete', 'reservation');
    cannot('delete', MODS.SERVICE_ORDERS);

    can('manage', 'accounting'); // Tabs superiores
    can('create', MODS.SERVICE_ORDERS_PAYMENTS);
    can('pay', MODS.SERVICE_ORDERS_PAYMENTS);
    can('view_archive', MODS.SERVICE_ORDERS_PAYMENTS);
  }
  ability.update(rules);
}
export { updateAbility };
export { defineAbilityFor };

// ! Nueva forma para probar
function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (user.isAdmin) {
    can('manage', 'all'); // read-write access to everything
  } else {
    can('read', 'all'); // read-only access to everything
  }

  cannot('delete', 'Post', { published: true });

  return build();
}
