import { Box, IconButton } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Poppers from '@material-ui/core/Popper';
import Person from '@material-ui/icons/Person';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'utils/api';
import { useLocalStorage } from 'utils/hooks';
import { linkTo } from 'utils/structure';

export default function AdminNavbarLinks() {
  // const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const [user] = useLocalStorage('user');

  const history = useHistory();

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  return (
    <Box display="flex" textAlign="right">
      <Box component="span" alignSelf="center">
        {user.username}
      </Box>
      <Box>
        <IconButton style={{ color: '#FFF' }} onClick={handleClickProfile}>
          <Person />
        </IconButton>
        <Poppers open={Boolean(openProfile)} anchorEl={openProfile} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={() => {
                        history.push(linkTo('profile'));
                        handleCloseProfile();
                      }}
                    >
                      Perfil
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={() => {
                        API.authDestroy();
                        history.push('/login');
                        handleCloseProfile();
                      }}
                    >
                      Salir
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </Box>
    </Box>
  );
}
