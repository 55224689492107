import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import CustomFields from './item_tabs/custom_fields';
import ServicesTable from './item_tabs/services_table';
import SupplierTab from './item_tabs/supplier_form';

export default function Edit() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    const tabs = [];
    if (ability.can('manage', MODS.SUPPLIERS)) {
      tabs.push({
        title: 'EDITAR PROVEEDOR',
        icon: <EditTwoToneIcon />,
        route: '',
        content: <SupplierTab />,
      });
      tabs.push({
        title: 'Información Adicional',
        icon: <AssignmentTwoToneIcon />,
        route: '/atributos',
        content: <CustomFields />,
      });
      tabs.push({
        title: 'SERVICIOS PROVEEDOR',
        icon: <RoomServiceIcon />,
        route: '/servicios',
        content: <ServicesTable />,
      });
    }
    setTabs(tabs);
  }, []);

  return <ContentTabs tabs={tabs} bgcolor="none" />;
}
