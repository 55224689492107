import Swal from 'sweetalert2';
// import '@sweetalert2/theme-borderless';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
  // padding: '0.6rem 4rem',
  // background: 'linear-gradient(90deg, #000000, #eeeeee)'
  // background: 'rgba(54, 70, 93, 0.99)'
});

export default Toast;
