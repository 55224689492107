import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { localization } from 'config';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { commonCols } from 'utils/site';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  gridToolbar: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
}));
const tableRef = React.createRef();

export default function Pending(props) {
  const classes = useStyles();

  const history = useHistory();

  const ability = useContext(AbilityContext);

  const { path } = props;

  const handleSearch = (query, getData) =>
    getData({
      // status: 'APPROVED',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <Grid container justifyContent="space-between" alignItems="center" direction="column">
      <Grid item xs={12}>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Codigo',
              field: 'code',
            },
            { title: 'Nombres', field: 'first_name' },
            { title: 'Apellidos', field: 'last_name' },
            {
              title: 'Documentos',
              field: 'documents',
              render: clientData => (
                <Box>
                  {clientData?.dni && (
                    <div>
                      <small>DNI</small> {clientData.dni}
                    </div>
                  )}
                  {clientData?.ruc && (
                    <div>
                      <small>RUC</small> {clientData.ruc}
                    </div>
                  )}
                  {clientData?.passport && (
                    <div>
                      <small>Pasaporte</small> {clientData.passport}
                    </div>
                  )}
                  {clientData?.carnet && (
                    <div>
                      <small>Carnet</small> {clientData.carnet}
                    </div>
                  )}
                </Box>
              ),
              sorting: false,
              export: false,
            },
            { title: 'Nacionalidad', field: 'country_name' },
            {
              title: 'Email',
              field: 'email',
              hidden: true,
              hiddenByColumnsButton: true,
            },
            { title: 'Teléfono', field: 'phone' },
            {
              title: 'Última reserva',
              field: 'reservations',
              render: i => <Link to={`${goto(MODS.RESERVATIONS)}/${i.reservations[0]}`}>{i.reservations_code[0]}</Link>,
            },
            ...commonCols(false),
          ]}
          tableRef={tableRef}
          data={query => handleSearch(query, API.Client.all)}
          options={{
            headerStyle: {
              color: '#FFF',
            },
            draggable: false,
            loadingType: 'linear',
            toolbarButtonAlignment: 'left',
            searchFieldAlignment: 'left',
            // actionsColumnIndex: -1,
            columnsButton: true,
            exportButton: true,
            paginationType: 'stepped',
            actionsCellStyle: {
              padding: '0',
            },
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
          components={{
            // OverlayLoading: () => <></>,
            Container: props => props.children,
            Toolbar: props => (
              <Grid className={classes.gridToolbar} alignItems="center" container direction="row">
                <Grid item xs={12} md={5}>
                  <MTableToolbar {...props} />
                </Grid>
                <Grid item xs={12} md={5} />
                <Grid item xs={12} md={2}>
                  <Link to={`${path}/nuevo`}>
                    <Button color="secondary" variant="contained">
                      Agregar Pax Principal
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            ),
          }}
          localization={localization}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (event, rowData) => history.push(`${path}/${rowData.id}`),
            },
            ability.can('delete', 'passengers')
              ? {
                  icon: 'cancel_presentation_outlined',
                  tooltip: 'Eliminar Registro',
                  onClick: (e, row) => {
                    Swal.fire({
                      title: `¿Eliminar ${row.code || 'el registro'}?`,
                      text: 'Se eliminará completamente.',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#3085d6',
                      confirmButtonText: '¡Eliminar!',
                      cancelButtonText: 'Mantener.',
                    }).then(result => {
                      if (result.value) {
                        API.Client.remove(row.id).then(() => {
                          Toast.fire({
                            icon: 'info',
                            title: 'El registro fue eliminado.',
                          });
                          tableRef.current.onQueryChange();
                        });
                      }
                    });
                  },
                }
              : null,
          ]}
          pageSize={20}
          pageSizeOptions={[5, 10, 20, 50, 100]}
        />
      </Grid>
    </Grid>
  );
}
