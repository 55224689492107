import { LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import avatar from 'assets/img/logosky.svg';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import FormInput from 'components/FormInput';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { API } from 'utils/api';
import { useFetchData, useLocalStorage } from 'utils/hooks';
import Toast from 'utils/toast';
import Yup from 'utils/Yup';
import { YupProvider } from 'utils/YupContext';

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Ingrese un email correcto').required('Ingrese un email'),
  first_name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese sus nombres'),
  last_name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese sus apellidos'),
  username: Yup.string().min(4, 'Demasiado corto.').max(50, 'Máximo permitido').required('Ingrese un usuario'),
});

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useLocalStorage('user');

  const [userData, setQueryUserData] = useFetchData(() => API.User.get(user.id), null);

  useEffect(() => {
    if (user.id) {
      setQueryUserData();
    }
  }, [user]);

  if (!userData) {
    return <div>Cargando...</div>;
  }

  const handleSubmit = (values, actions) => {
    API.User.update(userData.id, { ...values })
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Actualizado',
        });
        actions.setSubmitting(false);
      })
      .catch(() => {
        Toast.fire({
          icon: 'error',
          title: 'Ocurrió un error',
        });
        actions.setSubmitting(false);
      });
  };

  return (
    <YupProvider value={validationSchema}>
      <Formik
        initialValues={{
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          // password: '', // ! faltaria password
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, submitForm }) => (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Editar Perfil</h4>
                  </CardHeader>
                  <CardBody>
                    <Form autoComplete="off">
                      <GridContainer>
                        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                          <FormInput label="Nombres" name="first_name" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                          <FormInput label="Apellidos" name="last_name" />
                        </Grid>
                      </GridContainer>
                      <GridContainer>
                        <Grid item xs={12} sm={12} md={5} className={classes.gridItem}>
                          <FormInput label="Usuario" name="username" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} className={classes.gridItem}>
                          <FormInput label="Email" name="email" />
                        </Grid>
                      </GridContainer>
                    </Form>
                  </CardBody>
                  {isSubmitting && <LinearProgress />}
                  <CardFooter>
                    <Button disabled={isSubmitting} onClick={submitForm} color="primary">
                      Guardar Perfil
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card profile>
                  <CardAvatar profile>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img src={avatar} alt="..." />
                    </a>
                  </CardAvatar>
                  <CardBody profile>
                    <h6 className={classes.cardCategory}>{`${values.first_name} ${values.last_name}`}</h6>
                    <h4 className={classes.cardTitle}>{userData.groups[0].name}</h4>
                    <p className={classes.description}>{`Usuario: ${values.username}`}</p>
                    <p className={classes.description}>{`Email: ${values.email}`}</p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </Formik>
    </YupProvider>
  );
}
