import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Select from 'components/Select';
import { useField } from 'formik';
import Form from 'Forms/Origin';
import DialogForm from 'layouts/DialogForm';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

const useStyles = makeStyles(theme => ({
  button: {
    padding: '5%',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey[500]}`,
    margin: '0 2px',
    alignSelf: 'center',
    '&[disabled]': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

export default function OriginSelect({
  // * Props
  label,
  name,
  placeholder,
  onCreate,
  onUpdate,
}) {
  // * "Componente por defecto"
  const classes = useStyles();
  const [originData, setQueryData] = useFetchData(API.Origin.all);

  const [, { value }, { setValue }] = useField(name);

  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), []);
  const reload = useCallback(() => setQueryData({ get_all: true, time: Date.now() }), [setQueryData]);

  return (
    <>
      <DialogForm
        open={open}
        onClose={close}
        component={Form}
        id={value}
        onCreate={data => onCreate({ data, close, reload })}
        onUpdate={data => onUpdate({ data, close, reload })}
      />
      <Grid container alignItems="flex-end">
        <Grid item xs={8}>
          <Select options={originData} placeholder={placeholder} name={name} label={label} />
        </Grid>
        <Grid item xs={4}>
          <IconButton
            size="small"
            color="default"
            aria-label="edit"
            className={classes.button}
            onClick={() => setOpen(true)}
            disabled={!value}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            color="default"
            aria-label="add"
            className={classes.button}
            onClick={() => setValue(null).then(() => setOpen(true))}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

OriginSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
};
