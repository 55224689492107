import { Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { localization } from 'config';
import { format } from 'date-fns';
import Form from 'Forms/Supplier/SupplierType';
import DialogForm from 'layouts/DialogForm';
import MaterialTable from 'material-table';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    marginTop: theme.spacing(2),
  },
}));

const tableRef = React.createRef();

export default function SupplierType(props) {
  const classes = useStyles();
  const { id } = useParams();

  const [openPayment, setOpenPayment] = useState(null);

  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  const ability = useContext(AbilityContext);

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const handleSearch = ({ pageSize, page, search, orderDirection, orderBy }) =>
    API.SupplierType.all({
      supplier: id,
      per_page: pageSize,
      page: page + 1,
      search,
      ordering: `${orderDirection === 'desc' ? '-' : ''}` + `${orderBy === undefined ? '' : orderBy.field}`,
    }).then(({ data: { result, current, count } }) => ({
      data: result,
      page: current - 1,
      totalCount: count,
    }));

  return (
    <>
      <Box>
        <DialogForm
          open={open}
          onClose={close}
          component={Form}
          id={openPayment}
          onCreate={data => {
            Toast.fire({
              icon: 'success',
              title: `"${data.type_name}" registrado con éxito.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          onUpdate={data => {
            Toast.fire({
              icon: 'success',
              title: `${data.type_name} guardado.`,
            });
            tableRef.current.onQueryChange();
            close();
          }}
          createLabel="Aceptar"
        />
        <MaterialTable
          columns={[
            { title: 'Nombre', field: 'type_name' },
            // { title: 'Costo Unitario', field: 'cost' },
            // { title: 'Moneda', field: 'currency' },
            // {
            //   title: 'Incluye IGV',
            //   field: 'igv',
            //   render: ({ igv }) => <div>{!igv ? 'Sin IGV' : 'Con IGV'}</div>
            // },
            {
              title: 'Creado',
              field: 'created',
              render: ({ created }) => format(new Date(created), 'dd/MM/yyyy (HH:mm)'),
            },

            // {
            //   title: (
            //     <div
            //       style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            //       <span style={{ padding: 0, margin: '0 0 -15px 0' }}>
            //         Visibilidad en APP
            //       </span>
            //       <small>
            //         Servicio | Proveedor | Contacto
            //       </small>
            //     </div>
            //   ),
            //   field: 'show',
            //   render: ({ show, show_contact: showContact, show_supplier: showSupplier }) => (
            //     <div>
            //       {show
            //         ? (
            //           <CheckCircleIcon
            //             style={{ color: '#8bc34a', padding: '0 8px' }} />
            //         ) : (
            //           <NotInterestedIcon
            //             style={{ color: 'gray', padding: '0 8px' }} />
            //         )}
            //       {showSupplier
            //         ? (
            //           <CheckCircleIcon
            //             style={{ color: '#8bc34a', padding: '0 8px' }} />
            //         ) : (
            //           <NotInterestedIcon
            //             style={{ color: '#9e9e9e', padding: '0 8px' }} />
            //         )}
            //       {showContact
            //         ? (
            //           <CheckCircleIcon
            //             style={{ color: '#8bc34a', padding: '0 8px' }} />
            //         ) : (
            //           <NotInterestedIcon
            //             style={{ color: '#9e9e9e', padding: '0 8px' }} />
            //         )}
            //     </div>
            //   )
            // },
          ]}
          data={handleSearch}
          options={{
            headerStyle: {
              backgroundColor: '#009688',
              color: '#FFF',
            },
            draggable: false,
            // loadingType: 'linear',
            // actionsColumnIndex: -1,
            pageSize: 500,
            toolbar: false,
            loadingType: 'linear',
            paging: false,
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: 'Sin Tipos',
            },
          }}
          actions={
            !props.readonly
              ? [
                  rowData => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {
                      setOpen(() => {
                        setOpenPayment(rowData.id);
                        return true;
                      });
                    },
                  }),
                  ability.can('delete', 'supplier_types')
                    ? {
                        icon: 'cancel_presentation_outlined',
                        tooltip: 'Eliminar Tipo',
                        onClick: (e, row) => {
                          Swal.fire({
                            title: '¿Eliminar el tipo?',
                            text: 'Se eliminará completamente.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#3085d6',
                            confirmButtonText: '¡Eliminar!',
                            cancelButtonText: 'Mantener.',
                          }).then(result => {
                            if (result.value) {
                              API.Service.remove(row.id).then(() => {
                                Toast.fire({
                                  icon: 'info',
                                  title: 'El tipo fue eliminado.',
                                });
                                tableRef.current.onQueryChange();
                              });
                            }
                          });
                        },
                      }
                    : null,
                ]
              : []
          }
          tableRef={tableRef}
        />
      </Box>
      <Box paddingY={1}>
        {/* <Paper
          square
          elevation={3}
          style={{
            // backgroundColor: '#CDDC39',
            fontWeight: 'bold',
            marginTop: '5px'
          }}
        >
        </Paper> */}
      </Box>
      {!props.readonly && (
        <Box>
          <Zoom
            in
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <Fab
              variant="extended"
              aria-label="add"
              onClick={() =>
                setOpen(() => {
                  setOpenPayment(null);
                  return true;
                })
              }
              size="small"
              className={classes.fab}
            >
              <NoteAddIcon className={classes.extendedIcon} />
              Agregar otro tipo
            </Fab>
          </Zoom>
        </Box>
      )}
    </>
  );
}
