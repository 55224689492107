import Select from 'components/Select';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default ({
  // * Props
  label, name, placeholder, origin, time = Date.now
}) => {
  // * "Componente por defecto"

  const [data, setQuery] = useFetchData(API.Responsible.all, []);
  const [, , { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();

  const first = useRef(true);

  useEffect(() => {
    // Para que se haga null a partir del segund iteración
    first.current = first.current ? false : !!setValue(null);

    if (origin !== null) {
      setQuery({ get_all: true, origin__id: origin, time });
    }
  }, [origin, time]);

  return (
    <>
      <Select
        options={data}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={isSubmitting || (origin === null)}
      />
    </>
  );
};
