import React from 'react';
import { ErrorMessage } from 'formik';

const Error = props => (
  <ErrorMessage name={props.name}>
    {msg => {
      if (typeof msg !== 'string') return null;
      return (
        <div style={{
          color: 'red',
          marginTop: '.5rem',
          margin: '8px 14px 0',
          fontSize: '0.75rem',
          minHeight: '1em',
          textAlign: 'left',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: '400',
          lineHeight: '1em',
        }}>
          {msg}
        </div>
      );
    }}
  </ErrorMessage>
);

export default Error;
