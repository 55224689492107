import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Payments from './Payments/Index';
import Stepper from './Stepper';
import View from './View';
import ServiceOrders from './ServiceOrders/Index';

export default function Edit() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    const tabs = [];
    if (ability.can('manage', MODS.RESERVATIONS)) {
      tabs.push(
        {
          title: 'EDITAR RESERVA',
          icon: <EditTwoToneIcon />,
          route: '',
          content: <Stepper />
        }
      );
      tabs.push({
        title: 'PAGOS DE RESERVA',
        icon: <CreditCardTwoToneIcon />,
        route: '/pagos',
        content: <Payments />
      },);
      tabs.push({
        title: 'VER RESUMEN',
        icon: <AssignmentTwoToneIcon />,
        route: '/ver',
        content: <View />
      },);
    }
    if (ability.can('view', 'service_orders')) {
      tabs.push({
        title: 'ORDENES / BALANCE',
        icon: <BusinessCenterIcon />,
        route: '/ordenes',
        content: <ServiceOrders />
      });
    }
    setTabs(tabs);
  }, []);

  return (
    <ContentTabs tabs={tabs} bgcolor="none" />
  );
}
