import Select from 'components/Select';
import { voucherToOptions } from 'config';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

const componentOptions = Object.entries(voucherToOptions).map(
  ([id, name]) => ({ id, name })
);

export default function PaymentForSelect({
  // * Props
  name, label, reservation, onSelect
}) {
  const [reservationData] = useFetchData(() => API.Reservation.get(reservation));
  const [, { value }, { setValue }] = useField(name);
  const [options, setOptions] = useState([
    {
      label: 'PAX PRINCIPAL',
      options: []
    },
    {
      label: 'ACOMPAÑANTES',
      options: []
    },
    {
      label: 'OTROS',
      options: [{
        to: 'ENTITY',
        select: '',
        name: 'ENTIDAD',
        id: 'ENTITY'
      }, {
        to: 'NEW',
        select: '',
        name: 'OTRA PERSONA',
        id: 'NEW'
      }]
    },
  ]);

  useEffect(() => {
    if (reservationData?.client) {
      API.Client.get(reservationData.client).then(({ data }) => {
        setOptions(prev => prev.map((section, index) => {
          if (index === 0) {
            return ({
              ...section,
              options: [{
                to: 'CLIENT',
                select: `${data.first_name} ${data.last_name}`,
                name: `${data.first_name} ${data.last_name}`,
                id: data.id,
              }]
            });
          }
          return section;
        }));
      });
    }
    if (reservationData?.passengers.length) {
      Promise.all(reservationData.passengers.map(p => API.Person.get(p)))
        .then(passengers => {
          setOptions(prev => prev.map((section, index) => {
            if (index === 1) {
              return ({
                ...section,
                options: passengers.map(({ data }) => ({
                  to: 'PASSENGER',
                  select: `${data.first_name} ${data.last_name}`,
                  name: `${data.first_name} ${data.last_name}`,
                  id: data.id
                }))
              });
            }
            return section;
          }));
        });
    }
  }, [reservationData]);

  return (
    <Select
      options={options}
      placeholder="Seleccionar tipo"
      name={name}
      label={label}
      onChange={sel => {
        setValue(componentOptions.find(i => i.id === sel?.to)?.id || '');
        if (onSelect) onSelect(sel.select);
      }}
      value={componentOptions.find(i => i.id === value)}
    />
  );
}

PaymentForSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  reservation: PropTypes.number.isRequired
};
