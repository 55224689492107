import React, { useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { symbolCurrency } from 'config';
import { useDebouncedCallback } from 'use-debounce';
// import { API } from 'utils/api';

export default function CostUpdate({ defaultValue, currency, setValRequest }) {
  const pat = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
  const [value, setValue] = useState(parseFloat(defaultValue).toFixed(2));
  const [er, setEr] = useState(false);
  // Debounce callback
  const [debouncedHandleOnChange] = useDebouncedCallback(
    // function
    val => {
      setValue(val);
      // API.ServiceRequest.update(serviceRequest, { cost: val });
      setValRequest(val);
    },
    // delay in ms
    500,
  );

  const handleChange = val => {
    if (pat.test(val)) {
      setEr(false);
      debouncedHandleOnChange(val);
    } else {
      setEr(true);
    }
  };

  return (
    <Box display="flex" alignItems="baseline">
      {symbolCurrency[currency]}
      <TextField
        defaultValue={value}
        onChange={({ target: { value } }) => handleChange(value)}
        style={{ margin: '0 10px 10px', width: '100px' }}
        helperText={er ? 'Debe ser numerico y sólo 2 decimales' : ''}
        error={er}
      />
    </Box>
  );
}
