import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';

export default function Viewer({
  // * Props
  name, value, children, loading = '...'
}) {
  const [data] = useFetchData(() => API[name].get(value));

  if (!data) {
    return loading;
  }
  return children(data);
}
