import { Grid } from '@material-ui/core';
import PeoplePicker from 'components/PeoplePicker';
import { ageGroupOptions, PERSON_TYPE } from 'config';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

export default function Passengers(props) {
  const { values, setFieldValue } = useFormikContext();
  const [groupData, setGroupData] = useState([]);
  const [countLabel, setCountLabel] = useState('');

  useEffect(() => {
    if (values.group.length < groupData.length) {
      const add = values.group.map(pk => groupData.find(i => i.id === pk));
      setGroupData(add);
    }
  }, [values.group]);

  useEffect(() => {
    const adultCount = groupData.reduce(
      (a, i) => a + (ageGroupOptions[i.age_group] === ageGroupOptions.ADULT ? 1 : 0),
      0,
    );
    const childCount = groupData.reduce(
      (a, i) => a + (ageGroupOptions[i.age_group] === ageGroupOptions.CHILD ? 1 : 0),
      0,
    );
    const infantCount = groupData.reduce(
      (a, i) => a + (ageGroupOptions[i.age_group] === ageGroupOptions.INFANT ? 1 : 0),
      0,
    );
    setCountLabel(
      adultCount + (childCount > 0 ? ` + ${childCount}n` : '') + (infantCount > 0 ? ` + ${infantCount}i` : ''),
    );
  }, [groupData]);

  useEffect(() => {
    if (!values.client && values.group.length >= 1) {
      setFieldValue('client', values.group[0]);
    }

    setFieldValue(
      'passengers',
      values.group.filter(i => i !== values.client),
    );
  }, [values.group, values.client]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PeoplePicker
          label="Pasajeros"
          name="group"
          isDialog={props.isDialog}
          type={PERSON_TYPE.PASSENGER}
          placeholder="Buscar personas..."
          onLoadChip={data => {
            if (!groupData.find(i => i.id === data.id)) {
              setGroupData(prev => [...prev, data]);
            } else {
              setGroupData(prev => prev.map(i => (data.id === i.id ? data : i)));
            }
          }}
          highlight={values.client}
          onHigh={({ handleClose, person }) => {
            setFieldValue('client', person);
            handleClose();
          }}
          onDelete={({ remove, person }) => {
            remove();
            if (person === values.client) {
              setFieldValue('client', null);
            }
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <strong>{`(${countLabel})`}</strong>
        <small>{` Total de ${values.group.length} pasajeros`}</small>
      </Grid>
    </Grid>
  );
}
