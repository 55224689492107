import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FormInput from 'components/FormInput';
import GridItem from 'components/Grid/GridItem';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import Toast from 'utils/toast';
import { YupProvider } from 'utils/YupContext';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().min(3, 'Demasiado corto.').max(200, 'Máximo permitido').required('Ingrese un nombre'),
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: 10,
    marginTop: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Add() {
  const classes = useStyles();
  const [vouchersData, setQueryVouchersData] = useFetchData(API.PaymentVoucher.all, []);
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    setQueryVouchersData({ get_all: true, ordering: 'id' });
  }, []);

  useEffect(() => {
    if (vouchersData) {
      setVouchers([...vouchersData]);
    }
  }, [vouchersData]);

  return (
    <YupProvider value={schema}>
      <Grid container>
        <Grid item md={12}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={() => {
              setVouchers(prev => [...prev, { id: null, name: '', isEdit: true }]);
            }}
            size="small"
          >
            <AddIcon className={classes.extendedIcon} />
            Agregar Comprobante
          </Fab>
        </Grid>
      </Grid>
      {vouchers.map((vouch, index) => (
        <Formik
          initialValues={{
            name: vouch.name,
            isEdit: vouch?.isEdit || false,
            id: vouch?.id || null,
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            if (values.id) {
              API.PaymentVoucher.update(values.id, {
                name: values.name,
              })
                .then(({ data }) => {
                  actions.setFieldValue('isEdit', false);
                  Toast.fire({
                    icon: 'success',
                    title: `${data.name} fué editado con éxito.`,
                  });
                })
                .catch(({ response: { data } }) => {
                  actions.setErrors(Object.fromEntries(Object.entries(data).map(([name, [msg]]) => [name, msg])));
                });
            } else {
              API.PaymentVoucher.create({
                name: values.name,
              })
                .then(({ data }) => {
                  actions.setFieldValue('id', data.id);
                  actions.setFieldValue('isEdit', false);
                  // actions.setSubmitting(false);
                  Toast.fire({
                    icon: 'success',
                    title: `${data.name} fué agregado con éxito.`,
                  });
                })
                .catch(({ response: { data } }) => {
                  actions.setErrors(Object.fromEntries(Object.entries(data).map(([name, [msg]]) => [name, msg])));
                });
            }
            return true;
          }}
          key={`voucher${String(index)}`}
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => (
            <Form autoComplete="off">
              <Grid container justify="center" style={{ padding: '10px', borderTop: '1px solid #EEE' }}>
                <GridItem md={1}>
                  <Typography style={{ lineHeight: '2.5' }}>{`${index + 1}:`}</Typography>
                </GridItem>
                <GridItem md={6}>
                  {values.isEdit ? (
                    <FormInput name="name" delay={10} />
                  ) : (
                    <Typography style={{ lineHeight: '2.5' }}>{values.name}</Typography>
                  )}
                </GridItem>
                <GridItem md={3}>
                  {values.isEdit ? (
                    <IconButton
                      style={{ padding: '5px' }}
                      onClick={() => {
                        submitForm();
                      }}
                      disabled={isSubmitting}
                    >
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton style={{ padding: '5px' }} onClick={() => setFieldValue('isEdit', true)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      ))}
    </YupProvider>
  );
}
