import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorConexion from 'components/ErrorConexion';

const sumTotal = (arr, currency, price = 'price') =>
  arr.reduce((a, c) => parseFloat(a) + (c.currency === currency ? parseFloat(c[price]) : 0), 0).toFixed(2);
export { sumTotal };

const str2date = str => (str ? new Date(`${str}T00:00:00`) : null);
export { str2date };
const date2format = str => (str ? format(str, 'dd/MM/yyyy') : null);
export { date2format };
const str2format = str => (str ? format(new Date(`${str}T00:00:00`), 'dd/MM/yyyy') : null);
export { str2format };
const date2str = date => (date ? format(date, 'yyyy-MM-dd') : null);
export { date2str };

const str2fullformat = str => (str ? format(new Date(str), 'Pp', { locale: es }) : null);
export { str2fullformat };

const date2fullstr = date => (date ? format(date, 'yyyy-MM-dd HH:mm:ss') : null);
export { date2fullstr };

const height = 35;
export function MenuList(props) {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List width="" height={maxHeight} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

export function Option(props) {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
}

export function isValidId(str) {
  return /^\+?([1-9]\d*)$/.test(str);
}

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  if (typeof obj !== 'object') return true;

  return true;
}

export const FormContainer = ({ options, children }) => {
  const { isError, isLoading } = options;

  if (isLoading) {
    return <LinearProgress />;
  }
  if (isError) {
    return <ErrorConexion code="404" />;
  }
  return children;
};
