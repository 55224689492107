import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import HeaderTitle from 'components/HeaderTitle';
import Viewer from 'components/Viewer';
import { Link, useParams } from 'react-router-dom';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Total from './Total';

export default function Payments() {
  const { id } = useParams();

  const [reservationData] = useFetchData(() => API.Reservation.get(id));

  return (
    <>
      <Box>
        <HeaderTitle
          title={`RESERVA ${reservationData?.code || ''}`}
          style={{
            borderRadius: '5px',
            padding: '2px 10px',
            margin: '10px 0',
            border: '1px solid #B0BEC5',
          }}
        />
        <Box paddingY={1}>
          {!!reservationData?.reservation_details?.length &&
            reservationData?.reservation_details?.map(d => (
              <Box
                component={Paper}
                square
                elevation={3}
                fontWeight="bold"
                paddingBottom="15px"
                key={`det${d.id}`}
                marginBottom="10px"
              >
                <Box p={1}>
                  <Box display="flex">
                    <Box p={1}>{d.package_name}</Box>
                    <Box p={1}>
                      {d.price} {d.currency}
                    </Box>
                  </Box>
                </Box>
                <Table style={{ margin: '10px' }}>
                  <TableBody>
                    {d.service_requests.map(s =>
                      s?.service_order ? (
                        <Viewer name="ServiceOrder" value={s.service_order} key={`servords${s.service_order}`}>
                          {order => (
                            <TableRow>
                              <TableCell>
                                <Link to={`${goto(MODS.SERVICE_ORDERS)}/${parseInt(order.id, 10)}`}>{order.code}</Link>
                              </TableCell>
                              <TableCell>{order.service.supplier_name}</TableCell>
                              <TableCell>{order.service.description}</TableCell>
                              <TableCell>x {reservationData?.total_num_pax_format} pax</TableCell>
                              <TableCell>
                                {parseFloat(s.cost).toFixed(2)} {s.currency}
                              </TableCell>
                              <TableCell> (cantidad {s.quantity})</TableCell>
                            </TableRow>
                          )}
                        </Viewer>
                      ) : (
                        <Viewer name="Service" value={s.service} key={`serviceorder${s.id}${s.service}`}>
                          {service => (
                            <TableRow>
                              <TableCell>-</TableCell>
                              <TableCell>{service.supplier_name}</TableCell>
                              <TableCell>{service.description}</TableCell>
                              <TableCell>x {reservationData?.total_num_pax_format} pax</TableCell>
                              <TableCell>
                                {parseFloat(s.cost).toFixed(2)} {s.currency}
                              </TableCell>
                              <TableCell> (cantidad {s.quantity})</TableCell>
                            </TableRow>
                          )}
                        </Viewer>
                      ),
                    )}
                  </TableBody>
                </Table>
                <Box m={1} ml={2}>
                  <Total serviceRequests={d.service_requests} />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      <Box paddingY={1}>
        <Box component={Paper} square elevation={3} fontWeight="bold" marginTop="5px">
          <Box p={2}>
            <strong>Total de Reserva : </strong>
            {!!reservationData?.total_soles && (
              <>
                <br />
                {`SOLES: ${reservationData?.total_soles_format}`}
              </>
            )}
            {!!reservationData?.total_dolares && (
              <>
                <br />
                {`DOLARES: ${reservationData?.total_dolares_format}`}
              </>
            )}
            {!!reservationData?.total_euros && (
              <>
                <br />
                {`EUROS: ${reservationData?.total_euros_format}`}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
