import Select from 'components/Select';
import { ageGroupOptions } from 'config';
import PropTypes from 'prop-types';
import React from 'react';

const options = Object.entries(ageGroupOptions).map(
  ([id, name]) => ({ id, name })
);

export default function AgeGroupSelect({
  // * Props
  label, name
}) {
  // * "Componente por defecto"

  return (
    <>
      <Select
        label={label}
        options={options}
        name={name}
      />
    </>
  );
}

AgeGroupSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};
