import { Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import HeaderTitle from 'components/HeaderTitle';
import { localization } from 'config';
import Form from 'Forms/PackageGroup';
import DialogForm from 'layouts/DialogForm';
import MaterialTable from 'material-table';
import React, { useCallback, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { commonCols } from 'utils/site';
import Toast from 'utils/toast';

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    marginTop: theme.spacing(2),
    // right: theme.spacing(4),
  },
}));

const tableRef = React.createRef();

export default function Payments() {
  const classes = useStyles();
  const [openGroup, setOpenGroup] = useState(null);

  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);

  const ability = useContext(AbilityContext);

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleSearch = ({ pageSize, page, search, orderDirection, orderBy }) =>
    API.PackageGroup.all({
      per_page: pageSize,
      page: page + 1,
      search,
      ordering: orderDirection === 'desc' ? `-${orderBy?.field}` : orderBy?.field || null,
    }).then(({ data: { result, current, count } }) => ({
      data: result,
      page: current - 1,
      totalCount: count,
    }));

  return (
    <>
      <Box>
        <DialogForm
          open={open}
          onClose={close}
          component={Form}
          id={openGroup}
          maxWidth="sm"
          onCreate={() => {
            Toast.fire({
              icon: 'success',
              title: 'Grupo registrado.',
            });
            tableRef.current.onQueryChange();
            close();
          }}
          onUpdate={() => {
            Toast.fire({
              icon: 'success',
              title: 'Grupo editado.',
            });
            tableRef.current.onQueryChange();
            close();
          }}
          createLabel="Agregar Grupo"
        />
        <HeaderTitle
          title="Grupos de Filtrado"
          style={{
            borderRadius: '5px',
            padding: '2px 10px',
            margin: '10px 0',
            border: '1px solid #B0BEC5',
          }}
        />
        <MaterialTable
          columns={[
            { title: 'Nombre', field: 'name' },
            { title: 'Descripción', field: 'description' },
            ...commonCols(true),
          ]}
          data={handleSearch}
          options={{
            headerStyle: {
              backgroundColor: '#9c27b0',
              color: '#FFF',
            },
            draggable: false,
            // loadingType: 'linear',
            // actionsColumnIndex: -1,
            pageSize: 5,
            // toolbar: false,
            loadingType: 'linear',
            // paging: false,
            showTitle: false,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              margin: '10px',
            },
          }}
          localization={{
            ...localization,
          }}
          actions={[
            // rowData => ({
            //   icon: 'delete',
            //   tooltip: 'Delete User',
            //   onClick: (event, rowData) => console.log(`You want to delete ${rowData.code}`),
            //   disabled: rowData.birthYear < 2000
            // }),
            rowData => ({
              icon: 'edit',
              tooltip: 'Editar',
              onClick: () => {
                setOpen(() => {
                  setOpenGroup(rowData.id);
                  return true;
                });
              },
            }),
            ability.can('delete', 'package')
              ? {
                  icon: 'cancel_presentation_outlined',
                  tooltip: 'Eliminar Tour',
                  onClick: (e, row) => {
                    Swal.fire({
                      title: `¿Eliminar ${row.code || 'el registro'}?`,
                      text: 'Se eliminará completamente.',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#3085d6',
                      confirmButtonText: '¡Eliminar!',
                      cancelButtonText: 'Mantener.',
                    }).then(result => {
                      if (result.value) {
                        API.PackageGroup.remove(row.id).then(() => {
                          Toast.fire({
                            icon: 'info',
                            title: 'El registro fue eliminado.',
                          });
                          tableRef.current.onQueryChange();
                        });
                      }
                    });
                  },
                }
              : null,
          ]}
          tableRef={tableRef}
        />
      </Box>
      <Box>
        <Zoom in timeout={transitionDuration} style={{ transitionDelay: `${transitionDuration.exit}ms` }} unmountOnExit>
          <Fab
            variant="extended"
            aria-label="add"
            onClick={() =>
              setOpen(() => {
                setOpenGroup(null);
                return true;
              })
            }
            size="small"
            className={classes.fab}
          >
            <AddIcon className={classes.extendedIcon} />
            Agregar Grupo
          </Fab>
        </Zoom>
      </Box>
    </>
  );
}
