import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { API } from 'utils/api';
import { useFetchData } from 'utils/hooks';
import ErrorMessage from 'components/ErrorMessage';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';

const ActionDatePicker = ({ name, label, isEdit }) => {
  // * Clases
  const { values } = useFormikContext();
  const [, { value }, { setValue }] = useField(name);

  const [serviceRequestData, setQueryServiceRequestData] = useFetchData(
    (query = {}) => API.ServiceRequest.action('currencies', query),
    [],
  );

  useEffect(() => {
    if (!isEdit) {
      if (values.service || values.supplier || values.supplier_contact || values.date_in) {
        const query = {};
        if (values.service) query.service = values.service;
        if (values.supplier) query.supplier = values.supplier;
        if (values.supplier_contact) query.contact = values.supplier_contact;
        if (values.date_in) query.date_in = values.date_in;
        setQueryServiceRequestData(query);
      } else {
        setQueryServiceRequestData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service, values.supplier, values.supplier_contact, values.date_in]);

  return (
    <>
      <Typography>{label}</Typography>
      <Select
        value={value ? serviceRequestData.find(i => i.currency === value) : ''}
        onChange={sel => setValue(sel?.currency || null)}
        options={serviceRequestData || []}
        placeholder="Seleccione"
        getOptionLabel={i => i.currency}
        getOptionValue={i => i.currency}
        isClearable
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        name={name}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default ActionDatePicker;
