import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MaterialTable from 'material-table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { str2format, str2fullformat } from 'utils/helpers';
import { goto, MODS } from 'utils/structure';

const useStyles = makeStyles(() => ({
  table: {
    borderBottom: '1px solid #AAA',
    margin: 0,
    '& td': {
      padding: '2px 5px',
    },
  },
}));

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        width: 'max-content',
        margin: 'auto',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 10px !important',
        fontSize: '0.75em !important',
        width: 'max-content !important',
      },
      head: {
        backgroundColor: '#ffa726 !important', // '#f44336 !important'
      },
    },
  },
});

export default function Pending(props) {
  const classes = useStyles();

  const history = useHistory();

  const { path } = props;

  const ability = useContext(AbilityContext);

  const handleSearch = (query, getData) =>
    getData({
      status: 'PENDING',
      per_page: query.pageSize,
      page: query.page + 1,
      search: query.search,
      ordering:
        `${query.orderDirection === 'desc' ? '-' : ''}` + `${query.orderBy === undefined ? '' : query.orderBy.field}`,
    })
      .then(response => response.data)
      .then(data => ({
        data: data.result,
        page: data.current - 1,
        totalCount: data.count,
      }));

  return (
    <GridContainer justify="center">
      <GridItem md={12} xl={8}>
        <ThemeProvider theme={theme}>
          <MaterialTable
            title="Esperando aprobación"
            columns={[
              {
                title: 'Seguimiento',
                field: 'code',
              },
              {
                title: 'Ordenes / Reservas / Pax',
                field: 'entered_group',
                render: ({ entered_group: { service_orders: orders } }) =>
                  orders.map(ord => (
                    <table key={ord.id} className={classes.table}>
                      <tbody>
                        <tr>
                          <td
                            rowSpan={ord.service_request.length + 1}
                            style={{
                              fontSize: '0.8rem',
                              border: '1px solid #DDD',
                            }}
                          >
                            {ability.can('manage', 'operations') ? (
                              <Link to={`${goto(MODS.SERVICE_ORDERS)}/${ord.id}`}>{ord.code}</Link>
                            ) : (
                              <span>{ord.code}</span>
                            )}

                            <br />
                            <div style={{ fontSize: '0.6rem' }}>
                              Costo: {ord.total_cost_format}{' '}
                              {ability.can('manage', 'operations') && (
                                <span>( Servicio para el {ord.date_in ? str2format(ord.date_in) : '---'})</span>
                              )}
                            </div>
                          </td>
                          {ability.can('manage', 'reservations') && (
                            <>
                              <td
                                style={{
                                  fontSize: '0.5rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                Reserva
                              </td>
                              <td
                                style={{
                                  fontSize: '0.5rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                Pax
                              </td>
                            </>
                          )}
                          {/* <td colSpan={2} style={{ display: 'none' }}>{' '}</td> */}
                        </tr>
                        {ability.can('manage', 'reservations') &&
                          ord.service_request.map(({ reservation_detail: { reservation } }) => (
                            <tr key={reservation.id}>
                              <td>
                                <Link to={`${goto(MODS.RESERVATIONS)}/${reservation.id}`}>{reservation.code}</Link>
                              </td>
                              <td>({reservation.client.name})</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )),
              },
              { title: 'Comprobante', field: 'voucher_code' },
              { title: 'Proveedor', field: 'supplier' },
              { title: 'Costo', field: 'cost_format' },
              {
                title: 'Ingreso',
                field: 'created',
                render: ({ created: date }) => (date ? str2fullformat(date) : '---'),
                emptyValue: '---',
              },
              // ...commonCols(true, true)
            ]}
            data={query => handleSearch(query, API.ServiceOrderPayment.all)}
            options={{
              headerStyle: {
                // backgroundColor: '#01579b',
                color: '#FFF',
              },
              draggable: false,
              // loadingType: 'linear',
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            components={{
              OverlayLoading: () => <></>,
              Container: props => props.children,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Sin resultados',
                addTooltip: 'Agregar',
                deleteTooltip: 'Eliminar',
                editTooltip: 'Editar',
                editRow: {
                  deleteText: '¿Está seguro que desea eliminar esta fila?',
                  cancelTooltip: 'Cancelar',
                  saveTooltip: 'Guardar',
                },
              },
              header: {
                actions: 'Acciones ',
              },
              pagination: {
                labelRowsPerPage: 'Filas por página',
                labelRowsSelect: 'Filas Mostradas',
                labelDisplayedRows: '{count} resultados',
                firstAriaLabel: 'Primera página',
                firstTooltip: 'Primera página',
                previousAriaLabel: 'Anterior página',
                previousTooltip: 'Anterior página',
                nextAriaLabel: 'Siguiente página',
                nextTooltip: 'Siguiente página',
                lastAriaLabel: 'Última página',
                lastTooltip: 'Última página',
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
                nRowsSelected: '{0} seleccionado(s)',
              },
            }}
            actions={[
              // rowData => ({
              //   icon: 'delete',
              //   tooltip: 'Delete User',
              //   onClick: (event, rowData) => console.log(`You want to delete ${rowData.code}`),
              //   disabled: rowData.birthYear < 2000
              // }),
              // eslint-disable-next-line no-unused-vars
              rowData => ({
                icon: 'visibility',
                tooltip: 'Revisar',
                onClick: (event, rowData) => history.push(`${path}/${rowData.id}`),
              }),
            ]}
            // options={{
            //   actionsColumnIndex: -1
            // }}
          />
        </ThemeProvider>
      </GridItem>
    </GridContainer>
  );
}
