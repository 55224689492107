import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/ErrorMessage';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useYupRequired } from 'utils/hooks';

const InputStyled = styled.div`
  /* STYLE PARA HOVER */
  .react-tel-input:hover > .form-control {
    border: 1px solid #000;
  }

  .react-tel-input:hover > .flag-dropdown {
    border: 1px solid #000;
  }

  .react-tel-input > .form-control:focus,
  .react-tel-input > .form-control:focus + .flag-dropdown {
    border: 1.5px solid ${props => props.theme.palette.primary.main};
  }

  .form-control:focus + .flag-dropdown {
    border: 1.5px solid ${props => props.theme.palette.primary.main};
  }
`;

export default function PhoneInput({
  // * Props
  label,
  name,
}) {
  // * "Componente por defecto"

  const [, { value }, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();

  const [defaultValue, setDefaultValue] = useState(value);

  const required = useYupRequired(name);

  const theme = useTheme();

  const [debouncedHandleOnChange] = useDebouncedCallback(valu => {
    setValue(valu);
  }, 1000);

  return (
    <>
      <Typography>
        {required && <strong>* </strong>}
        {label}
      </Typography>
      <InputStyled theme={theme}>
        <ReactPhoneInput
          country="pe"
          localization={es}
          inputStyle={{
            width: '100%',
            paddingBottom: '18.5px',
            paddingTop: '18.5px',
            font: 'inherit',
          }}
          autoFormat
          value={defaultValue}
          onChange={text => {
            setDefaultValue(text);
            debouncedHandleOnChange(text);
          }}
          inputProps={{ name }}
          disabled={isSubmitting}
          enableLongNumbers
          enableSearch
        />
      </InputStyled>
      <ErrorMessage name={name} />
    </>
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
