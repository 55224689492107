import React from 'react';
import * as CONFIG from 'config';

export default function OptionsViewer({
  // * Props
  value,
  children,
  name,
}) {
  return (
    <>
      {children(CONFIG[name][value])}
    </>
  );
}
